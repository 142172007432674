import { FolderIcon } from "@heroicons/react/outline";
import { useSetRecoilState } from "recoil";
import { selectedAssetFileTagAtom } from "../assetFiles.recoil";

interface Props {
	tag: string;
}

const AssetFilesFolder: React.FC<Props> = ({ tag }) => {
	const selectedAssetFilesTag = useSetRecoilState(selectedAssetFileTagAtom);

	return (
		<div
			className="flex w-36 shrink-0 grow-0 flex-col items-center rounded border border-uwgc-midGrey p-1 shadow-md hover:cursor-pointer"
			onClick={() => {
				selectedAssetFilesTag(tag);
			}}
		>
			<FolderIcon className="h-12 w-12 fill-uwgc-yellow text-uwgc-charcoal" />
			{tag}
		</div>
	);
};

export default AssetFilesFolder;
