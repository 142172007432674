import { useCallback, useEffect } from "react";
import Button, { Props as ButtonProps } from "../../../../../../../shared/components/button/Button";
import {
	StoryFragment,
	StoryPartial,
	useGetStoryLazyQuery,
} from "../../../../../../../shared/generated/graphql";
import { useBuilderModals } from "../../builder.recoil";
import StoryPreview from "./StoryPreview";

interface Props extends ButtonProps {
	story?: StoryPartial;
	storyId?: number;
}

const OpenStoryPreviewButton: React.FC<Props> = ({ children, story, storyId, ...props }) => {
	const { pushBuilderModal } = useBuilderModals();

	const [getStory, getStoryRes] = useGetStoryLazyQuery({
		fetchPolicy: "no-cache",
	});

	const _openBuilderModal = useCallback(
		(story: StoryPartial) => {
			pushBuilderModal({
				render: (
					<div className="w-[80vw]">
						<StoryPreview story={story as StoryFragment} />
					</div>
				),
			});
		},
		[pushBuilderModal],
	);

	const _open = useCallback(() => {
		if (storyId !== undefined) {
			void getStory({
				variables: {
					id: storyId,
				},
			});
		} else if (story) {
			_openBuilderModal(story);
		}
	}, [_openBuilderModal, getStory, story, storyId]);

	useEffect(() => {
		if (getStoryRes.called && !getStoryRes.loading && getStoryRes.data) {
			_openBuilderModal(getStoryRes.data.getStories.rows[0]);
		}
	}, [getStoryRes.called, getStoryRes.loading, getStoryRes.data, _openBuilderModal]);

	return (
		<Button {...props} onClick={() => _open()}>
			{children}
		</Button>
	);
};

export default OpenStoryPreviewButton;
