import { useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";
import Story from "../../../../../../../display/views/story/Story";
import StoryControl from "../../../../../../../display/views/story/StoryControl";
import { StoryFragment } from "../../../../../../../shared/generated/graphql";
import { useStoryEngine } from "../../../../../../../shared/state/storyEngine/storyEngine.recoil";
import "./storyPreview.scss";

interface Props {
	story: StoryFragment;
}
const BASE_FONT_SIZE_SCALE = 0.87;

const StoryPreview: React.FC<Props> = ({ story }) => {
	const { storyEngine, initStoryEngine, destroyStoryEngine } = useStoryEngine();
	const { width, ref } = useResizeDetector();

	useEffect(() => {
		initStoryEngine();
	}, [initStoryEngine]);

	useEffect(() => {
		return () => {
			destroyStoryEngine();
		};
	}, [destroyStoryEngine]);

	useEffect(() => {
		if (storyEngine) {
			storyEngine.stories = [story];
			storyEngine.isPaused = false;
		}
	}, [story, storyEngine]);

	useEffect(() => {
		if (width !== undefined) {
			const newFontSizePercentage = (width * BASE_FONT_SIZE_SCALE) / 100;
			ref.current?.style.setProperty("--font-size", newFontSizePercentage + "px");
		}
	}, [width, ref]);

	return (
		<div className="story-preview" ref={ref}>
			<Story />
			<StoryControl />
		</div>
	);
};

export default StoryPreview;
