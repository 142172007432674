import classNames from "classnames";
import "../styles/components/tabs.scss";

interface Props
	extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	tabNames: string[];
	selectedTabName?: string;
	onTabSelect: (tabName: string) => void;
	renderTabContent: () => JSX.Element | undefined;
}

const Tabs: React.FC<Props> = ({
	tabNames,
	selectedTabName,
	onTabSelect,
	renderTabContent,
	...divProps
}) => {
	return (
		<div className="tabs-comp" {...divProps}>
			<div className="tabs">
				{tabNames.map((tabName) => {
					return (
						<div
							className={classNames("tab", {
								selected: tabName === selectedTabName,
							})}
							key={tabName}
							onClick={() => onTabSelect(tabName)}
						>
							{tabName}
						</div>
					);
				})}
			</div>
			{renderTabContent()}
		</div>
	);
};

export default Tabs;
