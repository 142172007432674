import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { DEFAULT_MAP_LAT_LNG, DEFAULT_MAP_ZOOM, mapStateAtom, MAP_COLOR_MAP } from "./map.recoil";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import HeatMapLayer from "./HeatMapLayer";
import MapLayerData, {
	MapLayerTypes,
	MapLayerZipCodeHeatMapData,
	MapLayerZipCodeScatterPlotData,
} from "./types/MapLayerData";
import { LatLngBounds, LatLngTuple } from "leaflet";
import ScatterPlotLayer from "./ScatterPlotLayer";
import MapDetailsRight from "./MapDetailsRight";
import "./map.scss";
import MapDetailsLeft from "./MapDetailsLeft";

const getLatLngsBounds = (latLngs: LatLngTuple[]): LatLngBounds => {
	if (latLngs.length === 0) {
		return new LatLngBounds([
			[0, 0],
			[0, 0],
		]);
	}

	const latLng0 = latLngs[0];

	let minLat = latLng0[0];
	let maxLat = latLng0[0];
	let minLng = latLng0[1];
	let maxLng = latLng0[1];
	for (let i = 1; i < latLngs.length; ++i) {
		const latLng = latLngs[i];
		const lat = latLng[0];
		const lng = latLng[1];
		if (lat < minLat) {
			minLat = lat;
		} else if (lat > maxLat) {
			maxLat = lat;
		}

		if (lng < minLng) {
			minLng = lng;
		} else if (lng > maxLng) {
			maxLng = lng;
		}
	}

	return new LatLngBounds([
		[minLat, minLng],
		[maxLat, maxLng],
	]);
};

const getLayerComponent = (layerData: MapLayerData) => {
	switch (layerData.type) {
		case MapLayerTypes.ZIP_CODES_HEAT_MAP: {
			const heatMapLayerData = layerData.data as MapLayerZipCodeHeatMapData;
			return <HeatMapLayer data={heatMapLayerData} />;
		}
		case MapLayerTypes.ZIP_CODES_SCATTER_PLOT: {
			const scatterPlotLayerData = layerData.data as MapLayerZipCodeScatterPlotData;
			return (
				<ScatterPlotLayer
					data={scatterPlotLayerData}
					// @TODO should only be using its own set per data type
					nameColorMap={MAP_COLOR_MAP}
				/>
			);
		}
		default:
			return undefined;
	}
};

const MapController: React.FC = () => {
	const mapState = useRecoilValue(mapStateAtom);

	const map = useMap();

	useEffect(() => {
		if (mapState.fitLatLngs) {
			const bounds = getLatLngsBounds(mapState.fitLatLngs);
			map.fitBounds(bounds);
		}

		// leave this here to know how to set lat lng manually
		// map.flyTo(DEFAULT_MAP_LAT_LNG, DEFAULT_MAP_ZOOM);
	}, [mapState.fitLatLngs]);

	return <></>;
};

// @TODO dynamic layers
const Map: React.FC = () => {
	const mapState = useRecoilValue(mapStateAtom);
	const resetMapState = useResetRecoilState(mapStateAtom);

	useEffect(() => {
		return () => {
			resetMapState();
		};
	}, []);

	const layer = useMemo(() => {
		if (mapState.layer) {
			return getLayerComponent(mapState.layer);
		}

		return undefined;
	}, [mapState.layer]);

	return (
		<div className="map">
			<div className="grid">
				<div className="container screen-0 max-w-full">
					<MapDetailsLeft />
				</div>
				<div className="container screen-1 max-w-full">
					<MapContainer
						center={DEFAULT_MAP_LAT_LNG} // not reactive
						zoom={DEFAULT_MAP_ZOOM} // not reactive
						style={{ width: "100%", height: "100%" }}
						zoomControl={false}
					>
						<TileLayer url="https://osm.ops.gate3.co/tile/{z}/{x}/{y}.png" />
						<MapController />
						{layer}
					</MapContainer>
				</div>
				<div className="container screen-2 max-w-full">
					<MapDetailsRight />
				</div>
			</div>
		</div>
	);
};

export default Map;
