import ControlStoryCommandData from "../../../types/controlWs/outControlWsData/ControlStoryCommandData";
import {
	createOutControlWsData,
	OutControlWsDataTypes,
} from "../../../types/controlWs/outControlWsData/OutControlWsData";
import useSendOutControlWsData from "./useSendOutControlWsData";

const useSendControlStoryCommandData = () => {
	const sendOutControlWsData = useSendOutControlWsData();
	return (command: ControlStoryCommandData) => {
		sendOutControlWsData(createOutControlWsData(OutControlWsDataTypes.STORY_COMMAND, command));
	};
};

export default useSendControlStoryCommandData;
