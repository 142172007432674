import MapSettings from "./mapSettings/MapSettings";
import "./maps.scss";
import { useRecoilState } from "recoil";
import { mapSelectedAtom } from "./maps.recoil";
import MapList from "./MapList";
import { useEffect } from "react";

const Maps: React.FC = () => {
	const [mapSelected, setMapSelected] = useRecoilState(mapSelectedAtom);

	useEffect(() => {
		return () => {
			setMapSelected(undefined);
		};
	}, []);

	return (
		<div className="maps flex flex-wrap gap-3">{mapSelected ? <MapSettings /> : <MapList />}</div>
	);
};

export default Maps;
