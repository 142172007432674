import AddFrameButton from "./frameForm/AddFrameButton";
import FramesList from "./framesList/FramesList";

export interface Props {
	sectionIndex: number;
}

const Frames: React.FC<Props> = ({ sectionIndex }) => {
	return (
		<div>
			<FramesList sectionIndex={sectionIndex} />
			<div className="blue-dashed flex justify-center items-center border-uwgc-darkGray p-2">
				<AddFrameButton sectionIndex={sectionIndex} />
			</div>
		</div>
	);
};

export default Frames;
