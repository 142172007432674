import React, { useEffect, useRef } from "react";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { useRecoilValue } from "recoil";
import { VideoComponentFragment } from "../../../../../../../shared/generated/graphql";
import {
	isMutedAtom,
	useStoryEngineState,
} from "../../../../../../../shared/state/storyEngine/storyEngine.recoil";
import { getFullFilePath } from "../../../../../../../shared/utils/Utils";

interface Props {
	isBuilder?: boolean;
	videoComponent: VideoComponentFragment;
}

const VideoComponent: React.FC<Props> = ({ isBuilder, videoComponent: { assetFile, isMuted } }) => {
	const storyEngineState = useStoryEngineState();

	const videoRef = useRef<HTMLVideoElement>(null);
	const isMutedGlobal = useRecoilValue(isMutedAtom);

	useEffect(() => {
		if (videoRef.current) {
			if (storyEngineState.isPaused) {
				videoRef.current.pause();
			} else {
				void videoRef.current.play();
			}
		}
	}, [storyEngineState.isPaused]);

	useEffect(() => {
		const videoElem = videoRef.current;

		return () => {
			if (videoElem) {
				// stops video stream/download
				videoElem.src = "";
			}
		};
	}, []);

	return (
		<>
			{isBuilder ? (
				<div className="flex h-full w-full items-center justify-center">
					<BsFillCameraVideoFill className="h-32 w-32" />
				</div>
			) : (
				<video
					ref={videoRef}
					className="h-full w-full"
					src={getFullFilePath(assetFile.file)}
					autoPlay
					muted={isMutedGlobal || !!isMuted}
				/>
			)}
		</>
	);
};

export default VideoComponent;
