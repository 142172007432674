import { useCallback } from "react";
import {
	atom,
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from "recoil";
import StoryEngine from "../../classes/StoryEngine";
import {
	AssetFileFragment,
	FrameFragment,
	LayerFragment,
	SectionFragment,
	StoryFragment,
} from "../../generated/graphql";
import useSubscriptionsHandler from "../../hooks/useSubscriptionsHandler";

const storyEngineAtom = atom<StoryEngine | undefined>({
	key: "storyEngine",
	default: undefined,
	dangerouslyAllowMutability: true,
});

const storyEngineStateAtom = atom<{
	story: StoryFragment | undefined;
	section: SectionFragment | undefined;
	frame: FrameFragment | undefined;
	layers: LayerFragment[];
	isPaused: boolean;
	bgAudioAssetFile: AssetFileFragment | undefined;
}>({
	key: "storyEngineState",
	default: {
		story: undefined,
		section: undefined,
		frame: undefined,
		layers: [],
		isPaused: true,
		bgAudioAssetFile: undefined,
	},
});

const storyEngineTimeAtom = atom<number>({
	key: "storyEngineTime",
	default: 0,
});

const storyEngineBgAudioVolumeAtom = atom<number>({
	key: "storyEngineBgAudioVolume",
	default: 1,
});

export const useStoryEngine = () => {
	const [storyEngine, setStoryEngine] = useRecoilState(storyEngineAtom);
	const resetStoryEngine = useResetRecoilState(storyEngineAtom);

	const setStoryEngineState = useSetRecoilState(storyEngineStateAtom);
	const resetStoryEngineState = useResetRecoilState(storyEngineStateAtom);

	const setStoryEngineTime = useSetRecoilState(storyEngineTimeAtom);
	const resetStoryEngineTime = useResetRecoilState(storyEngineTimeAtom);

	const setStoryEngineBgAudioTime = useSetRecoilState(storyEngineBgAudioVolumeAtom);
	const resetStoryEngineBgAudioTime = useResetRecoilState(storyEngineBgAudioVolumeAtom);

	const { addSubscription, unsubscribeAll } = useSubscriptionsHandler();

	const initStoryEngine = useCallback(() => {
		const newStoryEngine = new StoryEngine();

		addSubscription(
			newStoryEngine.storyObservable.subscribe((story) => {
				setStoryEngineState((state) => {
					return {
						...state,
						story,
					};
				});
			}),
		);
		addSubscription(
			newStoryEngine.sectionObservable.subscribe((section) => {
				setStoryEngineState((state) => {
					return {
						...state,
						section,
					};
				});
			}),
		);
		addSubscription(
			newStoryEngine.frameObservable.subscribe((frame) => {
				setStoryEngineState((state) => {
					return {
						...state,
						frame,
					};
				});
			}),
		);
		addSubscription(
			newStoryEngine.layersObservable.subscribe((layers) => {
				setStoryEngineState((state) => {
					return {
						...state,
						layers,
					};
				});
			}),
		);
		addSubscription(
			newStoryEngine.isPausedObservable.subscribe((isPaused) => {
				setStoryEngineState((state) => {
					return {
						...state,
						isPaused,
					};
				});
			}),
		);
		addSubscription(
			newStoryEngine.timeObservable.subscribe((time) => {
				setStoryEngineTime(time);
			}),
		);
		addSubscription(
			newStoryEngine.bgAudioAssetFileObservable.subscribe((bgAudioAssetFile) => {
				setStoryEngineState((state) => {
					return {
						...state,
						bgAudioAssetFile,
					};
				});
			}),
		);
		addSubscription(
			newStoryEngine.bgAudioVolumeObservable.subscribe((bgAudioVolume) => {
				setStoryEngineBgAudioTime(bgAudioVolume);
			}),
		);

		setStoryEngine(newStoryEngine);
	}, [
		addSubscription,
		setStoryEngine,
		setStoryEngineBgAudioTime,
		setStoryEngineState,
		setStoryEngineTime,
	]);

	const destroyStoryEngine = useCallback(() => {
		if (storyEngine) {
			unsubscribeAll();

			storyEngine.stories = [];

			resetStoryEngineBgAudioTime();
			resetStoryEngineTime();
			resetStoryEngineState();
			resetStoryEngine();
		}
	}, [
		resetStoryEngine,
		resetStoryEngineBgAudioTime,
		resetStoryEngineState,
		resetStoryEngineTime,
		storyEngine,
		unsubscribeAll,
	]);

	return {
		storyEngine,
		initStoryEngine,
		destroyStoryEngine,
	};
};

export const useStoryEngineTime = () => {
	return useRecoilValue(storyEngineTimeAtom);
};

export const useStoryEngineState = () => {
	return useRecoilValue(storyEngineStateAtom);
};

export const useStoryEngineBgAudioVolume = () => {
	return useRecoilValue(storyEngineBgAudioVolumeAtom);
};

export const isMutedAtom = atom<boolean>({
	key: "isMuted",
	default: false,
});
