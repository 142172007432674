import { useEffect } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { LocalStorageKeys } from "../../../constants/LocalStorageKeys";
import {
	hasControlAtom,
	isDisplayConnectedAtom,
	controlWsAtom,
	controlWsConnectionStatusAtom,
} from "./controlWs.recoil";
import WsConnectionStatus from "../../../shared/types/WsConnectionStatus";
import useHandleInControlWsData from "./handleInData/useHandleInControlWsData";
import useSubscriptionsHandler from "../../../shared/hooks/useSubscriptionsHandler";
import env from "../../../env";
import ControlWs from "../../classes/ControlWs";

const useControlWs = () => {
	const [controlWs, setControlWs] = useRecoilState(controlWsAtom);
	const resetControlWs = useResetRecoilState(controlWsAtom);
	const setControlWsConnectionStatus = useSetRecoilState(controlWsConnectionStatusAtom);
	const setHasControl = useSetRecoilState(hasControlAtom);
	const setIsDisplayConnected = useSetRecoilState(isDisplayConnectedAtom);
	const resetControlWsConnectionStatus = useResetRecoilState(controlWsConnectionStatusAtom);
	const handleInControlWsData = useHandleInControlWsData();

	const { addSubscription, unsubscribeAll } = useSubscriptionsHandler();

	const onConnectionChanged = (connection: WsConnectionStatus) => {
		setControlWsConnectionStatus(connection);

		if (connection !== WsConnectionStatus.CONNECTED) {
			// reset status
			setHasControl(false);
			setIsDisplayConnected(false);
		}
	};

	useEffect(() => {
		const token = localStorage.getItem(LocalStorageKeys.TOKEN);
		const newControlWs = new ControlWs(env.REACT_APP_API_WS_BASE_URL + "/" + token, "CONTROL");
		addSubscription(newControlWs.connectionStatusObservable.subscribe(onConnectionChanged));
		addSubscription(newControlWs.onDataReceivedObservable.subscribe(handleInControlWsData));
		newControlWs.open();
		setControlWs(newControlWs);

		return () => {
			resetControlWsConnectionStatus();
			unsubscribeAll();
			newControlWs.close();

			resetControlWs();
		};
	}, []);

	useEffect(() => {
		if (controlWs) {
			unsubscribeAll();
			addSubscription(controlWs.connectionStatusObservable.subscribe(onConnectionChanged));
			addSubscription(controlWs.onDataReceivedObservable.subscribe(handleInControlWsData));
		}
	}, [handleInControlWsData]);
};

export default useControlWs;
