import React from "react";
import { ComponentFragment } from "../../../../../../../shared/generated/graphql";
import VideoComponent from "./VideoComponent";
import HtmlComponent from "./HtmlComponent";
import ImageComponent from "./ImageComponent";
import ChartComponent from "./ChartComponent";

interface Props {
	isBuilder?: boolean;
	component: ComponentFragment;
}

const ContainerComponent: React.FC<Props> = ({
	isBuilder,
	component: {
		width: widthN,
		height: heightN,
		htmlComponents,
		imageComponents,
		videoComponents,
		chartComponents,
	},
}) => {
	const width = widthN ?? undefined;
	const height = heightN ?? undefined;

	const refresherKey = isBuilder ? Date.now() : undefined;

	return (
		<div className="container-component" style={{ width, height }}>
			{htmlComponents.length !== 0 && (
				<HtmlComponent key={refresherKey} htmlComponent={htmlComponents[0]} />
			)}
			{imageComponents.length !== 0 && (
				<ImageComponent key={refresherKey} imageComponent={imageComponents[0]} />
			)}
			{videoComponents.length !== 0 && (
				<VideoComponent
					isBuilder={isBuilder}
					key={refresherKey}
					videoComponent={videoComponents[0]}
				/>
			)}
			{chartComponents.length !== 0 && (
				<ChartComponent key={refresherKey} chartComponent={chartComponents[0]} />
			)}
		</div>
	);
};

export default ContainerComponent;
