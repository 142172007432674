import { Redirect, Route, Switch } from "react-router-dom";
import { PathName } from "../../../constants/PathName";
import Builder from "./builder/Builder";
import Control from "./control/Control";
import "./private-content.scss";

const PrivateContent = () => {
	return (
		<div className="private-content">
			<Switch>
				<Route path={PathName.CONTROL} component={Control} />
				<Route path={PathName.BUILDER} component={Builder} />
				<Route render={() => <Redirect push to={PathName.NOT_FOUND} />} />
			</Switch>
		</div>
	);
};

export default PrivateContent;
