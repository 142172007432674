import React, { useEffect } from "react";
import { ALL_LAYERS_INDEX, useStoryForm } from "../../../story.recoil";
import Layer from "./layer/Layer";
import { useResizeDetector } from "react-resize-detector";
import classNames from "classnames";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	selectedLayerIndex: number;
}

const BASE_FONT_SIZE_SCALE = 0.87;

const LayersOverlay: React.FC<Props> = ({ sectionIndex, frameIndex, selectedLayerIndex }) => {
	const { width, height, ref } = useResizeDetector();

	const {
		storyForm: { story },
	} = useStoryForm();

	const section = story.sections?.[sectionIndex];

	const frame = section?.frames?.[frameIndex];

	const layers = frame?.layers;

	useEffect(() => {
		if (width !== undefined) {
			const newFontSizePercentage = (width * BASE_FONT_SIZE_SCALE) / 100;
			ref.current?.style.setProperty("--font-size", newFontSizePercentage + "px");
		}
	}, [width, ref]);

	return (
		<div
			className={classNames(
				"layers-overlay",
				{ "layer-all": selectedLayerIndex === ALL_LAYERS_INDEX },
				section?.background + "-theme",
			)}
			ref={ref}
		>
			<div
				className={classNames("layer-background", frame?.background, {
					[section?.background ?? ""]: frame?.background == null || frame.background === "",
					"frame-default":
						section?.background == null && (frame?.background == null || frame.background === ""),
				})}
			/>
			{layers?.map((layer, i) => {
				return selectedLayerIndex === ALL_LAYERS_INDEX || selectedLayerIndex === i ? (
					<Layer key={i} sectionIndex={sectionIndex} frameIndex={frameIndex} layerIndex={i} />
				) : undefined;
			})}
			{/* Dimensions: {width}w {height}h */}
		</div>
	);
};

export default LayersOverlay;
