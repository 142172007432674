import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import InPublicControlWsData, {
	InPublicControlWsDataTypes,
} from "../../../types/publicControlWs/inPublicControlWsData/InPublicControlWsData";
import WsError from "../../../../shared/types/WsError";
import {
	canViewAtom,
	isViewingAtom,
	remainingTimeAtom,
} from "../../../views/public/publicControl.recoil";

const useHandleInPublicControlWsData = () => {
	const setCanView = useSetRecoilState(canViewAtom);
	const setIsViewing = useSetRecoilState(isViewingAtom);
	const setRemainingTime = useSetRecoilState(remainingTimeAtom);

	return useCallback((data: InPublicControlWsData) => {
		switch (data.type) {
			case InPublicControlWsDataTypes.ERROR:
				{
					const err = data.data as WsError;
					// @TODO show error on screen
					console.error(err.message);
				}
				break;
			case InPublicControlWsDataTypes.CAN_VIEW:
				setCanView(data.data as boolean);
				break;
			case InPublicControlWsDataTypes.IS_VIEWING:
				setIsViewing(data.data as boolean);
				break;
			case InPublicControlWsDataTypes.REMAINING_TIME:
				setRemainingTime(data.data as number);
				break;
			default: {
				console.error("Unknown InPublicControlWsDataTypes");
			}
		}
	}, []);
};

export default useHandleInPublicControlWsData;
