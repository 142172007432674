import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Loading from "../../../shared/components/Loading/Loading";
import { useGetStoryCallZipCodesLazyQuery } from "../../../shared/generated/graphql";
import { selectedStoryAtom, selectedZipCodeAtom, zipCodeInputValueAtom } from "./public.recoil";

const ZIP_CODE_LENGTH = 5;

const EnterZipCode: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const [zipCodeInputValue, setZipCodeInputValue] = useRecoilState(zipCodeInputValueAtom);
	const setSelectedZipCode = useSetRecoilState(selectedZipCodeAtom);

	const [getStoryCallZipCodesForValidation, getStoryCallZipCodesForValidationRes] =
		useGetStoryCallZipCodesLazyQuery({
			fetchPolicy: "cache-and-network",
		});

	const _onZipCodeInputValueChanged = useCallback(
		(newZipCodeInputValue: string) => {
			if (newZipCodeInputValue.length === ZIP_CODE_LENGTH) {
				void getStoryCallZipCodesForValidation({
					variables: {
						input: {
							filter: {
								storyId: selectedStory.id,
								likeZipCode: newZipCodeInputValue,
							},
						},
					},
				});
			}

			setZipCodeInputValue(newZipCodeInputValue);
		},
		[getStoryCallZipCodesForValidation, selectedStory.id, setZipCodeInputValue],
	);

	useEffect(() => {
		if (
			getStoryCallZipCodesForValidationRes.called &&
			!getStoryCallZipCodesForValidationRes.loading &&
			getStoryCallZipCodesForValidationRes.data
		) {
			if (getStoryCallZipCodesForValidationRes.data.getStoryCallZipCodes.rows.length !== 0) {
				setSelectedZipCode({
					zipCode: getStoryCallZipCodesForValidationRes.data.getStoryCallZipCodes.rows[0].zipCode,
					isValid: true,
				});
			} else {
				setSelectedZipCode({
					zipCode: "",
					isValid: false,
				});
			}
		}
	}, [
		getStoryCallZipCodesForValidationRes.called,
		getStoryCallZipCodesForValidationRes.data,
		getStoryCallZipCodesForValidationRes.loading,
		setSelectedZipCode,
	]);

	return (
		<div className="enter-zip-code">
			<div className="info px-2">
				<h3 className="mb-2 text-xl font-semibold text-uwgc-midblue">
					{selectedStory.name === "Hunger" && "What is food insecurity?"}
					{selectedStory.name === "Mental Health" && "What is mental health?"}
					{selectedStory.name === "Housing" && "What is housing stability?"}
				</h3>
				<div className="mb-6 text-uwgc-charcoal">
					{selectedStory.name === "Hunger" && (
						<>
							The fact or an instance of being unable to{" "}
							<span className="font-semibold text-uwgc-orange">consistently access</span> or{" "}
							<span className="font-semibold text-uwgc-orange">afford adequate</span> food.
						</>
					)}
					{selectedStory.name === "Mental Health" && (
						<>
							Mental health is the foundation for the
							<span className="font-semibold text-uwgc-orange"> well-being </span> and
							<span className="font-semibold text-uwgc-orange"> effective functioning </span> of
							individuals. It is more than the absence of a mental disorder; it is the ability to
							think, learn, and understand one's emotions and the reactions of others.
						</>
					)}
					{selectedStory.name === "Housing" && <></>}
				</div>
			</div>
			<div className="zip-code-input flex flex-col px-2">
				<label className="mb-2 font-semibold text-uwgc-charcoal">
					Enter your zip code and find out.
				</label>
				<div className="w-full max-w-lg">
					<input
						className="h-14 w-full max-w-lg border-gray-300 "
						placeholder="Enter Zip Code"
						maxLength={ZIP_CODE_LENGTH}
						disabled={getStoryCallZipCodesForValidationRes.loading}
						value={zipCodeInputValue ?? ""}
						onChange={(e) => _onZipCodeInputValueChanged(e.target.value)}
					/>
					{getStoryCallZipCodesForValidationRes.loading && <Loading loadingType="lds-ring" />}
				</div>
			</div>
		</div>
	);
};

export default EnterZipCode;
