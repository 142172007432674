import { LatLngTuple } from "leaflet";
import { atom } from "recoil";
import MapState from "./types/MapState";

export const DEFAULT_MAP_LAT_LNG: LatLngTuple = [41.4993, -81.6944];
export const DEFAULT_MAP_ZOOM = 13;

export const MAP_COLOR_MAP: string[] = [
	"#427abd",
	"#ff0000",
	"#ffd51a",
	"#5FC15D",
	"#d2d2d2",
	"#4b4f58",
];

export const mapStateAtom = atom<MapState>({
	key: "mapState",
	default: {},
});
