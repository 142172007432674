import Modal from "./Modal";
import { useModals } from "./modals.recoil";

const Modals: React.FC = () => {
	const { modals, popModal } = useModals();

	return (
		<div>
			{modals.map((modal, i) => {
				return (
					<Modal
						key={i}
						{...modal}
						onCloseClick={() => {
							modal.onCloseClick?.();
							popModal();
						}}
					/>
				);
			})}
		</div>
	);
};

export default Modals;
