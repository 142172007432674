export interface ToolbarElement {
	id: number;
	format: string;
	type: string;
	options?: {
		text: string;
		value: string;
	}[];
}

const toolbarGroups: ToolbarElement[][] = [
	[
		{
			id: 1,
			format: "fontSize",
			type: "dropdown",
			options: [
				{ text: "h1", value: "h1" },
				{ text: "h2", value: "h2" },
				{ text: "h3", value: "h3" },
				{ text: "h4", value: "h4" },
				{ text: "h5", value: "h5" },
				{ text: "h6", value: "h6" },
				{ text: "h7", value: "h7" },
				{ text: "paragraphs", value: "paragraphs" },
				{ text: "small", value: "small" },
				{ text: "base", value: "text-base-dynamic" },
			],
		},
		{
			id: 2,
			format: "lineHeight",
			type: "dropdown",
			options: [
				{ text: "1", value: "leading-none" },
				{ text: "1.25", value: "leading-tight" },
				{ text: "1.375", value: "leading-snug" },
				{ text: "1.5", value: "leading-normal" },
				{ text: "1.625", value: "leading-relaxed" },
				{ text: "2", value: "leading-loose" },
			],
		},
		{
			id: 4,
			format: "italic",
			type: "mark",
		},
		{
			id: 5,
			format: "underline",
			type: "mark",
		},
		{
			id: 6,
			format: "strikethrough",
			type: "mark",
		},
		{
			id: 3,
			format: "bold",
			type: "dropdown",
			options: [
				{ text: "Normal", value: "font-normal" },
				{ text: "Semibold", value: "font-semibold" },
				{ text: "Bold", value: "font-bold" },
				{ text: "Extrabold", value: "font-extrabold" },
			],
		},
		// {
		// 	id: 3,
		// 	format: "bold",
		// 	type: "mark",
		// },

		{
			id: 7,
			format: "color",
			type: "color-picker",
		},
		{
			id: 17,
			format: "alignLeft",
			type: "block",
		},
		{
			id: 18,
			format: "alignCenter",
			type: "block",
		},
		{
			id: 19,
			format: "alignRight",
			type: "block",
		},
		{
			id: 20,
			format: "paddingBlock",
			type: "block",
		},
	],
];

export default toolbarGroups;
