import { useSetRecoilState } from "recoil";
import { chartDataSetFormOpenerAtom } from "../chartDataSets.recoil";

const CloseChartDataSetFormButton: React.FC = ({ children }) => {
	const setChartDataSetFormOpener = useSetRecoilState(chartDataSetFormOpenerAtom);

	return (
		<div
			className="mx-4 my-4 flex items-center font-bold text-uwgc-midblue hover:cursor-pointer"
			onClick={() => {
				setChartDataSetFormOpener(undefined);
			}}
		>
			{children}
		</div>
	);
};

export default CloseChartDataSetFormButton;
