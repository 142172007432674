import PublicControlViewData from "./PublicControlViewData";
import PublicControlViewerCommand from "./PublicControlViewerCommand";

export enum OutPublicControlWsDataTypes {
	VIEW,
	EXTEND,
	VIEWER_COMMAND,
}

type OutPublicControlWsDataTypeMap = {
	[OutPublicControlWsDataTypes.VIEW]: PublicControlViewData;
	[OutPublicControlWsDataTypes.EXTEND]: undefined;
	[OutPublicControlWsDataTypes.VIEWER_COMMAND]: PublicControlViewerCommand;
};

interface OutPublicControlWsData<
	T extends OutPublicControlWsDataTypes = OutPublicControlWsDataTypes,
> {
	type: T;
	data: OutPublicControlWsDataTypeMap[T];
}

export const createOutPublicControlWsData = <T extends OutPublicControlWsDataTypes>(
	type: T,
	data: OutPublicControlWsDataTypeMap[T],
): OutPublicControlWsData<T> => {
	return {
		type,
		data,
	};
};

export default OutPublicControlWsData;
