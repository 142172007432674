import { useStoryForm } from "../../../story.recoil";
import Frame from "./frame/Frame";

export interface Props {
	sectionIndex: number;
}

const FramesList: React.FC<Props> = ({ sectionIndex }) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const frames = story.sections?.[sectionIndex].frames;

	return (
		<div>
			{frames?.map((frame, i) => {
				return <Frame key={i} sectionIndex={sectionIndex} frameIndex={i} />;
			})}
		</div>
	);
};

export default FramesList;
