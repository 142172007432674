import { FilmIcon, MusicNoteIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { MdCheckBox } from "react-icons/md";
import { useRecoilValue } from "recoil";
import Loading from "../../../../../../../../../../shared/components/Loading/Loading";
import {
	AssetFileTypeIds,
	useGetAssetFilesLazyQuery,
} from "../../../../../../../../../../shared/generated/graphql";
import { getFullFilePath } from "../../../../../../../../../../shared/utils/Utils";
import {
	AssetFilesModes,
	selectedAssetFilesModeAtom,
	selectedAssetFileTagAtom,
	selectedAssetFileTypeIdAtom,
	useUploadAssetFileTags,
} from "../../assetFiles.recoil";
import { useAssetFilesGridReloader, useSelectedAssetFiles } from "./assetFilesGrid.recoil";
import "./assetFilesGrid.scss";

const AssetFilesGrid: React.FC = () => {
	const selectedAssetFilesMode = useRecoilValue(selectedAssetFilesModeAtom);
	const selectedAssetFileTag = useRecoilValue(selectedAssetFileTagAtom);
	const selectedAssetFileTypeId = useRecoilValue(selectedAssetFileTypeIdAtom);
	const { addUploadAssetFileTag, resetUploadAssetFileTags } = useUploadAssetFileTags();

	const { assetFilesGridReloader } = useAssetFilesGridReloader();
	const {
		selectedAssetFiles,
		addSelectedAssetFile,
		removeSelectedAssetFile,
		replaceSelectedAssetFile,
		resetSelectedAssetFiles,
	} = useSelectedAssetFiles();

	const [getAssetFiles, getAssetFilesRes] = useGetAssetFilesLazyQuery({
		fetchPolicy: "no-cache",
	});

	const hasSelectedFile = (assetfileId: number) => {
		return selectedAssetFiles.some((selectedAssetFile) => selectedAssetFile.id === assetfileId);
	};

	useEffect(() => {
		return () => {
			resetSelectedAssetFiles();
		};
	}, [resetSelectedAssetFiles]);

	useEffect(() => {
		void getAssetFiles({
			variables: {
				input: {
					filter: {
						typeId: selectedAssetFileTypeId,
						withTag: selectedAssetFileTag,
					},
				},
			},
		});
	}, [
		selectedAssetFileTypeId,
		getAssetFiles,
		assetFilesGridReloader,
		resetSelectedAssetFiles,
		selectedAssetFileTag,
	]);

	useEffect(() => {
		if (selectedAssetFilesMode === AssetFilesModes.Page && selectedAssetFileTag !== undefined) {
			addUploadAssetFileTag(selectedAssetFileTag);
		}

		return () => {
			if (selectedAssetFilesMode === AssetFilesModes.Page) {
				resetUploadAssetFileTags();
			}
		};
	}, [
		addUploadAssetFileTag,
		resetUploadAssetFileTags,
		selectedAssetFileTag,
		selectedAssetFilesMode,
	]);

	const assetFiles = getAssetFilesRes.data?.getAssetFiles.rows || [];

	// @TODO paginated grid
	return (
		<div className="asset-files-grid">
			{/* @TODO absolute loading */}
			{getAssetFilesRes.loading && <Loading loadingType="lds-ring" />}
			{assetFiles.map((assetFile, i) => {
				return (
					<div
						key={i}
						className={
							"relative flex max-w-[200px] flex-col items-center justify-end gap-2 overflow-hidden p-2 hover:backdrop-brightness-90 " +
							(hasSelectedFile(assetFile.id) ? "outline outline-2 outline-uwgc-green" : "")
						}
					>
						<input
							className="absolute h-full w-full cursor-pointer opacity-0"
							type={"checkbox"}
							checked={selectedAssetFiles.some(
								(selectedAssetFile) => selectedAssetFile.id === assetFile.id,
							)}
							onChange={(e) => {
								if (e.target.checked) {
									if (selectedAssetFilesMode === AssetFilesModes.Page) {
										addSelectedAssetFile(assetFile);
									} else {
										replaceSelectedAssetFile(assetFile);
									}
								} else {
									removeSelectedAssetFile(assetFile);
								}
							}}
						/>
						{hasSelectedFile(assetFile.id) && (
							<MdCheckBox className="absolute top-2 left-2 h-4 w-4 text-uwgc-green" />
						)}

						{/* @TODO temp style */}
						{/* @TODO GridItem */}
						{selectedAssetFileTypeId === AssetFileTypeIds.Image && (
							<img
								className="bg-checkered"
								style={{ width: "200px" }}
								src={getFullFilePath(assetFile.file)}
								alt={assetFile.file.name}
							/>
						)}
						{selectedAssetFileTypeId === AssetFileTypeIds.Audio && (
							<div className="flex h-14 w-14 items-center justify-center rounded-full bg-uwgc-slate text-uwgc-blue">
								<MusicNoteIcon className="h-5 w-5" />
							</div>
						)}
						{selectedAssetFileTypeId === AssetFileTypeIds.Video && (
							<div className="flex h-14 w-14 items-center justify-center rounded-full bg-uwgc-slate text-uwgc-blue">
								<FilmIcon className="h-5 w-5" />
							</div>
						)}
						<div className="w-full overflow-hidden">{assetFile.file.name}</div>
					</div>
				);
			})}
		</div>
	);
};

export default AssetFilesGrid;
