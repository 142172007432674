import { cloneArrPush, cloneArrSplice } from "gate3-utils";
import { useCallback, useEffect } from "react";
import { atom, useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import {
	FileFormData,
	FileFragment,
	FilePartial,
	AssetFileTypeIds,
	FileTagPartial,
	useEditFileMutation,
	AddOrEditFileTagsInput,
	AddOrEditFileTagInput,
	FileTagFormData,
} from "../../../../../../../../shared/generated/graphql";

export enum AssetFilesModes {
	Page,
	Picker,
}

export const selectedAssetFilesModeAtom = atom<AssetFilesModes>({
	key: "selectedFilesMode",
	default: AssetFilesModes.Page,
});

export const selectedAssetFileTagAtom = atom<string | undefined>({
	key: "selectedAssetFileTag",
	default: undefined,
});

export const selectedAssetFileTypeIdAtom = atom<AssetFileTypeIds>({
	key: "selectedAssetFileTypeId",
	default: AssetFileTypeIds.Image,
});

const assetFilesFoldersReloaderAtom = atom<boolean>({
	key: "assetFilesFoldersReloaderAtom",
	default: false,
});

export const useAssetFilesFoldersReloader = () => {
	const [assetFilesFoldersReloader, setAssetFilesFoldersReloader] = useRecoilState(
		assetFilesFoldersReloaderAtom,
	);

	const reloadAssetFilesFolders = useCallback(() => {
		setAssetFilesFoldersReloader((v) => !v);
	}, [setAssetFilesFoldersReloader]);

	return {
		assetFilesFoldersReloader,
		reloadAssetFilesFolders,
	};
};

const uploadAssetFileTagsAtom = atom<string[]>({
	key: "uploadAssetFileTags",
	default: [],
});

export const useUploadAssetFileTags = () => {
	const [uploadAssetFileTags, setUploadAssetFileTags] = useRecoilState(uploadAssetFileTagsAtom);
	const resetUploadAssetFileTags = useResetRecoilState(uploadAssetFileTagsAtom);

	const addUploadAssetFileTag = useCallback(
		(tag: string) => {
			setUploadAssetFileTags((state) => {
				return cloneArrPush(state, tag);
			});
		},
		[setUploadAssetFileTags],
	);

	return {
		uploadAssetFileTags,
		addUploadAssetFileTag,
		resetUploadAssetFileTags,
	};
};

export interface FileForm {
	isDirty: boolean;
	file: FileFormData;
}

export const fileFormAtom = atom<FileForm>({
	key: "fileForm",
	default: {
		isDirty: false,
		file: {
			fileTags: [{}],
		},
	},
});

export type FileFormPartial = Partial<FileForm>;

export interface UpdateFileFormInput {
	fields: FileFormPartial;
}

export const useFileForm = () => {
	const [fileForm, setFileForm] = useRecoilState(fileFormAtom);
	const resetFileForm = useResetRecoilState(fileFormAtom);

	const updateFileForm = useCallback(
		(input: UpdateFileFormInput) => {
			setFileForm((state) => {
				return {
					...state,
					...input.fields,
				};
			});
		},
		[setFileForm],
	);

	const initFile = useCallback(
		(file: FileFormData) => {
			const fileCopy = {
				...file,
			};

			fileCopy.fileTags = [...fileCopy.fileTags];
			fileCopy.fileTags.push({});

			updateFileForm({
				fields: {
					file: fileCopy,
				},
			});
		},
		[updateFileForm],
	);

	return {
		fileForm,
		updateFileForm,
		resetFileForm,
		initFile,
	};
};

export interface UpdateFileInput {
	fields?: FilePartial;
	fileTags?: {
		insert?: InsertFileTagInput;
		destroy?: DestroyFileTagInput;
		update?: UpdateFileTagInput;
	};
}

export interface InsertFileTagInput {
	fields: FileTagPartial;
}

export interface DestroyFileTagInput {
	filter: {
		index: number;
	};
}

export interface UpdateFileTagInput {
	filter: {
		index: number;
	};
	fields?: FileTagPartial;
}

const _useUpdateFileInternal = () => {
	const setFileForm = useSetRecoilState(fileFormAtom);

	const updateFileInternal = useCallback(
		(input: UpdateFileInput) => {
			setFileForm((state) => {
				return {
					...state,
					isDirty: true,
					file: _updateFile(state.file, input),
				};
			});
		},
		[setFileForm],
	);

	return {
		_setFileValue: updateFileInternal,
	};
};

const _updateFile = (file: FileFormData, input: UpdateFileInput) => {
	const fileClone: FileFormData = {
		...file,
		...input.fields,
	};

	if (input.fileTags) {
		const fileTags = input.fileTags;
		if (fileTags.insert) {
			fileClone.fileTags = cloneArrPush(fileClone.fileTags, fileTags.insert.fields);
		}
		if (fileTags.destroy) {
			fileClone.fileTags = cloneArrSplice(fileClone.fileTags, fileTags.destroy.filter.index, 1);
		}
		if (fileTags.update) {
			fileClone.fileTags = cloneArrSplice(
				fileClone.fileTags,
				fileTags.update.filter.index,
				1,
				_updateFileTag(fileClone.fileTags![fileTags.update.filter.index], fileTags.update),
			);
		}
	}

	return fileClone;
};

const _updateFileTag = (fileTag: FileTagFormData, input: UpdateFileTagInput) => {
	const fileTagClone = {
		...fileTag,
		...input.fields,
	};

	return fileTagClone;
};

export const useUpdateFile = () => {
	const { _setFileValue } = _useUpdateFileInternal();

	const updateFile = useCallback(
		(input: UpdateFileInput) => {
			_setFileValue(input);
		},
		[_setFileValue],
	);

	const insertFileTag = useCallback(
		(input: InsertFileTagInput) => {
			_setFileValue({
				fileTags: {
					insert: input,
				},
			});
		},
		[_setFileValue],
	);

	const destroyFileTag = useCallback(
		(input: DestroyFileTagInput) => {
			_setFileValue({
				fileTags: {
					destroy: input,
				},
			});
		},
		[_setFileValue],
	);

	return {
		updateFile,
		insertFileTag,
		destroyFileTag,
	};
};

const saveFileResAtom = atom<{
	isCalled: boolean;
	isSaving: boolean;
	isRequiredError: boolean;
	addFileData?: FileFragment;
	shouldRedirectToFileTags: boolean;
	error?: any;
}>({
	key: "saveFile",
	default: {
		isCalled: false,
		isSaving: false,
		isRequiredError: false,
		shouldRedirectToFileTags: false,
	},
});

export const useUpdateFileTag = () => {
	const { _setFileValue } = _useUpdateFileInternal();

	const updateFileTag = useCallback(
		(input: UpdateFileTagInput) => {
			_setFileValue({
				fileTags: {
					update: input,
				},
			});
		},
		[_setFileValue],
	);

	return {
		updateFileTag,
	};
};

export const useSaveFile = () => {
	const {
		fileForm: { file },
		updateFileForm,
	} = useFileForm();
	const [saveFileRes, setSaveFileRes] = useRecoilState(saveFileResAtom);
	const resetSaveFileRes = useResetRecoilState(saveFileResAtom);

	const [editFile, editFileRes] = useEditFileMutation();

	const saveFile = useCallback(
		async (form?: HTMLFormElement, shouldRedirectToFileTags: boolean = false) => {
			if (form && !form.checkValidity()) {
				setSaveFileRes((state) => {
					return {
						...state,
						isRequiredError: true,
					};
				});
				return;
			}

			setSaveFileRes((state) => {
				return {
					...state,
					isCalled: true,
					isSaving: true,
					isRequiredError: false,
					addFileData: undefined,
					shouldRedirectToFileTags,
					error: undefined,
				};
			});

			const getAddOrEditFileTags = (): AddOrEditFileTagsInput | undefined => {
				return {
					inputs: file.fileTags.reduce<AddOrEditFileTagInput[]>((inputs, fileTag, i) => {
						if (i === file.fileTags.length - 1) {
							return inputs;
						}

						return [
							...inputs,
							{
								filter:
									fileTag.id !== undefined
										? {
												id: fileTag.id,
										  }
										: undefined,
								fields: {
									tag: fileTag.tag!,
								},
							},
						];
					}, []),
				};
			};

			if (file.id !== undefined) {
				void editFile({
					variables: {
						input: {
							filter: {
								id: file.id,
							},
							fields: {
								name: file.name!,
							},
							addOrEditFileTags: getAddOrEditFileTags(),
						},
					},
				});
			}
		},
		[file, setSaveFileRes, editFile],
	);

	useEffect(() => {
		return () => {
			resetSaveFileRes();
		};
	}, [resetSaveFileRes]);

	useEffect(() => {
		if (editFileRes.called && !editFileRes.loading) {
			setSaveFileRes((state) => {
				return {
					...state,
					isSaving: false,
					error: editFileRes.error,
				};
			});
		}
	}, [editFileRes.called, editFileRes.error, editFileRes.loading, setSaveFileRes]);

	useEffect(() => {
		if (saveFileRes.isCalled && !saveFileRes.isSaving && !saveFileRes.error) {
			updateFileForm({
				fields: {
					isDirty: false,
				},
			});
		}
	}, [saveFileRes.error, saveFileRes.isCalled, saveFileRes.isSaving, updateFileForm]);

	return {
		saveFile,
		saveFileRes,
	};
};
