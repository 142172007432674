import React from "react";
import { RecoilRoot } from "recoil";
import DisplayMain from "./display/views/DisplayMain";
import { Version } from "./version";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo.client";
import env, { BuildTypes } from "./env";
import ControlMain from "./control/views/ControlMain";
import { ToastContainer } from "react-toastify";

import "./App.scss";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import "tw-elements";
import Modals from "./shared/g3Components/modal/Modals";

const App: React.FC = () => {
	return (
		<div>
			<RecoilRoot>
				<ApolloProvider client={apolloClient}>
					<ToastContainer
						position="top-center"
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable={false}
						pauseOnHover
						progressStyle={{ backgroundColor: "orange" }}
					/>
					{env.REACT_APP_BUILD_TYPE === BuildTypes.Control ? <ControlMain /> : <DisplayMain />}
					<Modals />
				</ApolloProvider>
			</RecoilRoot>
			<div style={{ display: "none" }}>v{Version.GIT_REV_COUNT()}</div>
		</div>
	);
};

export default App;
