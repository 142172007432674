import { useRecoilValue } from "recoil";
import Card from "../../components/Card";
import { selectedStoryAtom } from "./public.recoil";

const NeedsMet: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;

	return (
		<Card className="needs-met text-lg text-uwgc-charcoal">
			United Way 211 has met the need of
			<br />
			<span className="text-[52px] font-bold leading-snug text-uwgc-orange">
				{selectedStory!.name === "Housing" ? "88%" : "95%"}
			</span>
			<br />
			{selectedStory!.name === "Hunger" && <>of food related calls with the help of our donor</>}
			{selectedStory!.name === "Mental Health" && (
				<>of mental health related calls with the help of our donor</>
			)}
			{selectedStory!.name === "Housing" && (
				<>of housing & shelter related calls with the help of our donors</>
			)}
		</Card>
	);
};

export default NeedsMet;
