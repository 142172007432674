import { useEffect } from "react";
import Button from "../../../../../../../../shared/components/button/Button";
import { useDeleteStory } from "../story.recoil";
import { toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { PathName } from "../../../../../../../constants/PathName";
import { useModals } from "../../../../../../../../shared/g3Components/modal/modals.recoil";
import { XIcon } from "@heroicons/react/outline";
import { MdCheck } from "react-icons/md";

const DeleteStoryButton: React.FC = () => {
	const history = useHistory();

	const { pushModal, popModal } = useModals();
	const { deleteStory, deleteStoryRes } = useDeleteStory();

	useEffect(() => {
		if (deleteStoryRes.isCalled && !deleteStoryRes.isLoading) {
			if (!deleteStoryRes.error) {
				toast.success("Deleted Successfully!");
				history.push(PathName.BUILDER_STORIES());
			} else {
				toast.error("Delete error");
			}

			popModal();
		}
	}, [deleteStoryRes.error, deleteStoryRes.isCalled, deleteStoryRes.isLoading, history, popModal]);

	return (
		<Button
			className="red"
			onClick={() => {
				pushModal({
					render: (
						<div className="flex gap-4">
							<Button className="blue" onClick={() => popModal()}>
								No <XIcon className="h-5 w-5" />
							</Button>
							<Button
								className="red"
								onClick={() => {
									deleteStory();
								}}
							>
								Yes <MdCheck className="h-5 w-5" />
							</Button>
						</div>
					),
					title: "Delete Story",
				});
			}}
		>
			Delete Story
			<BsTrash className="h-5 w-5" />
		</Button>
	);
};

export default DeleteStoryButton;
