export class PathName {
	static BUILDER_ASSET_LIBRARY_IMAGES: string;
	public static get NOT_FOUND() {
		return "/404-page-not-found";
	}
	public static get UNKNOWN_ERROR() {
		return "/unknown-error";
	}
	public static get UNAUTHORIZED() {
		return "/unauthorized";
	}
	public static get LOGIN() {
		return "/login";
	}
	public static get DEFAULT() {
		return PathName.PUBLIC;
	}
	public static get PUBLIC() {
		return "/public";
	}
	public static get CONTROL() {
		return "/control";
	}
	public static get CONTROL_DASHBOARD() {
		return this.CONTROL + "/dashboard";
	}
	public static get BUILDER() {
		return "/builder";
	}
	public static BUILDER_STORIES(storyId?: string) {
		return this.BUILDER + "/stories" + (storyId !== undefined ? `/${storyId}` : "");
	}
	public static BUILDER_STORIES_SECTIONS(storyId: string) {
		return this.BUILDER_STORIES(storyId) + "/sections";
	}
	public static get BUILDER_ASSETS() {
		return this.BUILDER + "/assets";
	}
	public static get BUILDER_ASSETS_IMAGES() {
		return this.BUILDER_ASSETS + "/images";
	}
	public static get BUILDER_ASSETS_VIDEOS() {
		return this.BUILDER_ASSETS + "/videos";
	}
	public static get BUILDER_ASSETS_AUDIOS() {
		return this.BUILDER_ASSETS + "/audios";
	}
	public static get BUILDER_CHART_DATA_SETS() {
		return this.BUILDER + "/chart-data-sets";
	}
	public static get SETTINGS() {
		return "/settings";
	}
	public static SHARE_STORY(storyId: string) {
		return "/share-story/" + storyId;
	}
}
