import { useCallback } from "react";
import {
	createOutPublicControlWsData,
	OutPublicControlWsDataTypes,
} from "../../../types/publicControlWs/outPublicControlWsData/OutPublicControlWsData";
import PublicControlViewerCommand from "../../../types/publicControlWs/outPublicControlWsData/PublicControlViewerCommand";
import useSendOutPublicControlWsData from "./useSendOutPublicControlData";

const useSendPublicControlViewerCommand = () => {
	const sendOutPublicControlWsData = useSendOutPublicControlWsData();

	return useCallback(
		(data: PublicControlViewerCommand) => {
			sendOutPublicControlWsData(
				createOutPublicControlWsData(OutPublicControlWsDataTypes.VIEWER_COMMAND, data),
			);
		},
		[sendOutPublicControlWsData],
	);
};

export default useSendPublicControlViewerCommand;
