import { BiEditAlt } from "react-icons/bi";
import Button from "../../../../../../../../../../shared/components/button/Button";
import { useBuilderModals } from "../../../../../builder.recoil";
import { useContainerTempForm, useStoryForm } from "../../../story.recoil";
import ContainerForm from "./ContainerForm";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
}

const EditContainerButton: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
}) => {
	const { pushBuilderModal } = useBuilderModals();

	const {
		storyForm: { story },
	} = useStoryForm();
	const { updateContainerTempForm } = useContainerTempForm();

	const container =
		story.sections?.[sectionIndex]?.frames?.[frameIndex]?.layers?.[layerIndex]?.containers?.[
			containerIndex
		];

	return (
		<div>
			<Button
				className="blue"
				onClick={() => {
					updateContainerTempForm({
						fields: {
							containerIndex,
							containerTemp: {
								...container,
							},
						},
					});

					pushBuilderModal({
						render: (
							<ContainerForm
								sectionIndex={sectionIndex}
								frameIndex={frameIndex}
								layerIndex={layerIndex}
							/>
						),
						title: "Edit Container",
					});
				}}
			>
				Edit Container
				<BiEditAlt className="w-5 h-5" />
			</Button>
		</div>
	);
};

export default EditContainerButton;
