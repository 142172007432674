import { useEffect } from "react";
import { useGetFileTagsGroupedByTagLazyQuery } from "../../../../../../../../../shared/generated/graphql";
import { useAssetFilesFoldersReloader } from "../assetFiles.recoil";
import AssetFilesFolder from "./AssetFilesFolder";

const AssetFilesFolders: React.FC = () => {
	const [getFileTagsGroupedByTag, getFileTagsGroupedByTagRes] = useGetFileTagsGroupedByTagLazyQuery(
		{
			fetchPolicy: "cache-and-network",
		},
	);

	const { assetFilesFoldersReloader } = useAssetFilesFoldersReloader();

	useEffect(() => {
		void getFileTagsGroupedByTag();
	}, [getFileTagsGroupedByTag, assetFilesFoldersReloader]);

	const fileTags = getFileTagsGroupedByTagRes.data?.getFileTagsGroupedByTag.rows || [];

	return (
		<div className="flex flex-wrap gap-8 p-8">
			{[{ id: 0, tag: "No Tag" }, ...fileTags].map((fileTag) => {
				return <AssetFilesFolder key={fileTag.tag} tag={fileTag.tag} />;
			})}
		</div>
	);
};

export default AssetFilesFolders;
