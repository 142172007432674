import { useEffect } from "react";
import BgAudio from "./BgAudio";
import env, { EnvTypes } from "../../../env";
import {
	useStoryEngine,
	useStoryEngineState,
} from "../../../shared/state/storyEngine/storyEngine.recoil";
import Container from "./frame/layer/container/Container";
import StoryDebug from "./StoryDebug";
import classNames from "classnames";

const Story: React.FC = () => {
	const { storyEngine } = useStoryEngine();
	const storyEngineState = useStoryEngineState();

	// @TODO useMemo
	//frame animation setup
	const animation = [];
	const animationDuration = [];
	const animationDelay = [];
	const animationFillMode = [];

	if (storyEngineState.frame) {
		if (storyEngineState.frame.inAnimation != null) {
			animation.push(storyEngineState.frame.inAnimation);
			animationDuration.push("0.5s");
			animationDelay.push("0s");
			animationFillMode.push("forwards");
		}

		if (storyEngineState.frame.outAnimation != null) {
			animation.push(storyEngineState.frame.outAnimation);
			animationDuration.push("0.5s");
			animationDelay.push(storyEngineState.frame!.duration - 0.5 + "s");
			animationFillMode.push("forwards");
		}
	}

	useEffect(() => {
		return () => {
			if (storyEngine) {
				storyEngine.isPaused = true;
			}
		};
	}, [storyEngine]);

	useEffect(() => {
		// @TODO use ref, these document doesn't exist yet when the app starts
		const animations: any = document.querySelectorAll(".animate__animated");
		if (storyEngineState.isPaused) {
			animations.forEach((animation: { style: { animationPlayState: string } }) => {
				animation.style.animationPlayState = "paused";
			});
		} else {
			animations.forEach((animation: { style: { animationPlayState: string } }) => {
				animation.style.animationPlayState = "running";
			});
		}
	}, [storyEngineState.isPaused]);

	return (
		// @TODO temp style. make sure this page is 100%
		<div className="story" style={{ height: "100%", width: "100%" }}>
			<div
				className={
					"section " +
					(storyEngineState.section?.background != null
						? storyEngineState.section.background
						: "frame-default")
				}
			>
				{storyEngineState.frame && (
					<div
						className={classNames("frame", "animate__animated", storyEngineState.frame.background)}
						style={{
							animation: animation.join(", "),
							animationDuration: animationDuration.join(", "),
							animationDelay: animationDelay.join(", "),
							animationFillMode: animationFillMode.join(", "),
						}}
					>
						{storyEngineState.layers.map((layer) => {
							// @TODO useMemo
							//layer animation setup
							const animationLayer = [];
							const animationDurationLayer = [];
							const animationDelayLayer = [];
							const animationFillModeLayer = [];

							if (storyEngineState.frame) {
								if (layer.inAnimation != null) {
									animationLayer.push(layer.inAnimation);
									animationDurationLayer.push("0.5s");
									animationDelayLayer.push("0s");
									animationFillModeLayer.push("forwards");
								}

								if (layer.outAnimation != null) {
									animationLayer.push(layer.outAnimation);
									animationDurationLayer.push("0.5s");
									animationDelayLayer.push(storyEngineState.frame.duration - 0.5 + "s");
									animationFillModeLayer.push("forwards");
								}
							}

							return (
								<div
									key={layer.id}
									className={"layer animate__animated"}
									style={{
										animation: animationLayer.join(", "),
										animationDuration: animationDurationLayer.join(", "),
										animationDelay: animationDelayLayer.join(", "),
										animationFillMode: animationFillModeLayer.join(", "),
									}}
								>
									{layer.containers.map((container) => {
										return (
											<Container
												key={container.id}
												container={container}
												frameDuration={storyEngineState.frame!.duration}
											/>
										);
									})}
								</div>
							);
						})}
					</div>
				)}
			</div>
			<BgAudio />
			{/* {env.NODE_ENV === EnvTypes.Development && <StoryDebug />} */}
		</div>
	);
};

export default Story;
