import { XIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { MdDone } from "react-icons/md";
import Button from "../../../../../../../../../../shared/components/button/Button";
import Input from "../../../../../../../../../../shared/components/input/Input";
import Select from "../../../../../../../../../../shared/components/select/Select";
import { HtmlComponentTypeIds } from "../../../../../../../../../../shared/generated/graphql";
import { useBuilderModals } from "../../../../../builder.recoil";
import {
	getComponentContentType,
	useComponentTempForm,
	useStoryForm,
	useUpdateComponent,
	useUpdateComponentTemp,
	useUpdateContainer,
} from "../../../story.recoil";
import ChartComponentForm from "./ChartComponentForm";
import HtmlComponentForm from "./HtmlComponentForm";
import ImageComponentForm from "./ImageComponentForm";
import TextComponentForm from "./TextComponentForm";
import VideoComponentForm from "./VideoComponentForm";

enum ContentTypes {
	Text = "Text",
	Image = "Single Image",
	Video = "Video",
	Chart = "Chart",
	Html = "HTML",
}

const BG_AUDIO_FADE_DURATION = 5;
const BG_AUDIO_FADE_LEVEL = 0.3;

interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
}

const ComponentForm: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
}) => {
	const formRef = useRef<HTMLFormElement>(null);

	const {
		storyForm: { story },
	} = useStoryForm();

	const { popBuilderModal } = useBuilderModals();

	const {
		componentTempForm: { isDirty, componentIndex, componentTemp },
		resetComponentTempForm,
	} = useComponentTempForm();
	const { updateComponentTemp } = useUpdateComponentTemp();
	const { insertComponent } = useUpdateContainer(sectionIndex!, frameIndex!, layerIndex!);
	const { updateComponent } = useUpdateComponent(
		sectionIndex,
		frameIndex,
		layerIndex,
		containerIndex,
	);

	const contentType = useMemo(() => {
		return getComponentContentType(componentTemp);
	}, [componentTemp]);

	const _cancel = useCallback(() => {
		popBuilderModal();
	}, [popBuilderModal]);

	const _continue = useCallback(() => {
		if (!formRef.current || !formRef.current.checkValidity()) {
			return;
		}

		// @TODO custom validator
		if (
			componentTemp.videoComponents &&
			componentTemp.videoComponents.length !== 0 &&
			!componentTemp.videoComponents[0].assetFile
		) {
			return;
		}

		if (componentIndex !== undefined) {
			if (isDirty) {
				updateComponent({
					filter: {
						index: componentIndex,
					},
					fields: {
						...componentTemp,
					},
				});
			}
		} else {
			insertComponent(containerIndex, {
				fields: {
					...componentTemp,
				},
			});
		}
		popBuilderModal();
	}, [
		componentIndex,
		componentTemp,
		containerIndex,
		insertComponent,
		isDirty,
		popBuilderModal,
		updateComponent,
	]);

	useEffect(() => {
		return () => {
			resetComponentTempForm();
		};
	}, [resetComponentTempForm]);

	const _setContentType = (contentType: ContentTypes) => {
		switch (contentType) {
			case ContentTypes.Html:
				updateComponentTemp({
					fields: {
						chartComponents: [],
						htmlComponents: [
							{
								typeId: HtmlComponentTypeIds.Html,
							},
						],
						imageComponents: [],
						videoComponents: [],
					},
				});
				break;
			case ContentTypes.Text:
				updateComponentTemp({
					fields: {
						chartComponents: [],
						htmlComponents: [
							{
								typeId: HtmlComponentTypeIds.Text,
								html: JSON.stringify([
									{
										type: "paragaph",
										children: [
											{
												text: "",
											},
										],
									},
								]),
							},
						],
						imageComponents: [],
						videoComponents: [],
					},
				});
				break;
			case ContentTypes.Image:
				updateComponentTemp({
					fields: {
						chartComponents: [],
						htmlComponents: [],
						imageComponents: [{}],
						videoComponents: [],
					},
				});
				break;
			case ContentTypes.Video:
				updateComponentTemp({
					fields: {
						chartComponents: [],
						htmlComponents: [],
						imageComponents: [],
						videoComponents: [
							{
								bgAudioFadeIn: {
									duration: BG_AUDIO_FADE_DURATION,
									endVolume: BG_AUDIO_FADE_LEVEL,
								},
								bgAudioFadeOut: {
									duration: BG_AUDIO_FADE_DURATION,
									startVolume: BG_AUDIO_FADE_LEVEL,
								},
							},
						],
					},
				});
				break;
			case ContentTypes.Chart:
				updateComponentTemp({
					fields: {
						chartComponents: [{}],
						htmlComponents: [],
						imageComponents: [],
						videoComponents: [],
					},
				});
				break;
		}
	};

	const _getXComponentForm = useCallback(() => {
		switch (contentType) {
			case ContentTypes.Text:
				return <TextComponentForm />;
			case ContentTypes.Html:
				return <HtmlComponentForm />;
			case ContentTypes.Image:
				return <ImageComponentForm />;
			case ContentTypes.Video:
				return <VideoComponentForm />;
			case ContentTypes.Chart:
				return <ChartComponentForm />;
		}

		return undefined;
	}, [contentType]);

	const currSection = story.sections && story.sections[sectionIndex];
	const currFrame = currSection?.frames && currSection.frames[frameIndex];
	const currLayer = currFrame?.layers && currFrame.layers[layerIndex];
	const currContainer = currLayer?.containers && currLayer.containers[containerIndex];

	const screenSize = "container-component-size-" + currContainer?.screenIndices?.length;

	return (
		<div className={"flex flex-col gap-8 " + screenSize}>
			<form ref={formRef} className="flex flex-col gap-4">
				<div>
					<label>Content Type</label>
					<Select
						value={contentType}
						onChange={(e) => _setContentType(e.target.value as ContentTypes)}
					>
						{Object.values(ContentTypes).map((v) => {
							return (
								<option key={v} value={v}>
									{v}
								</option>
							);
						})}
					</Select>
				</div>

				<div>
					<label>Width</label>
					<Input
						value={componentTemp.width ?? ""}
						onChange={(e) => {
							updateComponentTemp({
								fields: {
									width: e.target.value,
								},
							});
						}}
					/>
				</div>

				<div>
					<label>Height</label>
					<Input
						value={componentTemp.height ?? ""}
						onChange={(e) => {
							updateComponentTemp({
								fields: {
									height: e.target.value,
								},
							});
						}}
					/>
				</div>

				{_getXComponentForm()}
			</form>
			<div className="flex justify-between gap-4">
				<Button className="red" onClick={() => _cancel()}>
					Cancel <XIcon className="h-5 w-5" />
				</Button>
				<Button className="blue" onClick={() => _continue()}>
					Done <MdDone className="h-5 w-5" />
				</Button>
			</div>
		</div>
	);
};

export default ComponentForm;
