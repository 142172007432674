import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { hasControlAtom, isDisplayConnectedAtom } from "../controlWs.recoil";
import InControlWsData, {
	InControlWsDataTypes,
} from "../../../types/controlWs/inControlWsData/InControlWsData";
import StoryEngineState from "../../../types/controlWs/inControlWsData/StoryEngineState";
import WsError from "../../../../shared/types/WsError";
import useHandleStoryEngineState from "./useHandleStoryEngineState";
import useHandleMapCommandData from "./useHandleMapCommandData";
import MapCommandData from "../../../types/controlWs/inControlWsData/mapCommandData/MapCommandData";

const useHandleInControlWsData = () => {
	const setHasControl = useSetRecoilState(hasControlAtom);
	const setIsDisplayConnected = useSetRecoilState(isDisplayConnectedAtom);
	const handleStoryEngineState = useHandleStoryEngineState();
	const handleMapCommandData = useHandleMapCommandData();

	return useCallback(
		(data: InControlWsData) => {
			switch (data.type) {
				case InControlWsDataTypes.ERROR:
					{
						const err = data.data as WsError;
						// @TODO show error on screen
						console.error(err.message);
					}
					break;
				case InControlWsDataTypes.IS_DISPLAY_CONNECTED:
					setIsDisplayConnected(data.data as boolean);
					break;
				case InControlWsDataTypes.STORY_ENGINE_STATE:
					handleStoryEngineState(data.data as StoryEngineState);
					break;
				case InControlWsDataTypes.MAP_COMMAND:
					handleMapCommandData(data.data as MapCommandData);
					break;
				case InControlWsDataTypes.HAS_CONTROL:
					setHasControl(data.data as boolean);
					break;
				default: {
					console.error("Unknown InControlWsDataTypes");
				}
			}
		},
		[handleStoryEngineState, handleMapCommandData],
	);
};

export default useHandleInControlWsData;
