import { atom } from "recoil";
import { StoryRowFragment } from "../../../shared/generated/graphql";

export const storiesAtom = atom<StoryRowFragment[]>({
	key: "stories_public",
	default: [],
});

export const canViewAtom = atom<boolean>({
	key: "canView",
	default: false,
});

export const isViewingAtom = atom<boolean>({
	key: "isViewing",
	default: false,
});

export const remainingTimeAtom = atom<number>({
	key: "remainingTime",
	default: 0,
});
