import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import Card from "../../components/Card";
import { selectedStoryAtom } from "./public.recoil";

const Donate: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const donationAmountPerCount =
		selectedStory.name === "Hunger" ? 2.36 : selectedStory.name === "Mental Health" ? 12 : 25;

	const [donation, setDonation] = useState(donationAmountPerCount);

	return (
		<Card className="donate text-center text-lg text-uwgc-charcoal">
			<div className="flex justify-center text-center text-2xl text-uwgc-charcoal">
				<div className="my-4 flex gap-3">
					<div className="text-6xl font-extrabold text-uwgc-midblue">
						${donation.toLocaleString(undefined, { minimumFractionDigits: 2 })}
					</div>
					<div className="flex flex-col items-center justify-center">
						<button
							className="up"
							onClick={() => {
								setDonation(Number((donation + donationAmountPerCount).toFixed(2)));
							}}
						>
							<ChevronUpIcon className="h-7 w-7" />
						</button>
						<button
							className="down"
							onClick={() =>
								donation > donationAmountPerCount &&
								setDonation(Number((donation - donationAmountPerCount).toFixed(2)))
							}
						>
							<ChevronDownIcon className="h-7 w-7" />
						</button>
					</div>
				</div>
			</div>
			<div>
				Provides{" "}
				<span className="font-semibold text-uwgc-orange">
					{selectedStory.name === "Hunger" && <>{(donation / 2.36).toFixed(0)} nutritious meals</>}
					{selectedStory.name === "Mental Health" && (
						<>{((donation / 12) * 30).toFixed(0)} mins of counseling</>
					)}
					{selectedStory.name === "Housing" && <>{(donation / 25).toFixed(0)} night of shelter</>}
				</span>{" "}
				{selectedStory.name === "Hunger" && (
					<>for local children and adults who would otherwise go without</>
				)}
				{selectedStory.name === "Mental Health" && (
					<>for local children and adults who are struggling with mental health</>
				)}
				{selectedStory.name === "Housing" && (
					<>for those struggling with homelessness in Cleveland.</>
				)}
			</div>
			<button
				className="mt-3 h-14 w-full rounded bg-uwgc-orange text-center align-middle font-thin text-white"
				onClick={() => {
					window.open(
						"https://give.unitedwaycleveland.org/engage/DonationForm.seam?teamName=&amount=" +
							donation.toLocaleString(undefined, { minimumFractionDigits: 2 }) +
							"&teamCampaignId=3996&emailID=&emailid=&contactContactId=&campaignName=United+Way+Donate+Now+CLEVELAND&pageName=UWGC&cid=620687&_ga=2.134119435.484489213.1636643917-2116951871.1633354438",
						"_blank",
					);
				}}
			>
				DONATE{" "}
				<span className="font-semibold">
					${donation.toLocaleString(undefined, { minimumFractionDigits: 2 })}
				</span>{" "}
				NOW
			</button>
		</Card>
	);
};

export default Donate;
