import { ChartDataSetFragment } from "../../../../../../../../../../shared/generated/graphql";
import ChartDataSetPickerDoneButton from "./ChartDataSetPickerDoneButton";

interface Props {
	onChartDataSetPick?: (chartDataSet: ChartDataSetFragment) => void;
}

const ChartDataSetPickerFooter: React.FC<Props> = ({ onChartDataSetPick }) => {
	return (
		<div className="flex justify-center gap-4">
			<ChartDataSetPickerDoneButton onChartDataSetPick={onChartDataSetPick} />
		</div>
	);
};

export default ChartDataSetPickerFooter;
