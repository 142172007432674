import { MdOutlineAddBox } from "react-icons/md";
import Button from "../../../../../../../../../../shared/components/button/Button";
import { useBuilderModals } from "../../../../../builder.recoil";
import FrameForm from "./FrameForm";

export interface Props {
	sectionIndex: number;
}

const AddFrameButton: React.FC<Props> = ({ sectionIndex }) => {
	const { pushBuilderModal } = useBuilderModals();

	return (
		<Button
			className="green"
			onClick={() => {
				pushBuilderModal({
					render: <FrameForm sectionIndex={sectionIndex} />,
					title: "Add Frame",
				});
			}}
		>
			Add Frames <MdOutlineAddBox className="h-5 w-5" />
		</Button>
	);
};

export default AddFrameButton;
