import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { PathName } from "../../../../../../../../constants/PathName";
import { useStoryForm } from "../../story.recoil";

const CloseSectionsLink: React.FC = () => {
	const {
		storyForm: { story },
	} = useStoryForm();

	return (
		<Link className="button charcoal" to={PathName.BUILDER_STORIES(story.id + "")}>
			CLOSE
			<AiOutlineClose className="w-4 h-4" />
		</Link>
	);
};

export default CloseSectionsLink;
