import { ChartDataSetFragment } from "../../../../../../../../../shared/generated/graphql";
import ChartDataSetPickerContent from "./chartDataSetPickerContent/ChartDataSetPickerContent";
import ChartDataSetPickerFooter from "./chartDataSetPickerFooter/ChartDataSetPickerFooter";

interface Props {
	onChartDataSetPick?: (chartDataSet: ChartDataSetFragment) => void;
}

const ChartDataSetPicker: React.FC<Props> = ({ onChartDataSetPick }) => {
	return (
		<div>
			<ChartDataSetPickerContent />
			<ChartDataSetPickerFooter onChartDataSetPick={onChartDataSetPick} />
		</div>
	);
};

export default ChartDataSetPicker;
