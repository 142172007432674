import { useCallback, useMemo, useState } from "react";
import { createEditor } from "slate";
import { Editable, RenderElementProps, RenderLeafProps, Slate, withReact } from "slate-react";
import Toolbar from "./Toolbar";
import "./textEditor.scss";

const Element: React.FC<any> = ({ attributes, children, element }) => {
	switch (element.type) {
		case "blockquote":
			return <blockquote {...attributes}>{children}</blockquote>;
		case "alignLeft":
			return (
				<div style={{ textAlign: "left", listStylePosition: "inside" }} {...attributes}>
					{children}
				</div>
			);
		case "alignCenter":
			return (
				<div style={{ textAlign: "center", listStylePosition: "inside" }} {...attributes}>
					{children}
				</div>
			);
		case "alignRight":
			return (
				<div style={{ textAlign: "right", listStylePosition: "inside" }} {...attributes}>
					{children}
				</div>
			);
		case "list-item":
			return <li {...attributes}>{children}</li>;
		case "orderedList":
			return (
				<ol type="1" {...attributes}>
					{children}
				</ol>
			);
		case "unorderedList":
			return <ul {...attributes}>{children}</ul>;
		case "paddingBlock":
			return (
				<span className={" px-6-dynamic "} {...attributes}>
					{children}
				</span>
			);
		default:
			return (
				<p className="leading-snug" {...attributes}>
					{children}
				</p>
			);
	}
};

const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
	const leafAny = leaf as any;

	if (leafAny.fontSize) {
		children = <span className={leafAny.fontSize}>{children}</span>;
	}
	if (leafAny.lineHeight) {
		children = <span className={leafAny.lineHeight}>{children}</span>;
	}

	if (leafAny.bold) {
		children = <span className={leafAny.bold}>{children}</span>;
	}

	if (leafAny.italic) {
		children = <em>{children}</em>;
	}
	if (leafAny.strikethrough) {
		children = <span style={{ textDecoration: "line-through" }}>{children}</span>;
	}
	if (leafAny.underline) {
		children = <u>{children}</u>;
	}

	if (leafAny.color !== undefined) {
		children = <span className={"text-" + leafAny.color}>{children}</span>;
	}
	if (leafAny.bgColor !== undefined) {
		children = <span className={"bg-" + leafAny.bgColor}>{children}</span>;
	}

	return <span {...attributes}>{children}</span>;
};

interface Props {
	isReadOnly?: boolean;
	initlialValue: any;
	onChange?: (value: any) => void;
}

const TextEditor: React.FC<Props> = ({ isReadOnly, initlialValue, onChange }) => {
	const editor = useMemo(() => withReact(createEditor() as any), []);

	const renderElement = useCallback((props) => {
		return <Element {...props} />;
	}, []);

	const renderLeaf = useCallback((props) => {
		return <Leaf {...props} />;
	}, []);

	return (
		<div className="text-editor">
			<Slate
				editor={editor}
				value={initlialValue}
				onChange={(v) => {
					onChange?.(v);
				}}
			>
				{!isReadOnly && <Toolbar />}
				{/* @TODO temp style */}
				{/* <div className="editor-wrapper" style={{ border: "1px solid #f3f3f3", padding: "0 10px" }}> */}
				<Editable
					readOnly={isReadOnly}
					// placeholder='Write something'
					renderElement={renderElement}
					renderLeaf={renderLeaf}
				/>
				{/* </div> */}
			</Slate>
		</div>
	);
};

export default TextEditor;
