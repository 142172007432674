import React from "react";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";

interface Crumbs {
	url: string;
	name: string;
}

interface Props {
	crumbs: Array<Crumbs>;
}

const Breadcrumb: React.FC<Props> = ({ crumbs }) => {
	const lastIndex = crumbs.length - 1;

	return (
		<nav className="flex" aria-label="Breadcrumb">
			<ol className="flex items-center space-x-2 crumbs">
				{crumbs.map((crumb, index) => (
					<li key={crumb.name}>
						<div className="flex items-center">
							{index !== 0 && <MdArrowForwardIos className="h-6 w-6 text-uwgc-charcoal" />}
							{lastIndex !== index ? (
								<Link
									to={crumb.url}
									className="ml-2 text-2xl text-uwgc-midblue underline hover:text-uwgc-midBlue"
								>
									{crumb.name}
								</Link>
							) : (
								<div className="ml-2 text-2xl text-uwgc-charcoal hover:text-gray-700">
									{crumb.name}
								</div>
							)}
						</div>
					</li>
				))}
			</ol>
		</nav>
	);
};

export default Breadcrumb;
