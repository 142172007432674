import React, { TextareaHTMLAttributes } from "react";
import "./textarea.scss";

type Props = TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea: React.FC<Props> = (props) => {
	const { className } = props;
	return (
		<textarea
			{...props}
			className={className !== undefined ? "textarea " + className : "textarea"}
		/>
	);
};

export default Textarea;
