import { useRecoilState, useSetRecoilState } from "recoil";
import { useEffect, useMemo } from "react";
import { selectedStoryAtom, selectedZipCodeAtom, zipCodeInputValueAtom } from "./public.recoil";
import { useGetStoriesLazyQuery } from "../../../shared/generated/graphql";
import { strToNumber } from "gate3-utils";
import { storiesAtom } from "./publicControl.recoil";

const VALID_STORY_NAMES = ["Hunger", "Mental Health", "Housing"];

const SelectIssue: React.FC = () => {
	const [stories, setStories] = useRecoilState(storiesAtom);
	const [selectedStory, setSelectedStory] = useRecoilState(selectedStoryAtom);
	const setZipCodeInputValue = useSetRecoilState(zipCodeInputValueAtom);
	const setSelectedZipCode = useSetRecoilState(selectedZipCodeAtom);

	const [getStories, getStoriesRes] = useGetStoriesLazyQuery();

	const _setSelectedStoryById = (id?: number) => {
		const story = stories.find((story) => story.id === id);
		setSelectedStory(story);

		setZipCodeInputValue(undefined);
		setSelectedZipCode(undefined);
	};

	useEffect(() => {
		void getStories();
	}, [getStories]);

	const hasFinishedGettingStories = useMemo(() => {
		return getStoriesRes.called && !getStoriesRes.loading;
	}, [getStoriesRes.called, getStoriesRes.loading]);

	useEffect(() => {
		if (hasFinishedGettingStories) {
			if (getStoriesRes.data) {
				setStories(
					getStoriesRes.data.getStories.rows.filter((story) =>
						VALID_STORY_NAMES.some((validStoryName) => validStoryName === story.name),
					),
				);
			} else {
				setStories([]);
			}
		}
	}, [hasFinishedGettingStories, getStoriesRes.data, setStories]);

	return (
		<div className="my-6 w-full px-2">
			<div className="info mb-6 px-2">
				<h1 className="my-3 text-3xl font-extrabold text-uwgc-midblue">Local Impact Board</h1>
				How do these issues affect my community?
			</div>
			<label className="mb-2 font-semibold text-uwgc-charcoal">Select issue</label>
			<select
				className="h-12 w-full max-w-xl border-gray-400 text-gray-400"
				onChange={(e) => _setSelectedStoryById(strToNumber(e.target.value))}
				value={selectedStory?.id}
			>
				{[undefined, ...stories].map((story, i) => {
					return (
						<option className="text-uwgc-charcoal" key={i} value={story?.id}>
							{story?.name}
						</option>
					);
				})}
			</select>
		</div>
	);
};

export default SelectIssue;
