import { atom, selector } from "recoil";
import WsConnectionStatus from "../../../shared/types/WsConnectionStatus";
import ControlWs from "../../classes/ControlWs";

export const controlWsAtom = atom<ControlWs | undefined>({
	key: "controlWs",
	default: undefined,
	dangerouslyAllowMutability: true,
});

export const controlWsConnectionStatusAtom = atom<WsConnectionStatus>({
	key: "controlWsConnectionStatus",
	default: WsConnectionStatus.NOT_CONNECTED,
});

export const hasControlAtom = atom<boolean>({
	key: "hasControl",
	default: false,
});

export const isDisplayConnectedAtom = atom<boolean>({
	key: "isDisplayConnected",
	default: false,
});

export const canControlSelector = selector<boolean>({
	key: "canControl",
	get: ({ get }) => {
		const controlWsConnectionStatus = get(controlWsConnectionStatusAtom);
		const hasControl = get(hasControlAtom);
		const isDisplayConnected = get(isDisplayConnectedAtom);
		return (
			controlWsConnectionStatus === WsConnectionStatus.CONNECTED && hasControl && isDisplayConnected
		);
	},
});
