import { FormEvent } from "react";
import Button from "../../../shared/components/button/Button";
import { LoginInput } from "../../../shared/generated/graphql";
import { useStates } from "../../../shared/hooks/reusable/useStates";
import useLogin from "../../state/user/useLogin";
import "./login.scss";
import { BsArrowRightShort } from "react-icons/bs";
import Input from "../../../shared/components/input/Input";

type LoginInputData = Partial<LoginInput>;

const Login: React.FC = () => {
	const [loginInput, setLoginInput] = useStates<LoginInputData>({});

	const { login, loginQueryState } = useLogin();

	const submit = (e: FormEvent) => {
		e.preventDefault();

		login({
			username: loginInput.username!,
			password: loginInput.password!,
			isClear: true,
		});
	};

	return (
		<div className="login">
			<div className="max-h-14 bg-white flex justify-end px-3">
				<img className="h-14" src="/assets/svg/UWGC_Logo.svg" />
			</div>
			<div className="content">
				<form className="card" onSubmit={(e) => submit(e)}>
					<div className="login-title mb-14">Please Log In</div>
					<div>
						<Input
							id="email-address"
							name="email"
							type="text"
							autoComplete="email"
							required
							placeholder="Username"
							disabled={loginQueryState.isLoading}
							value={loginInput.username ?? ""}
							onChange={(e) =>
								setLoginInput({
									username: e.target.value,
								})
							}
						/>
					</div>
					<div>
						<Input
							id="password"
							name="password"
							type="password"
							autoComplete="current-password"
							required
							placeholder="Password"
							disabled={loginQueryState.isLoading}
							value={loginInput.password ?? ""}
							onChange={(e) =>
								setLoginInput({
									password: e.target.value,
								})
							}
						/>
					</div>

					<Button className="orange text-lg" type="submit" disabled={loginQueryState.isLoading}>
						Login
						<BsArrowRightShort className="h-10 w-10" />
					</Button>
					{loginQueryState.error !== undefined ? "Wrong username or password" : ""}
				</form>
			</div>
		</div>
	);
};

export default Login;
