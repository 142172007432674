import AddComponentButton from "../../../components/componentForm/AddComponentButton";
import Components from "../../../components/Components";
import EditContainerFormButton from "../../containerForm/EditContainerButton";
import { ContainerPartial } from "../../../../../../../../../../../shared/generated/graphql";
import ContainerPreview from "./containerPreview/ContainerPreview";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
	container: ContainerPartial;
}

const Container: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
	container,
}) => {
	const classes = () => {
		let className = " ";
		if (container.screenIndices && container.screenIndices.length > 0) {
			className += " screen";
			container.screenIndices.forEach((i) => {
				className += "-" + i;
			});
		}

		return className;
	};

	return (
		<div className={"container-details" + classes()}>
			<ContainerPreview
				sectionIndex={sectionIndex}
				frameIndex={frameIndex}
				layerIndex={layerIndex}
				containerIndex={containerIndex}
			/>
			<div className="container-components flex flex-col gap-4 p-4">
				<Components
					sectionIndex={sectionIndex}
					frameIndex={frameIndex}
					layerIndex={layerIndex}
					containerIndex={containerIndex}
				/>
				<div className="flex justify-center flex-wrap gap-4">
					<EditContainerFormButton
						sectionIndex={sectionIndex}
						frameIndex={frameIndex}
						layerIndex={layerIndex}
						containerIndex={containerIndex}
					/>
					<AddComponentButton
						sectionIndex={sectionIndex}
						frameIndex={frameIndex}
						layerIndex={layerIndex}
						containerIndex={containerIndex}
					/>
				</div>
			</div>
		</div>
	);
};

export default Container;
