import { loadEnvs } from "load-envs";

export enum EnvTypes {
	Production = "production",
	Staging = "staging",
	Development = "development",
}

export enum BuildTypes {
	DisplaySystem = "display-system",
	DisplayStandalone = "display-standalone",
	Control = "control",
}

export interface Env {
	NODE_ENV: EnvTypes;
	REACT_APP_API_BASE_URL: string;
	REACT_APP_API_WS_BASE_URL: string;
	REACT_APP_FILES_DIR: string;
	REACT_APP_BUILD_TYPE: BuildTypes;
}

let env: Env;

const init = () => {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (env !== undefined) return;

	env = loadEnvs<Env>([
		{
			envKey: "NODE_ENV",
			options: {
				validValues: Object.values(EnvTypes),
			},
		},
		{
			envKey: "REACT_APP_API_BASE_URL",
		},
		{
			envKey: "REACT_APP_API_WS_BASE_URL",
		},
		{
			envKey: "REACT_APP_FILES_DIR",
			options: {
				isOptional: process.env.REACT_APP_BUILD_TYPE === BuildTypes.Control,
			},
		},
		{
			envKey: "REACT_APP_BUILD_TYPE",
			options: {
				validValues: Object.values(BuildTypes),
			},
		},
	]);
};

init();

export default env!;
