import Layers from "../../../../layers/Layers";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
}

const FrameContent: React.FC<Props> = ({ sectionIndex, frameIndex }) => {
	return (
		<div className="mb-8">
			<Layers sectionIndex={sectionIndex} frameIndex={frameIndex} />
		</div>
	);
};

export default FrameContent;
