import { ServerIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import Button from "../../../../../../../shared/components/button/Button";
import {
	selectedChartDataSetAtom,
	useChartDataSetForm,
	useSaveChartDataSet,
} from "../chartDataSets.recoil";

interface Props {
	form?: HTMLFormElement;
}

const SaveChartDataSetButton: React.FC<Props> = ({ form }) => {
	const {
		chartDataSetForm: { isDirty, chartDataSet },
	} = useChartDataSetForm();
	const setSelectedChartDataSet = useSetRecoilState(selectedChartDataSetAtom);

	const { saveChartDataSet, saveChartDataSetRes } = useSaveChartDataSet();

	useEffect(() => {
		if (saveChartDataSetRes.isCalled && !saveChartDataSetRes.isSaving) {
			if (!saveChartDataSetRes.error) {
				setSelectedChartDataSet(saveChartDataSetRes.addChartDataSetData);
				toast.success("Saved Successfully!");
			} else {
				toast.error("Save error");
			}
		}
	}, [
		saveChartDataSetRes.isCalled,
		saveChartDataSetRes.isSaving,
		saveChartDataSetRes.addChartDataSetData,
		saveChartDataSetRes.error,
		setSelectedChartDataSet,
	]);

	return (
		<Button
			className="blue"
			disabled={chartDataSet.id !== undefined && !isDirty}
			onClick={() => {
				if (form && !form.checkValidity()) {
					toast.error("Save error");
				}
				void saveChartDataSet(form);
			}}
		>
			Save <ServerIcon className="h-5 w-5" />
		</Button>
	);
};

export default SaveChartDataSetButton;
