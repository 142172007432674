import React, { useEffect } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Loading from "../../shared/components/Loading/Loading";
import { PathName } from "../constants/PathName";
import useGetCurrentUser from "../state/user/useGetCurrentUser";
import { currentUserAtom } from "../state/user/user.recoil";

export interface Props {
	exact?: boolean;
	path: string;
	render?: (routeProps: RouteComponentProps) => JSX.Element;
	component?: JSX.Element;
}

const PrivateRoute: React.FC<Props> = ({ exact, path, render, component }) => {
	const currentUser = useRecoilValue(currentUserAtom);
	const { getCurrentUser, getCurrentUserQueryState } = useGetCurrentUser();

	useEffect(() => {
		getCurrentUser();
	}, []);

	return (
		<Route
			exact={exact}
			path={path}
			render={(routeProps) => {
				if (currentUser) {
					return render ? render(routeProps) : component;
				} else if (!getCurrentUserQueryState.wasCalled || getCurrentUserQueryState.isLoading) {
					return <Loading loadingType="lds-ring" />;
				} else if (getCurrentUserQueryState.error !== undefined) {
					return <Redirect to={PathName.LOGIN} />;
				} else {
					return <Redirect to={PathName.UNKNOWN_ERROR} />;
				}
			}}
		/>
	);
};

export default PrivateRoute;
