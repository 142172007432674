import React, { useState, useEffect, useRef } from "react";
import "./table.scss";

export interface Row<T extends { [key: string]: any }> {
	className?: string;
	data: T;
}

export interface Column<T extends { [key: string]: any }> {
	name: string | JSX.Element;
	field?: keyof T;
	formattedField?: (row: Row<T>, index: number) => JSX.Element | undefined;
	isSortable?: boolean;
	sortField?: string; // uses field if undefined
	columnWidth?: string;
}

export interface Pagination {
	pages: number;
	currentPage: number;
	onPageClick: (page: number) => void;
}

interface Props<T extends { [key: string]: any }> {
	className?: string;
	columns: Array<Column<T> | undefined>;
	rows: Row<T>[];
	onSort?: (field: string, isDesc: boolean) => void;
	defaultSortField?: string;
	defaultIsSortDesc?: boolean;
	extraRow?: JSX.Element;
	pagination?: Pagination;
	ascIcon?: JSX.Element;
	descIcon?: JSX.Element;
	render?: () => JSX.Element; // render this instead of tbody, good for when loading
	isPivoted?: boolean;
}

const Table = <T extends { [key: string]: any }>({
	className,
	columns,
	rows,
	onSort,
	defaultSortField,
	defaultIsSortDesc,
	extraRow,
	pagination,
	ascIcon,
	descIcon,
	render,
	isPivoted,
}: Props<T>): React.FunctionComponentElement<Props<T>> => {
	const [sortField, setSortField] = useState<string | undefined>(defaultSortField);
	const [isDesc, setIsDesc] = useState(defaultIsSortDesc || false);

	const canSort = useRef(false);

	const onColumnHeaderClick = (field: string | keyof T | undefined) => {
		setSortField(field as string);
		if (field !== sortField) {
			setIsDesc(defaultIsSortDesc || false);
		} else {
			setIsDesc(!isDesc);
		}
	};

	useEffect(() => {
		if (canSort.current) {
			if (onSort && sortField !== undefined) {
				onSort(sortField, isDesc);
			}
		} else {
			canSort.current = true;
		}
	}, [sortField, isDesc]);

	return (
		<>
			<table className={className}>
				<thead>
					<tr>
						{columns.map((column, i) => {
							if (!column) {
								return undefined;
							}

							return (
								<th
									key={i}
									onClick={() =>
										column.isSortable
											? onColumnHeaderClick(
													column.sortField !== undefined ? column.sortField : column.field,
											  )
											: null
									}
									style={{ width: column.columnWidth !== undefined ? column.columnWidth : "auto" }}
									className={column.isSortable ? "sortable" : ""}
								>
									<div className="sorting-icons" style={{ display: "inline-flex" }}>
										{column.name}
										{sortField !== undefined &&
										sortField === (column.sortField !== undefined ? column.sortField : column.field)
											? isDesc
												? descIcon
												: ascIcon
											: ""}
									</div>
								</th>
							);
						})}
					</tr>
				</thead>
				{!render && (
					<tbody>
						{rows.map((row, i) => {
							return (
								<tr key={i} className={row.className}>
									{columns.map((column, j) => {
										if (!column) {
											return undefined;
										}

										let value;
										if (column.formattedField) {
											value = column.formattedField(row, i);
										} else {
											value = row.data[column.field!];
										}
										return isPivoted ? (
											<td key={i + "-" + j} col-name={column.name}>
												{value}
											</td>
										) : (
											<td key={i + "-" + j}>{value}</td>
										);
									})}
								</tr>
							);
						})}
						{extraRow}
					</tbody>
				)}
			</table>
			{render && render()}
			{!render && (
				<div className="py-6 gap-4">
					{pagination &&
						(() => {
							const paginationButtons: JSX.Element[] = [];
							for (let i = 0; i < pagination.pages; ++i) {
								paginationButtons.push(
									<button
										type="button"
										className="px-3 py-1 rounded-2xl"
										style={{
											backgroundColor: i === pagination.currentPage ? "#a29f9f" : "white",
											color: i === pagination.currentPage ? "white" : "gray",
										}}
										key={i}
										onClick={() => {
											pagination.onPageClick(i);
										}}
									>
										{i + 1}
									</button>,
								);
							}
							return paginationButtons;
						})()}
				</div>
			)}
		</>
	);
};

export default Table;
