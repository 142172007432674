import Frames from "../../../../frames/Frames";

export interface Props {
	sectionIndex: number;
}

const SectionContent: React.FC<Props> = ({ sectionIndex }) => {
	return (
		<div className="py-4">
			<Frames sectionIndex={sectionIndex} />
		</div>
	);
};

export default SectionContent;
