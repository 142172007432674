import { useStoryForm } from "../../../../../story.recoil";
import EditFrameButton from "../../../frameForm/EditFrameButton";
import AddLayerButton from "../frameContent/layerTabs/layerForm/AddLayerButton";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
}

const FrameHeader: React.FC<Props> = ({ sectionIndex, frameIndex }) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const section = story.sections?.[sectionIndex];

	return (
		<div className="flex justify-between items-center">
			<div className="flex items-center gap-4 text-uwgc-green font-bold">
				<div>{section?.name + " " + (frameIndex + 1)}</div>
				<EditFrameButton sectionIndex={sectionIndex} frameIndex={frameIndex} />
			</div>
			<AddLayerButton sectionIndex={sectionIndex} frameIndex={frameIndex} />
		</div>
	);
};

export default FrameHeader;
