export enum ControlStoryCommandDataTypes {
	SET_STORY,
	SET_SECTION,
	SET_TIME,
	SET_IS_PAUSED,
}

type ControlStoryCommandDataTypeMap = {
	[ControlStoryCommandDataTypes.SET_STORY]: number | undefined;
	[ControlStoryCommandDataTypes.SET_SECTION]: number;
	[ControlStoryCommandDataTypes.SET_TIME]: number;
	[ControlStoryCommandDataTypes.SET_IS_PAUSED]: boolean;
};

interface ControlStoryCommandData<
	T extends ControlStoryCommandDataTypes = ControlStoryCommandDataTypes,
> {
	type: T;
	data: ControlStoryCommandDataTypeMap[T];
}

export const createControlStoryCommandData = <T extends ControlStoryCommandDataTypes>(
	type: T,
	data: ControlStoryCommandDataTypeMap[T],
): ControlStoryCommandData<T> => {
	return {
		type,
		data,
	};
};

export default ControlStoryCommandData;
