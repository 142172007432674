import { cloneArrPush, cloneArrSplice } from "gate3-utils";
import { useCallback, useEffect } from "react";
import { atom, useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import {
	AddOrEditChartDataPointInput,
	AddOrEditChartDataPointsInput,
	ChartDataPointFormData,
	ChartDataPointPartial,
	ChartDataSetFormData,
	ChartDataSetFragment,
	ChartDataSetPartial,
	ChartDataSetRowFragment,
	useAddChartDataSetMutation,
	useEditChartDataSetMutation,
} from "../../../../../../shared/generated/graphql";

export interface ChartDataSetFormOpener {
	id?: number; // adding if undefined. editing if defined
}

// opens form if not undefined
export const chartDataSetFormOpenerAtom = atom<ChartDataSetFormOpener | undefined>({
	key: "chartDataSetFormOpener",
	default: undefined,
});

export interface ChartDataSetForm {
	isDirty: boolean;
	chartDataSet: ChartDataSetFormData;
}

export const chartDataSetFormAtom = atom<ChartDataSetForm>({
	key: "chartDataSetForm",
	default: {
		isDirty: false,
		chartDataSet: {
			chartDataPoints: [],
		},
	},
});

export type ChartDataSetFormPartial = Partial<ChartDataSetForm>;

export interface UpdateChartDataSetFormInput {
	fields: ChartDataSetFormPartial;
}

export const useChartDataSetForm = () => {
	const [chartDataSetForm, setChartDataSetForm] = useRecoilState(chartDataSetFormAtom);
	const resetChartDataSetForm = useResetRecoilState(chartDataSetFormAtom);

	const updateChartDataSetForm = useCallback(
		(input: UpdateChartDataSetFormInput) => {
			setChartDataSetForm((state) => {
				return {
					...state,
					...input.fields,
				};
			});
		},
		[setChartDataSetForm],
	);

	const initChartDataSet = useCallback(
		(chartDataSet: ChartDataSetFormData) => {
			updateChartDataSetForm({
				fields: {
					chartDataSet,
				},
			});
		},
		[updateChartDataSetForm],
	);

	return {
		chartDataSetForm,
		updateChartDataSetForm,
		resetChartDataSetForm,
		initChartDataSet,
	};
};

export interface UpdateChartDataSetInput {
	fields?: ChartDataSetPartial;
	chartDataPoints?: {
		insert?: InsertChartDataPointInput;
		destroy?: DestroyChartDataPointInput;
		update?: UpdateChartDataPointInput;
	};
}

export interface InsertChartDataPointInput {
	fields: ChartDataPointPartial;
}

export interface DestroyChartDataPointInput {
	filter: {
		index: number;
	};
}

export interface UpdateChartDataPointInput {
	filter: {
		index: number;
	};
	fields?: ChartDataPointPartial;
}

const _useUpdateChartDataSetInternal = () => {
	const setChartDataSetForm = useSetRecoilState(chartDataSetFormAtom);

	const updateChartDataSetInternal = useCallback(
		(input: UpdateChartDataSetInput) => {
			setChartDataSetForm((state) => {
				return {
					...state,
					isDirty: true,
					chartDataSet: _updateChartDataSet(state.chartDataSet, input),
				};
			});
		},
		[setChartDataSetForm],
	);

	return {
		_setChartDataSetValue: updateChartDataSetInternal,
	};
};

const _updateChartDataSet = (
	chartDataSet: ChartDataSetFormData,
	input: UpdateChartDataSetInput,
) => {
	const chartDataSetClone: ChartDataSetFormData = {
		...chartDataSet,
		...input.fields,
	};

	if (input.chartDataPoints) {
		const chartDataPoints = input.chartDataPoints;
		if (chartDataPoints.insert) {
			chartDataSetClone.chartDataPoints = cloneArrPush(
				chartDataSetClone.chartDataPoints,
				chartDataPoints.insert.fields,
			);
		}
		if (chartDataPoints.destroy) {
			chartDataSetClone.chartDataPoints = cloneArrSplice(
				chartDataSetClone.chartDataPoints,
				chartDataPoints.destroy.filter.index,
				1,
			);
		}
		if (chartDataPoints.update) {
			chartDataSetClone.chartDataPoints = cloneArrSplice(
				chartDataSetClone.chartDataPoints,
				chartDataPoints.update.filter.index,
				1,
				_updateChartDataPoint(
					chartDataSetClone.chartDataPoints![chartDataPoints.update.filter.index],
					chartDataPoints.update,
				),
			);
		}
	}

	return chartDataSetClone;
};

const _updateChartDataPoint = (
	chartDataPoint: ChartDataPointFormData,
	input: UpdateChartDataPointInput,
) => {
	const chartDataPointClone = {
		...chartDataPoint,
		...input.fields,
	};

	return chartDataPointClone;
};

export const useUpdateChartDataSet = () => {
	const { _setChartDataSetValue } = _useUpdateChartDataSetInternal();

	const updateChartDataSet = useCallback(
		(input: UpdateChartDataSetInput) => {
			_setChartDataSetValue(input);
		},
		[_setChartDataSetValue],
	);

	const insertChartDataPoint = useCallback(
		(input: InsertChartDataPointInput) => {
			_setChartDataSetValue({
				chartDataPoints: {
					insert: input,
				},
			});
		},
		[_setChartDataSetValue],
	);

	const destroyChartDataPoint = useCallback(
		(input: DestroyChartDataPointInput) => {
			_setChartDataSetValue({
				chartDataPoints: {
					destroy: input,
				},
			});
		},
		[_setChartDataSetValue],
	);

	return {
		updateChartDataSet,
		insertChartDataPoint,
		destroyChartDataPoint,
	};
};

export const useUpdateChartDataPoint = () => {
	const { _setChartDataSetValue } = _useUpdateChartDataSetInternal();

	const updateChartDataPoint = useCallback(
		(input: UpdateChartDataPointInput) => {
			_setChartDataSetValue({
				chartDataPoints: {
					update: input,
				},
			});
		},
		[_setChartDataSetValue],
	);

	return {
		updateChartDataPoint,
	};
};

const saveChartDataSetResAtom = atom<{
	isCalled: boolean;
	isSaving: boolean;
	isRequiredError: boolean;
	addChartDataSetData?: ChartDataSetFragment;
	shouldRedirectToChartDataPoints: boolean;
	error?: any;
}>({
	key: "saveChartDataSet",
	default: {
		isCalled: false,
		isSaving: false,
		isRequiredError: false,
		shouldRedirectToChartDataPoints: false,
	},
});

export const useSaveChartDataSet = () => {
	const {
		chartDataSetForm: { chartDataSet },
		updateChartDataSetForm,
	} = useChartDataSetForm();
	const [saveChartDataSetRes, setSaveChartDataSetRes] = useRecoilState(saveChartDataSetResAtom);
	const resetSaveChartDataSetRes = useResetRecoilState(saveChartDataSetResAtom);

	const [addChartDataSet, addChartDataSetRes] = useAddChartDataSetMutation();
	const [editChartDataSet, editChartDataSetRes] = useEditChartDataSetMutation();

	const saveChartDataSet = useCallback(
		async (form?: HTMLFormElement, shouldRedirectToChartDataPoints: boolean = false) => {
			if (form && !form.checkValidity()) {
				setSaveChartDataSetRes((state) => {
					return {
						...state,
						isRequiredError: true,
					};
				});
				return;
			}

			setSaveChartDataSetRes((state) => {
				return {
					...state,
					isCalled: true,
					isSaving: true,
					isRequiredError: false,
					addChartDataSetData: undefined,
					shouldRedirectToChartDataPoints,
					error: undefined,
				};
			});

			const getAddOrEditChartDataPoints = (): AddOrEditChartDataPointsInput | undefined => {
				return {
					inputs: chartDataSet.chartDataPoints.map(
						(chartDataPoint): AddOrEditChartDataPointInput => {
							return {
								filter:
									chartDataPoint.id !== undefined
										? {
												id: chartDataPoint.id,
										  }
										: undefined,
								fields: {
									name: chartDataPoint.name!,
									value: chartDataPoint.value!,
									fill: chartDataPoint.fill!,
								},
							};
						},
					),
				};
			};

			if (chartDataSet.id !== undefined) {
				void editChartDataSet({
					variables: {
						input: {
							filter: {
								id: chartDataSet.id,
							},
							fields: {
								name: chartDataSet.name!,
							},
							addOrEditChartDataPoints: getAddOrEditChartDataPoints(),
						},
					},
				});
			} else {
				void addChartDataSet({
					variables: {
						input: {
							fields: {
								name: chartDataSet.name!,
							},
							addOrEditChartDataPoints: getAddOrEditChartDataPoints(),
						},
					},
				});
			}
		},
		[chartDataSet, setSaveChartDataSetRes, addChartDataSet, editChartDataSet],
	);

	useEffect(() => {
		return () => {
			resetSaveChartDataSetRes();
		};
	}, [resetSaveChartDataSetRes]);

	useEffect(() => {
		if (addChartDataSetRes.called && !addChartDataSetRes.loading) {
			setSaveChartDataSetRes((state) => {
				return {
					...state,
					isSaving: false,
					addChartDataSetData: addChartDataSetRes.data
						? addChartDataSetRes.data.addChartDataSets.rows[0]
						: undefined,
					error: addChartDataSetRes.error,
				};
			});
		}
	}, [
		addChartDataSetRes.called,
		addChartDataSetRes.loading,
		addChartDataSetRes.data,
		setSaveChartDataSetRes,
		addChartDataSetRes.error,
	]);

	useEffect(() => {
		if (editChartDataSetRes.called && !editChartDataSetRes.loading) {
			setSaveChartDataSetRes((state) => {
				return {
					...state,
					isSaving: false,
					error: editChartDataSetRes.error,
				};
			});
		}
	}, [
		editChartDataSetRes.called,
		editChartDataSetRes.error,
		editChartDataSetRes.loading,
		setSaveChartDataSetRes,
	]);

	useEffect(() => {
		if (
			saveChartDataSetRes.isCalled &&
			!saveChartDataSetRes.isSaving &&
			!saveChartDataSetRes.error
		) {
			updateChartDataSetForm({
				fields: {
					isDirty: false,
				},
			});
		}
	}, [
		saveChartDataSetRes.error,
		saveChartDataSetRes.isCalled,
		saveChartDataSetRes.isSaving,
		updateChartDataSetForm,
	]);

	return {
		saveChartDataSet,
		saveChartDataSetRes,
	};
};

// Selected Chart Data Set
export const selectedChartDataSetAtom = atom<ChartDataSetRowFragment | undefined>({
	key: "selectedChartDataSet",
	default: undefined,
});
