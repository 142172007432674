import WsError from "../../../../shared/types/WsError";
import MapCommandData from "./mapCommandData/MapCommandData";
import StoryEngineState from "./StoryEngineState";

export enum InControlWsDataTypes {
	ERROR, // WsError
	IS_DISPLAY_CONNECTED, // boolean
	STORY_ENGINE_STATE, // StoryEngineState
	MAP_COMMAND, // MapCommandData
	HAS_CONTROL, // boolean
}

interface InControlWsData {
	type: InControlWsDataTypes;
	data: WsError | boolean | StoryEngineState | MapCommandData;
}

export default InControlWsData;
