import {
	DesktopComputerIcon,
	DeviceMobileIcon,
	RefreshIcon,
	StatusOfflineIcon,
	StatusOnlineIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import {
	hasControlAtom,
	isDisplayConnectedAtom,
	controlWsConnectionStatusAtom,
} from "../../../state/controlWs/controlWs.recoil";
import useSendOutControlWsData from "../../../state/controlWs/sendOutData/useSendOutControlWsData";
import "../../../styles/components/display_status.scss";
import {
	createOutControlWsData,
	OutControlWsDataTypes,
} from "../../../types/controlWs/outControlWsData/OutControlWsData";
import WsConnectionStatus from "../../../../shared/types/WsConnectionStatus";

const DisplayStatus: React.FC = () => {
	const [isDisplayReloading, setIsDisplayReloading] = useState(false);

	const controlWsConnectionStatus = useRecoilValue(controlWsConnectionStatusAtom);
	const hasControl = useRecoilValue(hasControlAtom);
	const isDisplayConnected = useRecoilValue(isDisplayConnectedAtom);
	const sendOutControlWsData = useSendOutControlWsData();

	const reloadDisplay = () => {
		sendOutControlWsData(createOutControlWsData(OutControlWsDataTypes.RELOAD_DISPLAY, undefined));

		// fake loading
		setIsDisplayReloading(true);
		setTimeout(() => {
			setIsDisplayReloading(false);
		}, 1000);
	};

	return (
		<div className="display-status">
			{controlWsConnectionStatus === WsConnectionStatus.NOT_CONNECTED ? (
				<StatusOfflineIcon className="h-5 w-5 text-uwgc-midGrey" />
			) : controlWsConnectionStatus === WsConnectionStatus.CONNECTING ? (
				<StatusOnlineIcon className="h-5 w-5 text-uwgc-orange" />
			) : (
				<StatusOnlineIcon className="h-5 w-5 text-uwgc-green" />
			)}
			{hasControl ? (
				<DeviceMobileIcon className="h-5 w-5 text-uwgc-green" />
			) : (
				<DeviceMobileIcon
					className="h-5 w-5 text-uwgc-midGrey cursor-pointer"
					onClick={() =>
						sendOutControlWsData(
							createOutControlWsData(OutControlWsDataTypes.OVERRIDE_CONNECTION, undefined),
						)
					}
				/>
			)}
			{isDisplayConnected ? (
				isDisplayReloading ? (
					<RefreshIcon className="h-5 w-5 text-uwgc-yellow" />
				) : (
					<DesktopComputerIcon
						className={"h-5 w-5 text-uwgc-green " + (hasControl ? "cursor-pointer " : "")}
						onClick={() => {
							if (hasControl) {
								reloadDisplay();
							}
						}}
					/>
				)
			) : (
				<DesktopComputerIcon className="h-5 w-5 text-uwgc-midGrey" />
			)}
		</div>
	);
};

export default DisplayStatus;
