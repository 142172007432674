import React from "react";
import Containers from "../../../containers/Containers";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
}

const Layer: React.FC<Props> = ({ sectionIndex, frameIndex, layerIndex }) => {
	return (
		<div className="layer">
			<Containers sectionIndex={sectionIndex} frameIndex={frameIndex} layerIndex={layerIndex} />
		</div>
	);
};

export default Layer;
