import React from "react";

interface Props
	extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	className?: string;
}

const Card: React.FC<Props> = ({ children, className, ...divProps }) => {
	return (
		<div className={"bg-white rounded-md p-3 m-1 my-3 " + className} {...divProps}>
			{children}
		</div>
	);
};

export default Card;
