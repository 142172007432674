import Textarea from "../../../../../../../../../../shared/components/textarea/Textarea";
import { useComponentTempForm, useUpdateComponentTemp } from "../../../story.recoil";

const HtmlComponentForm: React.FC = () => {
	const {
		componentTempForm: { componentTemp },
	} = useComponentTempForm();
	const { updateHtmlComponentTemp } = useUpdateComponentTemp();

	return (
		<>
			HTML COMPONENT FORM
			<div>
				<label>Enter HTML (no javascript allowed)</label>
				<Textarea
					required
					value={componentTemp.htmlComponents![0].html ?? ""}
					onChange={(e) => {
						updateHtmlComponentTemp({
							fields: {
								html: e.target.value,
							},
						});
					}}
				/>
			</div>
		</>
	);
};

export default HtmlComponentForm;
