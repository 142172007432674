import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useGetStoriesFullLazyQuery } from "../../../../../../../shared/generated/graphql";
import { storiesAtom } from "../../../../../../state/stories.recoil";
import { selectedStoryAtom } from "./stories.recoil";
import { storyEngineStateAtom } from "./story/storyEngine.recoil";
import StoryCoverImage from "./story/StoryCoverImage";
import Loading from "../../../../../../../shared/components/Loading/Loading";

const StoryList: React.FC = () => {
	const [stories, setStories] = useRecoilState(storiesAtom);
	const setSelectedStory = useSetRecoilState(selectedStoryAtom);
	const storyEngineState = useRecoilValue(storyEngineStateAtom);

	const [getStories, getStoriesRes] = useGetStoriesFullLazyQuery({
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		void getStories({
			variables: {
				input: {
					filter: {
						isFeatured: true,
					},
					orderBys: [
						{
							by: "orderNum",
						},
						{
							by: "createdAt",
						},
						{
							by: "name",
						},
					],
				},
			},
		});
	}, [getStories]);

	const hasFinishedGettingStories = useMemo(() => {
		return getStoriesRes.called && !getStoriesRes.loading;
	}, [getStoriesRes.called, getStoriesRes.loading]);

	useEffect(() => {
		if (hasFinishedGettingStories) {
			if (getStoriesRes.data) {
				setStories(getStoriesRes.data.getStories.rows);
			} else {
				setStories([]);
			}
		}
	}, [hasFinishedGettingStories, getStoriesRes.data, setStories]);

	return (
		<>
			{getStoriesRes.loading && <Loading loadingType="lds-ring" />}
			<div className="story-list">
				{stories.map((story) => {
					return (
						<button key={story.id} onClick={() => setSelectedStory(story)}>
							<StoryCoverImage story={story} />

							<div className={storyEngineState.story?.id === story.id ? "text-uwgc-yellow " : ""}>
								{story.name}
							</div>
						</button>
					);
				})}
			</div>
		</>
	);
};

export default StoryList;
