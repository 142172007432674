import { ViewGridIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import Input from "../../../../../../../../../../shared/components/input/Input";
import Select from "../../../../../../../../../../shared/components/select/Select";
import {
	ChartTypeFragment,
	ChartTypeIds,
	useGetChartTypesLazyQuery,
} from "../../../../../../../../../../shared/generated/graphql";
import { useComponentTempForm, useUpdateComponentTemp } from "../../../story.recoil";
import OpenChartDataSetPickerButton from "../../chartDataSetPicker/OpenChartDataSetPickerButton";

const ChartComponentForm: React.FC = () => {
	const [chartTypes, setChartTypes] = useState<ChartTypeFragment[]>([]);

	const {
		componentTempForm: { componentTemp },
	} = useComponentTempForm();
	const { updateChartComponentTemp } = useUpdateComponentTemp();

	const chartComponent = componentTemp.chartComponents![0];

	const [getChartTypes, getChartTypesRes] = useGetChartTypesLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		void getChartTypes();
	}, [getChartTypes]);

	useEffect(() => {
		if (
			getChartTypesRes.called &&
			!getChartTypesRes.loading &&
			getChartTypesRes.data &&
			getChartTypesRes.data.getChartTypes.rows.length !== 0
		) {
			if (!chartComponent.typeId) {
				updateChartComponentTemp({
					fields: {
						typeId: getChartTypesRes.data.getChartTypes.rows[0].id,
					},
				});
			}
			setChartTypes(getChartTypesRes.data.getChartTypes.rows);
		}
	}, [
		chartComponent.typeId,
		getChartTypesRes.called,
		getChartTypesRes.data,
		getChartTypesRes.loading,
		updateChartComponentTemp,
	]);

	return (
		<>
			<div>
				<Select
					value={chartComponent.typeId ?? ""}
					onChange={(e) => {
						updateChartComponentTemp({
							fields: {
								typeId: e.target.value as ChartTypeIds,
							},
						});
					}}
				>
					{chartTypes.map((chartType) => {
						return (
							<option key={chartType.id} value={chartType.id}>
								{chartType.name}
							</option>
						);
					})}
				</Select>
				<label>Data Set*</label>
				<div>
					<Input
						className={"bg-uwgc-midGrey text-uwgc-charcoal hover:cursor-default"}
						required
						onFocus={(e) => {
							e.target.blur();
						}}
						value={chartComponent.chartDataSet?.name ?? ""}
					/>
				</div>
				<OpenChartDataSetPickerButton
					onChartDataSetPick={(chartDataSet) => {
						updateChartComponentTemp({
							fields: {
								chartDataSet: {
									...chartDataSet,
								},
							},
						});
					}}
				>
					SELECT FROM DATA SETS <ViewGridIcon className="h-5 w-5" />
				</OpenChartDataSetPickerButton>
			</div>
		</>
	);
};

export default ChartComponentForm;
