import { useState } from "react";
import { StoryFragment } from "../../../../../../../../shared/generated/graphql";
import { getFullFilePath } from "../../../../../../../../shared/utils/Utils";

interface Props {
	story: StoryFragment;
}
const StoryCoverImage: React.FC<Props> = ({ story }) => {
	const [hasImage, setHasImage] = useState(true);

	return (
		<>
			{hasImage ? (
				<img
					src={story.coverImageAssetFile ? getFullFilePath(story.coverImageAssetFile.file) : ""}
					alt="food"
					onError={(err) => {
						setHasImage(false);
					}}
				/>
			) : (
				<div className="bg-uwgc-charcoal w-full h-full absolute -z-50 rounded-md"></div>
			)}
		</>
	);
};

export default StoryCoverImage;
