export const COLOR_THEMES = [
	{
		value: "frame-blue",
		text: "Dark Blue",
	},
	{
		value: "frame-orange",
		text: "Orange",
	},
	{
		value: "frame-gray",
		text: "Gray",
	},
	{
		value: "frame-midBlue",
		text: "Mid Blue",
	},
	{
		value: "frame-yellow",
		text: "Yellow",
	},
];
