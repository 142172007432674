import { LayerGroup, Polygon, Tooltip } from "react-leaflet";
import { useRecoilValue } from "recoil";
import { MapLayerZipCodeHeatMapData } from "./types/MapLayerData";
import { mapStateAtom } from "./map.recoil";
import HighlightLayer from "./HighlightLayer";

interface Props {
	data: MapLayerZipCodeHeatMapData;
}

const HeatMapLayer: React.FC<Props> = ({ data }) => {
	const mapState = useRecoilValue(mapStateAtom);

	const shouldShowLabels = mapState.fitLatLngs && mapState.fitLatLngs.length < 1000;

	return (
		<LayerGroup>
			{data.zipCodeCounts.map((zipCodeCount, i) => {
				return zipCodeCount.polygons.map((polygon, j) => {
					return (
						<Polygon
							key={i + "-" + j}
							pathOptions={{
								color: "#552500",
								fillOpacity: zipCodeCount.count / data.maxCount,
								fillColor: "#ff5700",
								weight: 1,
							}}
							positions={polygon}
						>
							{shouldShowLabels && j === zipCodeCount.polygons.length - 1 && (
								<Tooltip
									permanent={true}
									direction="center"
									className="!bg-transparent !border-0 text-center !text-white font-bold !shadow-none"
								>
									<div className="text-sm" style={{ textShadow: "0 0 0.5rem black" }}>
										{zipCodeCount.zipCode}
									</div>
									<div className="text-2xl" style={{ textShadow: "0 0 0.5rem black" }}>
										{zipCodeCount.count.toLocaleString("en-US")}
									</div>
								</Tooltip>
							)}
						</Polygon>
					);
				});
			})}
			<div className="leaflet-top leaflet-right">
				<div className="leaflet-control ">
					<div className="bg-white opacity-80 w-40 rounded-lg p-4">
						<div className="text-lg text-center font-bold border-b border-gray-400">Calls</div>
						<div className="flex mt-4">
							<div className="bg-gradient-to-t from-transparent to-uwgc-orange w-4 h-20 border border-uwgc-charcoal rounded"></div>
							<div className="flex flex-col justify-between pl-4 font-bold">
								<div className="text-base">{data.maxCount}</div>
								<div className="text-base">0</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<HighlightLayer />
		</LayerGroup>
	);
};

export default HeatMapLayer;
