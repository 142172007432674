import { PathName } from "../constants/PathName";

const STORY_AND_NAME_DELIMETER = "-";

export const serializeStoryNameAndId = (name: string, id: number) => {
	return name + STORY_AND_NAME_DELIMETER + id;
};

export const createShareableStoryLink = (name: string, id: number) => {
	return (
		window.location.origin +
		PathName.SHARE_STORY(encodeURIComponent(serializeStoryNameAndId(name, id)))
	);
};

export const parseStoryNameAndId = (storyNameAndId: string) => {
	const lastDelimeterIndex = storyNameAndId.lastIndexOf(STORY_AND_NAME_DELIMETER);
	if (lastDelimeterIndex < 1 || storyNameAndId.length < 3) {
		console.error("Failed to parse storyNameAndId");
	}

	const name = storyNameAndId.substring(0, lastDelimeterIndex);
	const id = parseInt(storyNameAndId.substring(lastDelimeterIndex + 1, storyNameAndId.length));

	return {
		name,
		id,
	};
};
