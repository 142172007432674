import { Polygon } from "react-leaflet";
import { useRecoilValue } from "recoil";
import { mapStateAtom } from "./map.recoil";

const HighlightLayer: React.FC = () => {
	const mapState = useRecoilValue(mapStateAtom);

	return (
		<>
			{mapState.fitLatLngs && mapState.fitLatLngs.length > 0 && mapState.fitLatLngs.length < 1000 && (
				<Polygon
					pathOptions={{
						color: "#252a69",
						fill: false,
						weight: 5,
					}}
					positions={mapState.fitLatLngs}
				/>
			)}
		</>
	);
};

export default HighlightLayer;
