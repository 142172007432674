import React from "react";

interface Props {
	message: string | JSX.Element;
	position: "top" | "bottom";
	showPointer?: boolean;
	type: "hover" | "click";
}

const TooltipAlt: React.FC<Props> = ({ message, position, showPointer, type, children }) => {
	return (
		<div tabIndex={-5} className="tooltip-alt relative flex flex-col items-center group">
			{children}
			<div
				className={
					"absolute flex-col items-center hidden " +
					(type === "click" ? " group-focus:flex " : " group-hover:flex ") +
					(position === "bottom" ? " top-full" : " bottom-full")
				}
			>
				<span className="relative z-10 p-2 text-base leading-none text-[#4B4F58] whitespace-no-wrap bg-[#B0C5E4] rounded-md">
					{message}
				</span>
				{showPointer === true && <div className="w-3 h-3 -mt-2 rotate-45 bg-[#B0C5E4]"></div>}
			</div>
		</div>
	);
};

export default TooltipAlt;
