import { getComponentContentType, useStoryForm } from "../../../story.recoil";
import EditComponentButton from "../componentForm/EditComponentButton";
import RemoveComponentButton from "../componentForm/RemoveComponentButton";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
	componentIndex: number;
}

const Component: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
	componentIndex,
}) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const component =
		story.sections?.[sectionIndex]?.frames?.[frameIndex]?.layers?.[layerIndex].containers?.[
			containerIndex
		].components?.[componentIndex];

	return (
		<div className="flex items-center justify-between p-0 blue-dashed-small font-bold text-2xl ">
			{component && getComponentContentType(component)}
			<div className="flex gap-2">
				<EditComponentButton
					sectionIndex={sectionIndex}
					frameIndex={frameIndex}
					layerIndex={layerIndex}
					containerIndex={containerIndex}
					componentIndex={componentIndex}
				/>
				<RemoveComponentButton
					sectionIndex={sectionIndex}
					frameIndex={frameIndex}
					layerIndex={layerIndex}
					containerIndex={containerIndex}
					componentIndex={componentIndex}
				/>
			</div>
		</div>
	);
};

export default Component;
