import ContainersList from "./containersList/ContainersList";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
}

const Containers: React.FC<Props> = ({ sectionIndex, frameIndex, layerIndex }) => {
	return (
		<div className=" border-t-2 border-t-uwgc-slate border border-uwgc-midGrey drop-shadow-md rounded-b-md shadow-lg">
			<ContainersList sectionIndex={sectionIndex} frameIndex={frameIndex} layerIndex={layerIndex} />
		</div>
	);
};

export default Containers;
