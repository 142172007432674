import { PhotographIcon } from "@heroicons/react/outline";
import { useCallback } from "react";
import {
	AssetFileFragment,
	AssetFileTypeIds,
} from "../../../../../../../../../../shared/generated/graphql";
import { useComponentTempForm, useUpdateComponentTemp } from "../../../story.recoil";
import OpenAssetPickerButton from "../../assetPicker/OpenAssetPickerButton";

const ImageComponentForm: React.FC = () => {
	const {
		componentTempForm: { componentTemp },
	} = useComponentTempForm();
	const { updateImageComponentTemp } = useUpdateComponentTemp();

	const _onAssetPick = useCallback(
		(assetFile: AssetFileFragment) => {
			updateImageComponentTemp({
				fields: {
					assetFile: {
						...assetFile,
					},
				},
			});
		},
		[updateImageComponentTemp],
	);

	const imageComponent = componentTemp.imageComponents![0];

	return (
		<>
			IMAGE COMPONENT FORM
			<div className="my-2">{/* @TODO download/upload image. image preview */}</div>
			<div className="flex flex-col items-center gap-2">
				<div>{imageComponent.assetFile?.file?.name}</div>
				<OpenAssetPickerButton typeId={AssetFileTypeIds.Image} onAssetPick={_onAssetPick}>
					SELECT IMAGE <PhotographIcon className="h-5 w-5" />
				</OpenAssetPickerButton>
			</div>
		</>
	);
};

export default ImageComponentForm;
