import { useCallback, useEffect, useRef } from "react";
import { BiPaintRoll } from "react-icons/bi";
import { MdOutlineFileUpload } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Button from "../../../../../../../../shared/components/button/Button";
import Input from "../../../../../../../../shared/components/input/Input";
import Loading from "../../../../../../../../shared/components/Loading/Loading";
import Textarea from "../../../../../../../../shared/components/textarea/Textarea";
import Toggle from "../../../../../../../../shared/components/toggle/Toggle";
import {
	AssetFileFragment,
	AssetFileTypeIds,
} from "../../../../../../../../shared/generated/graphql";
import { strToNumber } from "gate3-utils";
import { PathName } from "../../../../../../../constants/PathName";
import {
	TITLE_SECTION_VIDEO_LAYER_INDEX,
	useSaveStory,
	useStoryForm,
	useUpdateStory,
	useUpdateTitleSection,
} from "../story.recoil";
import OpenAssetPickerButton from "./assetPicker/OpenAssetPickerButton";
import SaveStoryButton from "./SaveStoryButton";
import DeleteStoryButton from "./DeleteStoryButton";

interface Props {
	form?: HTMLFormElement;
}

const StoryForm: React.FC<Props> = ({ form }) => {
	const history = useHistory();
	const formRef = useRef<HTMLFormElement>(null);

	const {
		storyForm: { story },
	} = useStoryForm();

	const { updateStory } = useUpdateStory();
	const { saveStory, saveStoryRes } = useSaveStory();
	// const { isReqError, isRequiredError } = useSaveStory();
	const { insertOrUpdateTitleSectionTitleComponent, insertOrUpdateTitleSectionVideoComponent } =
		useUpdateTitleSection();

	const _onVideo1AssetPick = useCallback(
		(assetFile: AssetFileFragment) => {
			insertOrUpdateTitleSectionVideoComponent(0, {
				...assetFile,
			});
		},
		[insertOrUpdateTitleSectionVideoComponent],
	);

	const _onVideo2AssetPick = useCallback(
		(assetFile: AssetFileFragment) => {
			insertOrUpdateTitleSectionVideoComponent(1, {
				...assetFile,
			});
		},
		[insertOrUpdateTitleSectionVideoComponent],
	);

	const _onVideo3AssetPick = useCallback(
		(assetFile: AssetFileFragment) => {
			insertOrUpdateTitleSectionVideoComponent(2, {
				...assetFile,
			});
		},
		[insertOrUpdateTitleSectionVideoComponent],
	);

	const _onCoverImageAssetPick = useCallback(
		(assetFile: AssetFileFragment) => {
			updateStory({
				fields: {
					coverImageAssetFile: {
						...assetFile,
					},
				},
			});
		},
		[updateStory],
	);

	useEffect(() => {
		if (saveStoryRes.isCalled && !saveStoryRes.isSaving && saveStoryRes.addStoryData) {
			if (saveStoryRes.shouldRedirectToSections) {
				history.push(PathName.BUILDER_STORIES_SECTIONS(saveStoryRes.addStoryData.storyId + ""));
			} else {
				history.push(PathName.BUILDER_STORIES(saveStoryRes.addStoryData.storyId + ""));
			}
		}
	}, [
		saveStoryRes.isCalled,
		saveStoryRes.isSaving,
		saveStoryRes.addStoryData,
		saveStoryRes.shouldRedirectToSections,
		history,
	]);

	// @TODO why are video components in different containers?
	const titleSectionVideoLayerContainers =
		story.sections![0].frames![0].layers![TITLE_SECTION_VIDEO_LAYER_INDEX].containers!;
	const titleSectionVideoLayerContainer0 = titleSectionVideoLayerContainers[0];
	const titleSectionVideoLayerContainer1 = titleSectionVideoLayerContainers[1];
	const titleSectionVideoLayerContainer2 = titleSectionVideoLayerContainers[2];
	const titleSectionVideoComponent0 =
		titleSectionVideoLayerContainer0.components!.length !== 0
			? titleSectionVideoLayerContainer0.components![0].videoComponents![0]
			: undefined;
	const titleSectionVideoComponent1 =
		titleSectionVideoLayerContainer1.components!.length !== 0
			? titleSectionVideoLayerContainer1.components![0].videoComponents![0]
			: undefined;
	const titleSectionVideoComponent2 =
		titleSectionVideoLayerContainer2.components!.length !== 0
			? titleSectionVideoLayerContainer2.components![0].videoComponents![0]
			: undefined;

	return (
		<div className="story-detail">
			{/* @TODO loading, absolute loader, block user input */}
			{saveStoryRes.isSaving && <Loading loadingType="lds-ring" />}
			<form ref={formRef} className="mb-12 flex max-w-2xl flex-col gap-4">
				{/* @TODO create input component with the label*/}
				{story.id !== undefined && (
					<div>
						<label>Story ID</label>
						<div>{story.id}</div>
					</div>
				)}

				<div>
					<label>Story Name (Internal name)*</label>
					<Input
						required
						value={story.name ?? ""}
						onChange={(e) => {
							updateStory({
								fields: {
									name: e.target.value,
								},
							});
						}}
					/>
					{/* {saveStoryRes.isRequiredError && <div className="text-uwgc-red">Please add name</div>} */}
				</div>

				<div>
					<label>Description*</label>
					<Textarea
						required
						value={story.description ?? ""}
						onChange={(e) => {
							updateStory({
								fields: {
									description: e.target.value,
								},
							});
						}}
						rows={4}
					/>
				</div>

				<div className="flex gap-8">
					<div className="flex flex-col">
						<label>Featured</label>
						<Toggle
							checked={story.isFeatured}
							onColor="#22C55E"
							onChange={(e) => {
								updateStory({
									fields: {
										isFeatured: e.target.checked,
									},
								});
							}}
						/>
					</div>
					<div className="flex flex-col">
						<label>Order</label>
						<Input
							className="max-w-[86px]"
							value={story.orderNum ?? ""}
							onChange={(e) => {
								updateStory({
									fields: {
										orderNum: strToNumber(e.target.value),
									},
								});
							}}
						/>
					</div>
				</div>

				<div>
					<label>Title (this appears on the title slide)*</label>
					<Input
						required
						value={story.topic ?? ""}
						onChange={(e) => {
							updateStory({
								fields: {
									topic: e.target.value,
								},
							});
							insertOrUpdateTitleSectionTitleComponent(e.target.value);
						}}
					/>
					{/* {saveStoryRes.isRequiredError && <div className="text-uwgc-red">Please add title</div>} */}
				</div>

				<div className="flex flex-col">
					<label>Slide 1 Video Background</label>
					<div className="flex gap-2">
						<div className="input">{titleSectionVideoComponent0?.assetFile?.file?.name}</div>
						<OpenAssetPickerButton typeId={AssetFileTypeIds.Video} onAssetPick={_onVideo1AssetPick}>
							Select
							<MdOutlineFileUpload className="h-6 w-6" />
						</OpenAssetPickerButton>
					</div>
				</div>

				<div className="flex flex-col">
					<label>Slide 2 Video Background</label>
					<div className="flex gap-2">
						<div className="input">{titleSectionVideoComponent1?.assetFile?.file?.name}</div>
						<OpenAssetPickerButton typeId={AssetFileTypeIds.Video} onAssetPick={_onVideo2AssetPick}>
							Select
							<MdOutlineFileUpload className="h-6 w-6" />
						</OpenAssetPickerButton>
					</div>
				</div>

				<div className="flex flex-col">
					<label>Slide 3 Video Background</label>
					<div className="flex gap-2">
						<div className="input">{titleSectionVideoComponent2?.assetFile?.file?.name}</div>
						<OpenAssetPickerButton typeId={AssetFileTypeIds.Video} onAssetPick={_onVideo3AssetPick}>
							Select
							<MdOutlineFileUpload className="h-6 w-6" />
						</OpenAssetPickerButton>
					</div>
				</div>

				<div className="flex flex-col">
					<label>Controller Cover Image</label>
					<div className="flex gap-2">
						<div className="input">{story.coverImageAssetFile?.file?.name}</div>
						<OpenAssetPickerButton
							typeId={AssetFileTypeIds.Image}
							onAssetPick={_onCoverImageAssetPick}
						>
							Select
							<MdOutlineFileUpload className="h-6 w-6" />
						</OpenAssetPickerButton>
					</div>
				</div>
			</form>
			{saveStoryRes.isRequiredError && (
				<div className="text-uwgc-red">Please add required Story Name, Description and Title</div>
			)}
			<div className="my-4 flex justify-center gap-8">
				{story.id !== undefined && <DeleteStoryButton />}

				{/* <Button className="charcoal">
					Load Template
					<DocumentDuplicateIcon className="h-5 w-5" />
				</Button> */}
				<Button
					className="green"
					onClick={() => {
						if (story.id !== undefined) {
							history.push(PathName.BUILDER_STORIES_SECTIONS(story.id + ""));
						} else {
							void saveStory(formRef.current ?? undefined, true);
						}
					}}
				>
					Build Story
					<BiPaintRoll className="h-5 w-5" />
				</Button>
				<SaveStoryButton form={formRef.current ?? undefined} />
			</div>
		</div>
	);
};

export default StoryForm;
