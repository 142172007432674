import WsError from "../../../../shared/types/WsError";
import DisplayModes from "../../../../shared/types/DisplayModes";
import MapCommandData from "./mapCommandData/MapCommandData";
import StoryCommandData from "./StoryCommandData";

export enum InDisplayWsDataTypes {
	ERROR, // WsError
	IS_CONTROL_CONNECTED, // boolean
	SET_DISPLAY_MODE, // DisplayModes
	STORY_COMMAND, // StoryCommandData
	MAP_COMMAND, // MapCommandData
	RELOAD, // undefined
}

interface InDisplayWsData {
	type: InDisplayWsDataTypes;
	data: WsError | boolean | DisplayModes | StoryCommandData | MapCommandData | undefined;
}

export default InDisplayWsData;
