import { useCallback } from "react";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import { AssetFileFragment } from "../../../../../../../../../../shared/generated/graphql";
import { cloneArrPush, cloneArrSplice } from "gate3-utils";

const assetFilesGridReloaderAtom = atom<boolean>({
	key: "assetFilesGridReloader",
	default: false,
});

export const useAssetFilesGridReloader = () => {
	const [assetFilesGridReloader, setAssetFilesGridReloader] = useRecoilState(
		assetFilesGridReloaderAtom,
	);

	const reloadAssetFilesGrid = useCallback(() => {
		setAssetFilesGridReloader((v) => !v);
	}, [setAssetFilesGridReloader]);

	return { assetFilesGridReloader, reloadAssetFilesGrid };
};

const selectedAssetFilesAtom = atom<AssetFileFragment[]>({
	key: "selectedAssetFiles",
	default: [],
});

export const useSelectedAssetFiles = () => {
	const [selectedAssetFiles, setSelectedAssetFiles] = useRecoilState(selectedAssetFilesAtom);
	const resetSelectedAssetFiles = useResetRecoilState(selectedAssetFilesAtom);

	const addSelectedAssetFile = useCallback(
		(assetFile: AssetFileFragment) => {
			setSelectedAssetFiles((state) => {
				return cloneArrPush(state, assetFile);
			});
		},
		[setSelectedAssetFiles],
	);

	const removeSelectedAssetFile = useCallback(
		(assetFile: AssetFileFragment) => {
			setSelectedAssetFiles((state) => {
				const index = state.findIndex((assetFileIt) => assetFileIt.id === assetFile.id);
				if (index !== -1) {
					return cloneArrSplice(state, index, 1);
				} else {
					return state;
				}
			});
		},
		[setSelectedAssetFiles],
	);

	const replaceSelectedAssetFile = useCallback(
		(assetFile: AssetFileFragment) => {
			setSelectedAssetFiles([assetFile]);
		},
		[setSelectedAssetFiles],
	);

	return {
		selectedAssetFiles,
		addSelectedAssetFile,
		removeSelectedAssetFile,
		replaceSelectedAssetFile,
		resetSelectedAssetFiles,
	};
};
