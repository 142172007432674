import classNames from "classnames";
import React from "react";
import { ContainerFragment } from "../../../../../../shared/generated/graphql";
import ContainerComponent from "./component/ContainerComponent";

interface Props {
	isBuilder?: boolean;
	container: ContainerFragment;
	frameDuration: number;
	isPreview?: boolean;
}

const Container: React.FC<Props> = ({ isBuilder, container, frameDuration, isPreview }) => {
	const { components, screenIndices, background, inAnimation, outAnimation, delay } = container;
	const classes = () => {
		let className = "container ";
		if (screenIndices.length > 0) {
			className += " screen";
			screenIndices.forEach((i) => {
				className += "-" + i;
			});
		}
		//for animations
		className += " animate__animated ";
		return className;
	};

	const calculateOutAnimationDelay = () => {
		const outAnimationDuration = 0.5;
		return frameDuration - delay - outAnimationDuration;
	};

	const animation = [];
	const animationDuration = [];
	const animationDelay = [];
	const animationFillMode = [];

	if (!isPreview && inAnimation != null) {
		animation.push(inAnimation);
		animationDuration.push("0.5s");
		animationDelay.push("0s");
		animationFillMode.push("forwards");
	}

	if (!isPreview && outAnimation != null) {
		animation.push(outAnimation);
		animationDuration.push("0.5s");
		animationDelay.push(calculateOutAnimationDelay() + "s");
		animationFillMode.push("forwards");
	}

	return (
		<div
			className={classNames(classes(), background)}
			style={{
				animation: animation.join(", "),
				animationDuration: animationDuration.join(", "),
				animationDelay: animationDelay.join(", "),
				animationFillMode: animationFillMode.join(", "),
			}}
		>
			{components.map((component, i) => {
				return <ContainerComponent key={i} isBuilder={isBuilder} component={component} />;
			})}
		</div>
	);
};

export default Container;
