import Container from "../../../../../../../../../../../../display/views/story/frame/layer/container/Container";
import { ContainerFragment } from "../../../../../../../../../../../../shared/generated/graphql";
import { useStoryForm } from "../../../../../story.recoil";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
}

const ContainerPreview: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
}) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const container =
		story.sections?.[sectionIndex]?.frames?.[frameIndex]?.layers?.[layerIndex].containers?.[
			containerIndex
		];

	return (
		<div className="container-preview">
			<Container
				isBuilder
				container={container as ContainerFragment}
				frameDuration={0}
				isPreview={true}
			/>
		</div>
	);
};

export default ContainerPreview;
