import { CogIcon } from "@heroicons/react/outline";
import { useBuilderModals } from "../../../../../builder.recoil";
import { useSectionTempForm, useStoryForm } from "../../../story.recoil";
import SectionForm from "./SectionForm";

export interface Props {
	sectionIndex: number;
}

const EditSectionButton: React.FC<Props> = ({ sectionIndex }) => {
	const { pushBuilderModal } = useBuilderModals();
	const {
		storyForm: { story },
	} = useStoryForm();
	const { updateSectionTempForm } = useSectionTempForm();

	const section = story.sections?.[sectionIndex];

	return (
		<button
			onClick={() => {
				updateSectionTempForm({
					fields: {
						sectionIndex,
						sectionTemp: {
							...section,
						},
					},
				});

				pushBuilderModal({
					render: <SectionForm />,
					title: "Edit Section",
				});
			}}
			className="pl-3"
		>
			<CogIcon className="h-8 w-8 text-uwgc-yellow" />
		</button>
	);
};

export default EditSectionButton;
