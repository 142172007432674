import { BehaviorSubject, Subject } from "rxjs";
import WsConnectionStatus from "../types/WsConnectionStatus";

/**
 * handles real time connection between this app and server
 * keep this generic as much as possible. being able to use this class on different projects
 * */
class WebSocketManager<InDataType, OutDataType> {
	private _url: string;
	private _protocol: string;
	private _reconnect = false;
	private _ws: WebSocket | undefined;
	private _connectionStatus = new BehaviorSubject<WsConnectionStatus>(
		WsConnectionStatus.NOT_CONNECTED,
	);
	private _onDataReceived = new Subject<InDataType>();
	private _reconnectTimeout: NodeJS.Timeout | undefined;

	constructor(url: string, protocol: string) {
		this._url = url;
		this._protocol = protocol;
	}

	get connectionStatus() {
		return this._connectionStatus.getValue();
	}

	get connectionStatusObservable() {
		return this._connectionStatus.asObservable();
	}

	get onDataReceivedObservable() {
		return this._onDataReceived.asObservable();
	}

	open() {
		if (this._reconnectTimeout) {
			return;
		}

		console.info("ws connecting");
		this._reconnect = true;
		this._connectionStatus.next(WsConnectionStatus.CONNECTING);

		this._ws = new WebSocket(this._url, this._protocol);

		this._ws.addEventListener("open", () => this._onOpen());
		this._ws.addEventListener("close", () => this._onClose());
		this._ws.addEventListener("message", (event) => this._onMessage(event));
		this._ws.addEventListener("error", (event) => this._onError(event));
	}

	close() {
		if (!this._ws || this.connectionStatus !== WsConnectionStatus.CONNECTED) {
			console.error("cannot close ws connection, already closed");
			return;
		}

		this._reconnect = false;
		if (this._reconnectTimeout) {
			clearTimeout(this._reconnectTimeout);
			this._reconnectTimeout = undefined;
		}
		this._ws.close();
		this._ws = undefined;
	}

	send(data: OutDataType) {
		if (!this._ws || this.connectionStatus !== WsConnectionStatus.CONNECTED) {
			console.error("cannot send data, no connection");
			return;
		}

		this._ws.send(JSON.stringify(data));
	}

	private _onOpen() {
		console.info("ws connection opened");
		this._connectionStatus.next(WsConnectionStatus.CONNECTED);
	}

	private _onClose() {
		console.info("ws connection closed");
		this._connectionStatus.next(WsConnectionStatus.NOT_CONNECTED);
		this._ws = undefined;
		if (this._reconnect) {
			this._reconnectTimeout = setTimeout(() => {
				this._reconnectTimeout = undefined;
				this.open();
			}, 500);
		}
	}

	private _onMessage(event: MessageEvent<string>) {
		this._onDataReceived.next(JSON.parse(event.data));
	}

	private _onError(event: Event) {
		console.error(event);
		this._ws!.close();
	}
}

export default WebSocketManager;
