import React from "react";
import TextEditor from "../../../../../../../control/views/private/privateContent/builder/stories/story/storyContent/components/componentForm/textEditor/TextEditor";
import {
	HtmlComponentFragment,
	HtmlComponentTypeIds,
} from "../../../../../../../shared/generated/graphql";

interface Props {
	htmlComponent: HtmlComponentFragment;
}

const HtmlComponent: React.FC<Props> = ({ htmlComponent: { typeId, html } }) => {
	return (
		<>
			{typeId === HtmlComponentTypeIds.Html ? (
				<div dangerouslySetInnerHTML={{ __html: html }} />
			) : (
				<TextEditor isReadOnly initlialValue={JSON.parse(html)} />
			)}
		</>
	);
};

export default HtmlComponent;
