import { ALL_LAYERS_INDEX } from "../../../../../../../story.recoil";
import EditLayerButton from "../layerForm/EditLayerButton";

export interface Props {
	isSelected: boolean;
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	onClick: () => void;
}

const LayerTab: React.FC<Props> = ({
	isSelected,
	sectionIndex,
	frameIndex,
	layerIndex,
	onClick,
}) => {
	return (
		<div className={"layer-tab " + (isSelected ? "selected" : "")} onClick={() => onClick()}>
			{layerIndex === ALL_LAYERS_INDEX ? "All Layers" : `Layer ${layerIndex + 1}`}
			{layerIndex !== ALL_LAYERS_INDEX && (
				<EditLayerButton
					sectionIndex={sectionIndex}
					frameIndex={frameIndex}
					layerIndex={layerIndex}
				/>
			)}
		</div>
	);
};

export default LayerTab;
