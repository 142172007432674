import StoriesHeader from "./storiesHeader/StoriesHeader";
import StoriesTable from "./StoriesTable";

// @TODO this component naming sucks, it should be Stories, and Stories should be something else
const StoriesPage: React.FC = () => {
	return (
		<div>
			<StoriesHeader />
			<StoriesTable />
		</div>
	);
};

export default StoriesPage;
