import { useEffect } from "react";
import { LocalStorageKeys } from "../../../constants/LocalStorageKeys";
import { LoginInput, useLoginMutation } from "../../../shared/generated/graphql";
import { useStates } from "../../../shared/hooks/reusable/useStates";
import QueryState from "../../../shared/types/QueryState";
import { handleApolloError } from "../../../shared/utils/ApolloUtils";
import useGetCurrentUser from "./useGetCurrentUser";

const useLogin = () => {
	const [loginQueryState, setLoginQueryState] = useStates<QueryState<undefined>>({
		wasCalled: false,
		isLoading: false,
		error: undefined,
		data: undefined,
	});

	const [_login] = useLoginMutation();
	const { getCurrentUser, getCurrentUserQueryState } = useGetCurrentUser();

	const login = (input: LoginInput) => {
		setLoginQueryState({
			wasCalled: true,
			isLoading: true,
			error: undefined,
			data: undefined,
		});

		localStorage.removeItem(LocalStorageKeys.TOKEN);
		_login({
			variables: {
				input,
			},
		})
			.then((res) => {
				localStorage.setItem(LocalStorageKeys.TOKEN, res.data!.login.token);

				getCurrentUser();
			})
			.catch((err) => {
				setLoginQueryState({
					isLoading: false,
					error: err ? handleApolloError(err) : "Unknown error",
				});
			});
	};

	useEffect(() => {
		if (getCurrentUserQueryState.wasCalled && !getCurrentUserQueryState.isLoading) {
			if (getCurrentUserQueryState.error !== undefined) {
				setLoginQueryState({
					isLoading: false,
					error: getCurrentUserQueryState.error,
				});
			} else {
				setLoginQueryState({
					isLoading: false,
				});
			}
		}
	}, [getCurrentUserQueryState.isLoading]);

	return {
		login,
		loginQueryState,
	};
};

export default useLogin;
