import { Link } from "react-router-dom";
import { PathName } from "../../../../../../../constants/PathName";
import StoriesFilters from "./StoriesFilters";
import { AiOutlinePlus } from "react-icons/ai";

const StoriesHeader: React.FC = () => {
	return (
		<div className="flex justify-between px-4 py-8 items-center">
			<StoriesFilters />
			<Link className="button orange" to={PathName.BUILDER_STORIES("add")}>
				Create Story
				<AiOutlinePlus className="w-4 h-4" />
			</Link>
		</div>
	);
};

export default StoriesHeader;
