export enum PublicControlViewerCommandTypes {
	SET_ZIP_CODE,
	VIEW_ALL_CLEVELAND,
	VIEW_ALL_CALLS,
	VIEW_GENDER_OF_CALLERS,
	VIEW_AGE_OF_CALLERS,
	VIEW_SERVICES_REQUESTED,
}

type PublicControlViewerCommandTypeMap = {
	[PublicControlViewerCommandTypes.SET_ZIP_CODE]: string;
	[PublicControlViewerCommandTypes.VIEW_ALL_CLEVELAND]: undefined;
	[PublicControlViewerCommandTypes.VIEW_ALL_CALLS]: undefined;
	[PublicControlViewerCommandTypes.VIEW_GENDER_OF_CALLERS]: undefined;
	[PublicControlViewerCommandTypes.VIEW_AGE_OF_CALLERS]: undefined;
	[PublicControlViewerCommandTypes.VIEW_SERVICES_REQUESTED]: undefined;
};

interface PublicControlViewerCommand<
	T extends PublicControlViewerCommandTypes = PublicControlViewerCommandTypes,
> {
	type: T;
	data: PublicControlViewerCommandTypeMap[T];
}

export const createPublicControlViewerCommand = <T extends PublicControlViewerCommandTypes>(
	type: T,
	data: PublicControlViewerCommandTypeMap[T],
): PublicControlViewerCommand<T> => {
	return {
		type,
		data,
	};
};

export default PublicControlViewerCommand;
