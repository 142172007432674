import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { storyEngineStateAtom } from "../../../views/private/privateContent/control/dashboard/stories/story/storyEngine.recoil";
import StoryEngineState from "../../../types/controlWs/inControlWsData/StoryEngineState";
import { storiesAtom } from "../../stories.recoil";

const useHandleStoryEngineState = () => {
	const stories = useRecoilValue(storiesAtom);
	const setStoryEngineStateAtom = useSetRecoilState(storyEngineStateAtom);

	return useCallback(
		(data: StoryEngineState) => {
			const story = stories.find((story) => story.id === data.storyId);
			const section = story?.sections.find((section) => section.id === data.sectionId);

			setStoryEngineStateAtom({
				story,
				section,
				time: data.time,
				isPaused: data.isPaused,
			});
		},
		[stories],
	);
};

export default useHandleStoryEngineState;
