import { EyeIcon } from "@heroicons/react/outline";
import Breadcrumb from "../../../../../../../../shared/components/breadcrumb/Breadcrumb";
import { PathName } from "../../../../../../../constants/PathName";
import OpenStoryPreviewButton from "../../storyPreview/OpenStoryPreviewButton";
import { useStoryForm } from "../story.recoil";

const StoryHeader: React.FC = () => {
	const {
		storyForm: { story },
	} = useStoryForm();

	return (
		<div className="story-builder-header">
			<Breadcrumb
				crumbs={[
					{ url: PathName.BUILDER_STORIES(), name: "Stories" },
					{ url: "", name: story.id !== undefined ? story.name! : "Add Story" },
				]}
			/>
			<OpenStoryPreviewButton className="blue" story={story}>
				PREVIEW <EyeIcon className="w-5 h-5" />
			</OpenStoryPreviewButton>
		</div>
	);
};

export default StoryHeader;
