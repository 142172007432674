import { atom } from "recoil";
import DisplayWebSocketManager from "../../classes/DisplayWs";
import WsConnectionStatus from "../../../shared/types/WsConnectionStatus";

export const displayWsAtom = atom<DisplayWebSocketManager | undefined>({
	key: "displayWs",
	default: undefined,
	dangerouslyAllowMutability: true,
});

export const displayWsConnectionStatusAtom = atom<WsConnectionStatus>({
	key: "displayWsConnectionStatus",
	default: WsConnectionStatus.NOT_CONNECTED,
});

export const isControlConnectedAtom = atom<boolean>({
	key: "isControlConnected",
	default: false,
});
