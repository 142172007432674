import { ApolloError } from "@apollo/client";

export const extractApolloServerErrors = (err: ApolloError): string[] => {
	const errors = (err.networkError as any)?.result?.errors;
	if (errors) {
		const errorMessages: string[] = [];
		for (let i = 0; i < errors.length; ++i) {
			errorMessages.push(errors[i].message);
		}

		return errorMessages;
	}

	return [];
};

export const handleApolloError = (err: ApolloError) => {
	// @TODO create a KnownError object instead
	const serverErrorMessages = extractApolloServerErrors(err);
	if (serverErrorMessages.length !== 0) {
		return serverErrorMessages[0];
	}

	return "An error has occurred";
};
