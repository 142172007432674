import React from "react";
import { useRecoilValue } from "recoil";
import { mapStateAtom } from "./map.recoil";

const MapDetailsLeft: React.FC = () => {
	const mapState = useRecoilValue(mapStateAtom);

	return (
		<div className="bg-uwgc-blue w-full h-full flex flex-col justify-center p-7">
			<div className="text-5xl font-semibold mt-1 text-uwgc-yellow">{mapState.detail?.title}</div>
			{<div className="text-3xl mb-3 text-white">{mapState.detail?.subtitle}</div>}
		</div>
	);
};

export default MapDetailsLeft;
