import { atom } from "recoil";
import DisplayModes from "../../../shared/types/DisplayModes";
import { StoryFragment } from "../../../shared/generated/graphql";

export const storiesAtom = atom<StoryFragment[]>({
	key: "stories_display",
	default: [],
});

export const displayModeAtom = atom<DisplayModes>({
	key: "displayMode",
	default: DisplayModes.STORY,
});

export const reloaderAtom = atom<boolean>({
	key: "reloader",
	default: false,
});
