import useDisplayWs from "../state/displayWs/useDisplayWs";
import useStoryEngineStateSender from "../state/displayWs/useStoryEngineStateSender";

const SystemInit: React.FC = () => {
	useDisplayWs();
	useStoryEngineStateSender();

	return <></>;
};

export default SystemInit;
