import AssetFiles from "../../../../../assetLibrary/assetLibraryContent/assetFiles/AssetFiles";

const AssetPickerContent: React.FC = () => {
	return (
		<div>
			<AssetFiles />
		</div>
	);
};

export default AssetPickerContent;
