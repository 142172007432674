import ControlMapCommandData from "../../../types/controlWs/outControlWsData/ControlMapCommandData";
import {
	createOutControlWsData,
	OutControlWsDataTypes,
} from "../../../types/controlWs/outControlWsData/OutControlWsData";
import useSendOutControlWsData from "./useSendOutControlWsData";

const useSendControlMapCommandData = () => {
	const sendOutControlWsData = useSendOutControlWsData();
	return (command: ControlMapCommandData) => {
		sendOutControlWsData(createOutControlWsData(OutControlWsDataTypes.MAP_COMMAND, command));
	};
};

export default useSendControlMapCommandData;
