import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { displayModeAtom, reloaderAtom } from "../display.recoil";
import { isControlConnectedAtom } from "../displayWs.recoil";
import DisplayModes from "../../../../shared/types/DisplayModes";
import InDisplayWsData, {
	InDisplayWsDataTypes,
} from "../../../types/displayWs/inDisplayWsData/InDisplayWsData";
import MapCommandData from "../../../types/displayWs/inDisplayWsData/mapCommandData/MapCommandData";
import StoryCommandData from "../../../types/displayWs/inDisplayWsData/StoryCommandData";
import WsError from "../../../../shared/types/WsError";
import useHandleMapCommandData from "./useHandleMapCommandData";
import useHandleStoryCommandData from "./useHandleStoryCommandData";
import { useStoryEngine } from "../../../../shared/state/storyEngine/storyEngine.recoil";

const useHandleInDisplayWsData = () => {
	const setIsControlConnected = useSetRecoilState(isControlConnectedAtom);
	const setDisplayMode = useSetRecoilState(displayModeAtom);
	const setReloader = useSetRecoilState(reloaderAtom);
	const { storyEngine } = useStoryEngine();
	const handleStoryCommandData = useHandleStoryCommandData();
	const handleMapCommandData = useHandleMapCommandData();

	return useCallback(
		(data: InDisplayWsData) => {
			switch (data.type) {
				case InDisplayWsDataTypes.ERROR:
					{
						const err = data.data as WsError;
						// @TODO show error on screen
						console.error(err.message);
					}
					break;
				case InDisplayWsDataTypes.IS_CONTROL_CONNECTED:
					setIsControlConnected(data.data as boolean);
					break;
				case InDisplayWsDataTypes.SET_DISPLAY_MODE:
					{
						const displayMode = data.data as DisplayModes;
						setDisplayMode(displayMode);

						if (storyEngine) {
							if (displayMode === DisplayModes.STORY) {
								storyEngine.reset();
								storyEngine.isPaused = false;
							} else {
								storyEngine.isPaused = true;
							}
						}
					}
					break;
				case InDisplayWsDataTypes.STORY_COMMAND:
					handleStoryCommandData(data.data as StoryCommandData);
					break;
				case InDisplayWsDataTypes.MAP_COMMAND:
					handleMapCommandData(data.data as MapCommandData);
					break;
				case InDisplayWsDataTypes.RELOAD:
					setReloader((val) => !val);
					break;
				default: {
					console.error("Unknown InDisplayWsDataTypes");
				}
			}
		},
		[
			setIsControlConnected,
			handleStoryCommandData,
			handleMapCommandData,
			setReloader,
			setDisplayMode,
			storyEngine,
		],
	);
};

export default useHandleInDisplayWsData;
