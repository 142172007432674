import { CogIcon } from "@heroicons/react/outline";
import { useBuilderModals } from "../../../../../../../../../builder.recoil";
import { useLayerTempForm, useStoryForm } from "../../../../../../../story.recoil";
import LayerForm from "./LayerForm";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
}

const EditLayerButton: React.FC<Props> = ({ sectionIndex, frameIndex, layerIndex }) => {
	const { pushBuilderModal } = useBuilderModals();

	const {
		storyForm: { story },
	} = useStoryForm();
	const { updateLayerTempForm } = useLayerTempForm();

	const layer = story.sections?.[sectionIndex]?.frames?.[frameIndex]?.layers?.[layerIndex];

	return (
		<button
			className=" hover:text-white hover:backdrop-brightness-50 rounded-full"
			onClick={() => {
				updateLayerTempForm({
					fields: {
						layerIndex,
						layerTemp: {
							...layer,
						},
					},
				});

				pushBuilderModal({
					render: <LayerForm sectionIndex={sectionIndex} frameIndex={frameIndex} />,
					title: "Edit Layer",
				});
			}}
		>
			<CogIcon className="h-5 w-5" />
		</button>
	);
};

export default EditLayerButton;
