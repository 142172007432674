import AssetFilesGrid from "./assetFilesGrid/AssetFilesGrid";

const AssetFilesContent: React.FC = () => {
	return (
		<div className="af-content">
			<AssetFilesGrid />
		</div>
	);
};

export default AssetFilesContent;
