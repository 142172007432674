import { useBuilderModals } from "../../../../../builder.recoil";
import { MdOutlineAddBox } from "react-icons/md";
import Button from "../../../../../../../../../../shared/components/button/Button";
import ComponentForm from "./ComponentForm";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
}

const AddComponentButton: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
}) => {
	const { pushBuilderModal } = useBuilderModals();

	return (
		<Button
			className="green"
			onClick={() => {
				pushBuilderModal({
					render: (
						<ComponentForm
							sectionIndex={sectionIndex}
							frameIndex={frameIndex}
							layerIndex={layerIndex}
							containerIndex={containerIndex}
						/>
					),
					title: "Add Component",
				});
			}}
		>
			Add Component <MdOutlineAddBox className="h-5 w-5" />
		</Button>
	);
};

export default AddComponentButton;
