import { ViewGridIcon } from "@heroicons/react/outline";
import Input from "../../../../../../../../../../shared/components/input/Input";
import {
	AssetFileFragment,
	AssetFileTypeIds,
} from "../../../../../../../../../../shared/generated/graphql";
import { strToNumber } from "gate3-utils";
import { useComponentTempForm, useUpdateComponentTemp } from "../../../story.recoil";
import OpenAssetPickerButton from "../../assetPicker/OpenAssetPickerButton";
import { useCallback } from "react";

const VideoComponentForm: React.FC = () => {
	const {
		componentTempForm: { componentTemp },
	} = useComponentTempForm();
	const { updateVideoComponentTemp } = useUpdateComponentTemp();

	const _onAssetPick = useCallback(
		(assetFile: AssetFileFragment) => {
			updateVideoComponentTemp({
				fields: {
					assetFile: {
						...assetFile,
					},
				},
			});
		},
		[updateVideoComponentTemp],
	);

	const videoComponent = componentTemp.videoComponents![0];

	return (
		<>
			<div>{/* @TODO download/upload video. video preview? */}</div>
			<label className="flex h-9 cursor-pointer items-center gap-4">
				<input
					className="h-5 w-5"
					id="mutecheckbox"
					type={"checkbox"}
					checked={videoComponent.isMuted ?? false}
					onChange={(e) => {
						updateVideoComponentTemp({
							fields: {
								isMuted: e.target.checked,
							},
						});
					}}
				/>
				Mute Audio
			</label>
			<div>
				<label>Background Music Fade</label>
				<Input
					type={"range"}
					min={0}
					max={1}
					step={0.01}
					value={videoComponent.bgAudioFadeIn?.endVolume ?? 0}
					onChange={(e) => {
						updateVideoComponentTemp({
							fields: {
								bgAudioFadeIn: {
									endVolume: strToNumber(e.target.value),
								},
								bgAudioFadeOut: {
									startVolume: strToNumber(e.target.value),
								},
							},
						});
					}}
				/>
			</div>
			<div className="flex flex-col items-center gap-2">
				<div>{videoComponent.assetFile?.file?.name}</div>
				<OpenAssetPickerButton typeId={AssetFileTypeIds.Video} onAssetPick={_onAssetPick}>
					SELECT FROM LIBRARY <ViewGridIcon className="h-5 w-5" />
				</OpenAssetPickerButton>
			</div>
		</>
	);
};

export default VideoComponentForm;
