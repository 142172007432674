import Modal from "../../../../../shared/g3Components/modal/Modal";
import { useBuilderModals } from "./builder.recoil";

const BuilderModals: React.FC = () => {
	const { builderModals, popBuilderModal } = useBuilderModals();

	return (
		<div>
			{builderModals.map((builderModal, i) => {
				return (
					<Modal
						key={i}
						{...builderModal}
						onCloseClick={() => {
							builderModal.onCloseClick?.();
							popBuilderModal();
						}}
					/>
				);
			})}
		</div>
	);
};

export default BuilderModals;
