import { useEffect } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import env from "../../../env";
import { displayWsAtom, displayWsConnectionStatusAtom } from "./displayWs.recoil";
import WsConnectionStatus from "../../../shared/types/WsConnectionStatus";
import useHandleInDisplayWsData from "./handleInData/useHandleInDisplayWsData";
import useSubscriptionsHandler from "../../../shared/hooks/useSubscriptionsHandler";
import DisplayWs from "../../classes/DisplayWs";

const useDisplayWs = () => {
	const [displayWs, setDisplayWs] = useRecoilState(displayWsAtom);
	const resetDisplayWs = useResetRecoilState(displayWsAtom);
	const setDisplayWsConnectionStatus = useSetRecoilState(displayWsConnectionStatusAtom);
	const resetDisplayWsConnectionStatus = useResetRecoilState(displayWsConnectionStatusAtom);
	const handleInDisplayWsData = useHandleInDisplayWsData();

	const { addSubscription, unsubscribeAll } = useSubscriptionsHandler();

	const onConnectionChanged = (connection: WsConnectionStatus) => {
		setDisplayWsConnectionStatus(connection);
	};

	useEffect(() => {
		const newDisplayWs = new DisplayWs(env.REACT_APP_API_WS_BASE_URL + "/ws", "DISPLAY");
		addSubscription(newDisplayWs.connectionStatusObservable.subscribe(onConnectionChanged));
		addSubscription(newDisplayWs.onDataReceivedObservable.subscribe(handleInDisplayWsData));
		newDisplayWs.open();
		setDisplayWs(newDisplayWs);

		return () => {
			resetDisplayWsConnectionStatus();
			unsubscribeAll();
			newDisplayWs.close();

			resetDisplayWs();
		};
	}, []);

	useEffect(() => {
		if (displayWs) {
			unsubscribeAll();
			addSubscription(displayWs.connectionStatusObservable.subscribe(onConnectionChanged));
			addSubscription(displayWs.onDataReceivedObservable.subscribe(handleInDisplayWsData));
		}
	}, [displayWs, handleInDisplayWsData]);
};

export default useDisplayWs;
