import { useCallback, useEffect, useRef } from "react";
import QueryState from "../types/QueryState";
import { useStates } from "./reusable/useStates";
import axios, { AxiosInstance } from "axios";
import { LocalStorageKeys } from "../../constants/LocalStorageKeys";
import env from "../../env";
import { AssetFile, AssetFileTypeIds } from "../generated/graphql";

export const useFiles = () => {
	const authAxiosRef = useRef<AxiosInstance | null>(null);

	const [addAssetFileQueryState, setAddAssetFileQueryState] = useStates<
		QueryState<AssetFile | undefined>
	>({
		wasCalled: false,
		isLoading: false,
		error: undefined,
		data: undefined,
	});

	useEffect(() => {
		const token = localStorage.getItem(LocalStorageKeys.TOKEN);

		const authAxios = axios.create();
		const headers = authAxios.defaults.headers as any;
		headers["authorization"] = token !== null ? `Bearer ${token}` : "";

		authAxiosRef.current = authAxios;
	}, []);

	const addAssetFile = useCallback(
		async (blob: Blob, typeId: AssetFileTypeIds, tags: string[]) => {
			setAddAssetFileQueryState({
				wasCalled: true,
				isLoading: true,
				error: undefined,
				data: undefined,
			});

			const url = env.REACT_APP_API_BASE_URL + "/api/v1/asset-files";
			const form = new FormData();
			form.append("file", blob);
			form.append("typeId", typeId);
			for (const tag of tags) {
				form.append("tags[]", tag);
			}
			try {
				const res = await authAxiosRef.current!.post<AssetFile>(url, form);

				setAddAssetFileQueryState({
					isLoading: false,
					error: undefined,
					data: res.data,
				});
			} catch (err: any) {
				setAddAssetFileQueryState({
					isLoading: false,
					error:
						err !== undefined
							? err.message !== undefined
								? err.message
								: "Unknown Error"
							: "Unknown Error",
				});

				throw err;
			}
		},
		[setAddAssetFileQueryState],
	);

	return {
		addAssetFileQueryState,
		addAssetFile,
	};
};

export default useFiles;
