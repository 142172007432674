import { MinusIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import Button from "../../../../../../../../../../shared/components/button/Button";
import Input from "../../../../../../../../../../shared/components/input/Input";
import { useGetFileLazyQuery } from "../../../../../../../../../../shared/generated/graphql";
import { useFileForm, useUpdateFile, useUpdateFileTag } from "../../assetFiles.recoil";
import SaveFileButton from "./SaveFileFormButton";

interface Props {
	fileId: number;
	onSave?: () => void;
}

const FileForm: React.FC<Props> = ({ fileId, onSave }) => {
	const {
		fileForm: { file },
		initFile,
		resetFileForm,
	} = useFileForm();
	const { updateFile, insertFileTag, destroyFileTag } = useUpdateFile();
	const { updateFileTag } = useUpdateFileTag();

	const [getFile, getFileRes] = useGetFileLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		void getFile({
			variables: {
				id: fileId,
			},
		});

		return () => {
			resetFileForm();
		};
	}, [fileId, getFile, resetFileForm]);

	useEffect(() => {
		if (getFileRes.called && !getFileRes.loading && getFileRes.data) {
			const file = getFileRes.data.getFiles.rows[0];

			void initFile(file);
		} else if (getFileRes.error) {
			// @TODO handle error
		}
	}, [getFileRes.called, getFileRes.data, getFileRes.error, getFileRes.loading, initFile]);

	const lastFileTagIndex = file.fileTags.length - 1;
	const lastFileTag = file.fileTags[lastFileTagIndex];

	return (
		<div>
			<form className="mb-4 flex flex-col gap-2">
				<div>
					Name
					<Input
						value={file.name ?? ""}
						onChange={(e) => {
							updateFile({
								fields: {
									name: e.target.value,
								},
							});
						}}
					/>
				</div>

				<div>
					Tags
					<div className="flex flex-col items-start gap-2">
						{file.fileTags.map((fileTag, i) => {
							return (
								i !== lastFileTagIndex && (
									<Button
										className="group !rounded-full border border-uwgc-midblue hover:text-uwgc-red"
										key={i}
										type={"button"}
										onClick={() => {
											destroyFileTag({
												filter: {
													index: i,
												},
											});
										}}
									>
										{fileTag.tag}
										{/* @TODO only show minus on hover */}
										<MinusIcon className="h-5 w-5 opacity-0 group-hover:opacity-100" />
									</Button>
								)
							);
						})}
					</div>
				</div>
				<div className="flex">
					<Input
						value={lastFileTag.tag ?? ""}
						onChange={(e) => {
							updateFileTag({
								filter: {
									index: lastFileTagIndex,
								},
								fields: {
									tag: e.target.value,
								},
							});
						}}
					/>
					<Button
						className="w-max w-32 !text-uwgc-midblue"
						type={"button"}
						onClick={() => {
							insertFileTag({
								fields: {},
							});
						}}
					>
						+ Add
					</Button>
				</div>
			</form>
			<SaveFileButton onSave={onSave} />
		</div>
	);
};

export default FileForm;
