import FrameContent from "./frameContent/FrameContent";
import FrameHeader from "./frameHeader/FrameHeader";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
}

const Frame: React.FC<Props> = ({ sectionIndex, frameIndex }) => {
	return (
		<div id={"sec-" + sectionIndex + "-frame-" + frameIndex}>
			<FrameHeader sectionIndex={sectionIndex} frameIndex={frameIndex} />
			<FrameContent sectionIndex={sectionIndex} frameIndex={frameIndex} />
		</div>
	);
};

export default Frame;
