import { FiLayers } from "react-icons/fi";
import Button from "../../../../../../../../../../../../../../shared/components/button/Button";
import { useBuilderModals } from "../../../../../../../../../builder.recoil";
import LayerForm from "./LayerForm";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
}

const AddLayerButton: React.FC<Props> = ({ sectionIndex, frameIndex }) => {
	const { pushBuilderModal } = useBuilderModals();

	return (
		<Button
			className="green"
			onClick={() => {
				pushBuilderModal({
					render: <LayerForm sectionIndex={sectionIndex} frameIndex={frameIndex} />,
					title: "Add Layer",
				});
			}}
		>
			Add Layer <FiLayers className="h-5 w-5" />
		</Button>
	);
};

export default AddLayerButton;
