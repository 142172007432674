import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import InputNavItem from "../types/InputNavItem";
import OutputNavItem from "../types/OutputNavItem";

const useProcessNavItems = (navItems: InputNavItem[]) => {
	const location = useLocation();

	return useMemo(() => {
		return navItems.map((navItem): OutputNavItem => {
			return {
				...navItem,
				isCurrent: new RegExp("^" + navItem.href).test(location.pathname),
			};
		});
	}, [navItems, location]);
};

export default useProcessNavItems;
