import AssetLibraryContent from "./assetLibraryContent/AssetLibraryContent";
import "./assetLibrary.scss";

const AssetLibrary: React.FC = () => {
	return (
		<div className="asset-library">
			<AssetLibraryContent />
		</div>
	);
};

export default AssetLibrary;
