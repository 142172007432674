import { FilmIcon, MusicNoteIcon, PhotographIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useRecoilState } from "recoil";
import Button from "../../../../../../../../../shared/components/button/Button";
import { AssetFileTypeIds } from "../../../../../../../../../shared/generated/graphql";
import { selectedAssetFileTypeIdAtom } from "../assetFiles.recoil";

const AssetTypeTabs: React.FC = () => {
	const [selectedAssetFileTypeId, setSelectedAssetFileTypeId] = useRecoilState(
		selectedAssetFileTypeIdAtom,
	);

	return (
		<div className="al-sidebar">
			<Button
				className={classNames({
					active: selectedAssetFileTypeId === AssetFileTypeIds.Image,
					"library-btn": true,
				})}
				onClick={() => {
					setSelectedAssetFileTypeId(AssetFileTypeIds.Image);
				}}
			>
				Images
				<PhotographIcon className="h-5 w-5" />
			</Button>
			<Button
				className={classNames({
					active: selectedAssetFileTypeId === AssetFileTypeIds.Video,
					"library-btn": true,
				})}
				onClick={() => {
					setSelectedAssetFileTypeId(AssetFileTypeIds.Video);
				}}
			>
				Videos
				<FilmIcon className="h-5 w-5" />
			</Button>
			<Button
				className={classNames({
					active: selectedAssetFileTypeId === AssetFileTypeIds.Audio,
					"library-btn": true,
				})}
				onClick={() => {
					setSelectedAssetFileTypeId(AssetFileTypeIds.Audio);
				}}
			>
				Audios
				<MusicNoteIcon className="h-5 w-5" />
			</Button>
		</div>
	);
};

export default AssetTypeTabs;
