import { ServerIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Button from "../../../../../../../../../../shared/components/button/Button";
import { useFileForm, useSaveFile } from "../../assetFiles.recoil";

interface Props {
	form?: HTMLFormElement;
	onSave?: () => void;
}

const SaveFileButton: React.FC<Props> = ({ form, onSave }) => {
	const {
		fileForm: { isDirty, file },
	} = useFileForm();

	const { saveFile, saveFileRes } = useSaveFile();

	useEffect(() => {
		if (saveFileRes.isCalled && !saveFileRes.isSaving) {
			if (!saveFileRes.error) {
				toast.success("Saved Successfully!");
				onSave?.();
			} else {
				toast.error("Save error");
			}
		}
	}, [onSave, saveFileRes.error, saveFileRes.isCalled, saveFileRes.isSaving]);

	return (
		<Button
			className="blue mx-auto"
			disabled={file.id !== undefined && !isDirty}
			onClick={() => {
				if (form && !form.checkValidity()) {
					toast.error("Save error");
				}
				void saveFile(form);
			}}
		>
			Save <ServerIcon className="h-5 w-5" />
		</Button>
	);
};

export default SaveFileButton;
