import { useRecoilValue, useResetRecoilState } from "recoil";
import { storyEngineStateAtom } from "./storyEngine.recoil";
import { selectedStoryAtom } from "../stories.recoil";
import RunStory from "./RunStory";
import StoryDashboard from "./StoryDashboard";
import "./story.scss";
import { useEffect } from "react";

const Story: React.FC = () => {
	// @TODO optimize storyEngineState update, only update if this component is open, currently it updates when the control is connected to the display regardless of what component is open
	const storyEngineState = useRecoilValue(storyEngineStateAtom);
	const resetStoryEngineState = useResetRecoilState(storyEngineStateAtom);
	const selectedStory = useRecoilValue(selectedStoryAtom)!;

	useEffect(() => {
		return () => {
			resetStoryEngineState();
		};
	}, [resetStoryEngineState]);

	return (
		<div className="story-control-app">
			{storyEngineState.story?.id === selectedStory.id ? <RunStory /> : <StoryDashboard />}
		</div>
	);
};

export default Story;
