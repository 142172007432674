import Button from "../../../../../../../../../shared/components/button/Button";
import { ChartDataSetFragment } from "../../../../../../../../../shared/generated/graphql";
import { useBuilderModals } from "../../../../builder.recoil";
import ChartDataSetPicker from "./ChartDataSetPicker";

interface Props {
	onChartDataSetPick?: (chartDataSet: ChartDataSetFragment) => void;
}

const OpenChartDataSetPickerButton: React.FC<Props> = ({ children, onChartDataSetPick }) => {
	const { pushBuilderModal, popBuilderModal } = useBuilderModals();

	return (
		<Button
			className="orange"
			type={"button"}
			onClick={() => {
				pushBuilderModal({
					render: (
						<ChartDataSetPicker
							onChartDataSetPick={(chartDataSet) => {
								onChartDataSetPick?.(chartDataSet);
								popBuilderModal();
							}}
						/>
					),
					title: "Data Set Picker",
				});
			}}
		>
			{children}
		</Button>
	);
};

export default OpenChartDataSetPickerButton;
