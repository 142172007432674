import { useState } from "react";
import Tabs from "../../../../../components/Tabs";
import "./dashboard.scss";
import Maps from "./maps/Maps";
import Stories from "./stories/Stories";

enum TabNames {
	STORIES = "Stories",
	VIDEOS = "Videos",
	MAPS = "Maps",
}

const Dashboard: React.FC = () => {
	const [selectedTabName, setSelectedTabName] = useState("Stories");

	return (
		<div className="dashboard">
			<Tabs
				tabNames={Object.values(TabNames)}
				selectedTabName={selectedTabName}
				onTabSelect={(tabName) => setSelectedTabName(tabName)}
				renderTabContent={() => {
					switch (selectedTabName) {
						case TabNames.STORIES:
							return <Stories />;
						case TabNames.MAPS:
							return <Maps />;
						default:
							return undefined;
					}
				}}
			/>
		</div>
	);
};

export default Dashboard;
