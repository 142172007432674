import React, { InputHTMLAttributes } from "react";
import "./input.scss";

type Props = InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<Props> = (props) => {
	const { className } = props;
	return <input {...props} className={className !== undefined ? "input " + className : "input"} />;
};

export default Input;
