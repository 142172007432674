import { useRecoilValue, useResetRecoilState } from "recoil";
import useSendOutPublicControlWsData from "../../../state/publicControlWs/sendOutData/useSendOutPublicControlData";
import usePublicControlWs from "../../../state/publicControlWs/usePublicControlWs";
import {
	createOutPublicControlWsData,
	OutPublicControlWsDataTypes,
} from "../../../types/publicControlWs/outPublicControlWsData/OutPublicControlWsData";
import { canViewAtom, isViewingAtom, remainingTimeAtom } from "../publicControl.recoil";
import { useCallback, useEffect } from "react";
import { publicControlWsConnectionStatusAtom } from "../../../state/publicControlWs/publicControlWs.recoil";
import WsConnectionStatus from "../../../../shared/types/WsConnectionStatus";
import "./view-on-monitors.scss";
import { selectedStoryAtom, selectedZipCodeAtom } from "../public.recoil";
import Viewer from "./Viewer";

const ViewOnMonitors: React.FC = () => {
	usePublicControlWs();

	const publicControlWsConnectionStatus = useRecoilValue(publicControlWsConnectionStatusAtom);
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const selectedZipCode = useRecoilValue(selectedZipCodeAtom)!;

	const canView = useRecoilValue(canViewAtom);
	const isViewing = useRecoilValue(isViewingAtom);
	const sendOutPublicControlWsData = useSendOutPublicControlWsData();

	const resetCanView = useResetRecoilState(canViewAtom);
	const resetIsViewing = useResetRecoilState(isViewingAtom);
	const resetRemainingTime = useResetRecoilState(remainingTimeAtom);

	const reset = useCallback(() => {
		resetCanView();
		resetIsViewing();
		resetRemainingTime();
	}, [resetCanView, resetIsViewing, resetRemainingTime]);

	useEffect(() => {
		return () => {
			reset();
		};
	}, [reset]);

	useEffect(() => {
		if (publicControlWsConnectionStatus === WsConnectionStatus.NOT_CONNECTED) {
			reset();
		}
	}, [publicControlWsConnectionStatus, reset]);

	const _canView = canView && publicControlWsConnectionStatus === WsConnectionStatus.CONNECTED;

	return (
		<div className="view-on-monitors sticky bottom-0">
			<div className="monitor-action">
				{isViewing ? (
					<Viewer />
				) : (
					<>
						<div className="text-center">
							View map for zip code {selectedZipCode.zipCode} on window monitors
						</div>
						<div className={_canView ? "available" : "unavailable"}>
							{_canView ? "Available" : "Not Available"}
						</div>
						<button
							className="button-orange"
							disabled={!_canView}
							onClick={() =>
								sendOutPublicControlWsData(
									createOutPublicControlWsData(OutPublicControlWsDataTypes.VIEW, {
										isView: true,
										storyId: selectedStory.id,
										zipCode: selectedZipCode.zipCode,
									}),
								)
							}
						>
							VIEW ON MONITORS
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export default ViewOnMonitors;
