import React, { SelectHTMLAttributes } from "react";
import "./select.scss";

type Props = SelectHTMLAttributes<HTMLSelectElement>;

const Select: React.FC<Props> = ({ className, ...props }) => {
	return (
		<select {...props} className={className !== undefined ? "select " + className : "select"} />
	);
};

export default Select;
