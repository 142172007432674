import { useEffect, useRef } from "react";
import Input from "../../../../../../../shared/components/input/Input";
import { useGetChartDataSetLazyQuery } from "../../../../../../../shared/generated/graphql";
import ChartDataPoints from "./chartDataPoints/ChartDataPoints";
import { useChartDataSetForm, useUpdateChartDataSet } from "../chartDataSets.recoil";
import SaveChartDataSetButton from "./SaveChartDataSetButton";
import CloseChartDataSetFormButton from "./CloseChartDataSetFormButton";
import { MdArrowBackIos } from "react-icons/md";

interface Props {
	chartDataSetId?: number;
}

const ChartDataSetForm: React.FC<Props> = ({ chartDataSetId }) => {
	const fromRef = useRef<HTMLFormElement>(null);
	const {
		chartDataSetForm: { chartDataSet },
		initChartDataSet,
		resetChartDataSetForm,
	} = useChartDataSetForm();
	const { updateChartDataSet } = useUpdateChartDataSet();

	const [getChartDataSet, getChartDataSetRes] = useGetChartDataSetLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		if (chartDataSetId !== undefined) {
			void getChartDataSet({
				variables: {
					id: chartDataSetId,
				},
			});
		}

		return () => {
			resetChartDataSetForm();
		};
	}, [chartDataSetId, getChartDataSet, resetChartDataSetForm]);

	useEffect(() => {
		if (getChartDataSetRes.called && !getChartDataSetRes.loading && getChartDataSetRes.data) {
			const chartDataSet = getChartDataSetRes.data.getChartDataSets.rows[0];

			void initChartDataSet(chartDataSet);
		} else if (getChartDataSetRes.error) {
			// @TODO handle error
		}
	}, [
		getChartDataSetRes.called,
		getChartDataSetRes.data,
		getChartDataSetRes.error,
		getChartDataSetRes.loading,
		initChartDataSet,
	]);

	return (
		<div className="p-4">
			<CloseChartDataSetFormButton>
				<MdArrowBackIos className="h-5 w-5" /> Data Sets
			</CloseChartDataSetFormButton>
			<form ref={fromRef}>
				<div className="mb-4 w-96 px-4">
					<label className="pl-4 font-bold">Dataset Name*</label>
					<Input
						required
						placeholder={"Data Set Name"}
						value={chartDataSet.name ?? ""}
						onChange={(e) => {
							updateChartDataSet({
								fields: {
									name: e.target.value,
								},
							});
						}}
					/>
				</div>
				<div>
					<label className="pl-8 font-bold">Data*</label>
					<ChartDataPoints />
				</div>
			</form>
			<div className="flex w-full justify-center">
				<SaveChartDataSetButton form={fromRef.current!} />
			</div>
		</div>
	);
};

export default ChartDataSetForm;
