import ComponentsList from "./ComponentsList";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
}

const Components: React.FC<Props> = ({ sectionIndex, frameIndex, layerIndex, containerIndex }) => {
	return (
		<div>
			<ComponentsList
				sectionIndex={sectionIndex}
				frameIndex={frameIndex}
				layerIndex={layerIndex}
				containerIndex={containerIndex}
			/>
		</div>
	);
};

export default Components;
