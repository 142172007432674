import PrivateContent from "./privateContent/PrivateContent";
import PrivateHeader from "./privateHeader/PrivateHeader";
import PrivateSideBar from "./privateSideBar/PrivateSideBar";

const Private: React.FC = () => {
	return (
		<div>
			<PrivateHeader />
			<PrivateSideBar />
			<PrivateContent />
		</div>
	);
};

export default Private;
