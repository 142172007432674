import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetStoryByNameAndIdLazyQuery } from "../../../shared/generated/graphql";
import { parseStoryNameAndId } from "../../utils/utils";
import StoryPreview from "../private/privateContent/builder/stories/storyPreview/StoryPreview";

const ShareStory: React.FC = () => {
	const params = useParams<{
		storyNameAndId: string;
	}>();

	const [getStoryByNameAndId, getStoryByNameAndIdRes] = useGetStoryByNameAndIdLazyQuery({
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		const parsedStoryNameAndId = parseStoryNameAndId(params.storyNameAndId);

		void getStoryByNameAndId({
			variables: {
				id: parsedStoryNameAndId.id,
				name: parsedStoryNameAndId.name,
			},
		});
	}, [getStoryByNameAndId, params.storyNameAndId]);

	const story = useMemo(() => {
		if (getStoryByNameAndIdRes.data && getStoryByNameAndIdRes.data.getStories.rows.length !== 0) {
			return getStoryByNameAndIdRes.data.getStories.rows[0];
		}

		return null;
	}, [getStoryByNameAndIdRes.data]);

	return (
		<>
			{story && (
				<div className="h-screen bg-uwgc-charcoal">
					<StoryPreview story={story} />
				</div>
			)}
		</>
	);
};

export default ShareStory;
