import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useGetStoryCallsCountLazyQuery } from "../../../shared/generated/graphql";
import Card from "../../components/Card";
import { selectedStoryAtom, selectedZipCodeAtom } from "./public.recoil";

const CallsCount: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const selectedZipCode = useRecoilValue(selectedZipCodeAtom)!;

	const [getStoryCallsCount, getStoryCallsCountRes] = useGetStoryCallsCountLazyQuery();

	useEffect(() => {
		void getStoryCallsCount({
			variables: {
				input: {
					filter: {
						storyId: selectedStory.id,
						zipCode: selectedZipCode.zipCode,
					},
				},
			},
		});
	}, [getStoryCallsCount, selectedStory.id, selectedZipCode.zipCode]);

	const storyCallCount = getStoryCallsCountRes.data?.getStoryCallsCount;

	return (
		<Card className="food-insecurity-number my-3 text-lg">
			Jan 2021 through Jan 2022
			<br />
			<span className="text-[52px] font-bold leading-snug text-uwgc-orange">
				{storyCallCount?.toLocaleString("en-US")}
			</span>
			<br />
			{selectedStory.name === "Hunger" && <>people experienced food insecurities in zip code </>}
			{selectedStory.name === "Mental Health" && (
				<>people experienced mental health issues in zip code </>
			)}
			{selectedStory.name === "Housing" && <>people experienced housing issues in zip code 44105</>}
			{selectedZipCode.zipCode}
		</Card>
	);
};

export default CallsCount;
