import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import {
	AssetFileFragment,
	AssetFileTypeIds,
} from "../../../../../../../../../shared/generated/graphql";
import {
	AssetFilesModes,
	selectedAssetFilesModeAtom,
	selectedAssetFileTypeIdAtom,
	useUploadAssetFileTags,
} from "../../../../assetLibrary/assetLibraryContent/assetFiles/assetFiles.recoil";
import { useStoryForm } from "../../story.recoil";
import AssetPickerContent from "./assetPickerContent/AssetPickerContent";
import AssetPickerFooter from "./assetPickerFooter/AssetPickerFooter";

interface Props {
	typeId: AssetFileTypeIds;
	onAssetPick?: (assetFile: AssetFileFragment) => void;
}

const AssetPicker: React.FC<Props> = ({ typeId, onAssetPick }) => {
	const {
		storyForm: { story },
	} = useStoryForm();
	const setSelectedAssetFilesModeAtom = useSetRecoilState(selectedAssetFilesModeAtom);
	const setSelectedAssetFileTypeId = useSetRecoilState(selectedAssetFileTypeIdAtom);
	const { addUploadAssetFileTag } = useUploadAssetFileTags();

	useEffect(() => {
		setSelectedAssetFilesModeAtom(AssetFilesModes.Picker);
		setSelectedAssetFileTypeId(typeId);

		addUploadAssetFileTag(story.name ?? "");
	}, [
		addUploadAssetFileTag,
		setSelectedAssetFileTypeId,
		setSelectedAssetFilesModeAtom,
		story.name,
		typeId,
	]);

	return (
		<div>
			<AssetPickerContent />
			<AssetPickerFooter onAssetPick={onAssetPick} />
		</div>
	);
};

export default AssetPicker;
