import React, { useCallback, useState } from "react";
import LayerTabs from "../frames/framesList/frame/frameContent/layerTabs/LayerTabs";
import LayersOverlay from "./layersOverlay/LayersOverlay";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
}

const Layers: React.FC<Props> = ({ sectionIndex, frameIndex }) => {
	const [selectedLayerIndex, setSelectedLayerIndex] = useState(0);

	const _onSelectLayer = useCallback(
		(layerIndex: number) => {
			setSelectedLayerIndex(layerIndex);
		},
		[setSelectedLayerIndex],
	);

	return (
		<div className="layers">
			<LayerTabs
				sectionIndex={sectionIndex}
				frameIndex={frameIndex}
				selectedLayerIndex={selectedLayerIndex}
				onSelectLayer={_onSelectLayer}
			/>
			<LayersOverlay
				sectionIndex={sectionIndex}
				frameIndex={frameIndex}
				selectedLayerIndex={selectedLayerIndex}
			/>
		</div>
	);
};

export default Layers;
