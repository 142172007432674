import { useStoryForm } from "../../../story.recoil";
import Container from "./container/Container";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
}

const ContainersList: React.FC<Props> = ({ sectionIndex, frameIndex, layerIndex }) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const containers =
		story.sections?.[sectionIndex]?.frames?.[frameIndex]?.layers?.[layerIndex].containers || [];

	return (
		<div className="grid grid-cols-3">
			{containers.map((container, i) => {
				return (
					<Container
						key={i}
						sectionIndex={sectionIndex}
						frameIndex={frameIndex}
						layerIndex={layerIndex}
						containerIndex={i}
						container={container}
					/>
				);
			})}
		</div>
	);
};

export default ContainersList;
