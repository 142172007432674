import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import useProcessNavItems from "../../../../shared/hooks/useProcessNavItems";
import { PathName } from "../../../constants/PathName";
import { isSidebarOpenAtom } from "../../../state/sidebar.recoil";
import "./privateSidebar.scss";

const PrivateSideBar = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenAtom);
	const navItems = useProcessNavItems([
		{
			href: PathName.CONTROL_DASHBOARD,
			name: "Community Dashboard",
		},
		{
			href: PathName.BUILDER,
			name: "Story Builder",
		},
		// { // @TODO
		// 	href: PathName.SETTINGS,
		// 	name: "Settings",
		// },
	]);

	return (
		<>
			{isSidebarOpen && <div className="backdrop" onClick={() => setIsSidebarOpen(false)} />}
			<div className={"sidebar " + (isSidebarOpen ? " sidebar-open " : "")}>
				{navItems.map((navItem) => {
					return (
						<Link
							key={navItem.href}
							className={"link " + (navItem.isCurrent ? " active" : "")}
							to={navItem.href}
							onClick={() => setIsSidebarOpen(false)}
						>
							{navItem.name}
						</Link>
					);
				})}
			</div>
		</>
	);
};

export default PrivateSideBar;
