import { XIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdDone } from "react-icons/md";
import { toast } from "react-toastify";
import Button from "../../../../../../../../../../../../../../shared/components/button/Button";
import { useBuilderModals } from "../../../../../../../../../builder.recoil";
import {
	useLayerTempForm,
	useUpdateFrame,
	useUpdateLayer,
	useUpdateLayerTemp,
} from "../../../../../../../story.recoil";

interface Props {
	sectionIndex: number;
	frameIndex: number;
}

const LayerForm: React.FC<Props> = ({ sectionIndex, frameIndex }) => {
	const formRef = useRef<HTMLFormElement>(null);

	const { popBuilderModal } = useBuilderModals();
	const [selectedLayout, setSelectedLayout] = useState<"a" | "b" | "c" | "d" | undefined>(
		undefined,
	);

	const {
		layerTempForm: { isDirty, layerIndex, layerTemp },
		resetLayerTempForm,
	} = useLayerTempForm();
	const { updateLayerTemp } = useUpdateLayerTemp();
	const { insertLayer } = useUpdateFrame(sectionIndex);
	const { updateLayer } = useUpdateLayer(sectionIndex, frameIndex);
	const [isRequiredError, setRequiredError] = useState(false);

	const _cancel = useCallback(() => {
		popBuilderModal();
	}, [popBuilderModal]);

	const _continue = useCallback(() => {
		if (!formRef.current || !formRef.current.checkValidity()) {
			toast.error("Save error");
			setRequiredError(true);
			return;
		}

		if (layerIndex !== undefined) {
			if (isDirty) {
				updateLayer({
					filter: {
						index: layerIndex,
					},
					fields: {
						...layerTemp,
					},
				});
			}
		} else {
			insertLayer(frameIndex, {
				fields: {
					...layerTemp,
				},
			});
		}
		setRequiredError(false);
		popBuilderModal();
	}, [popBuilderModal, layerIndex, isDirty, updateLayer, layerTemp, insertLayer, frameIndex]);

	useEffect(() => {
		return () => {
			resetLayerTempForm();
		};
	}, [resetLayerTempForm]);

	return (
		<div className="flex max-w-md flex-col gap-8">
			<form ref={formRef}>
				<div className="flex flex-col gap-8">
					<div className="text-center">SELECT LAYOUT*</div>
					<div
						className="grid cursor-pointer grid-cols-3 gap-2 hover:brightness-90"
						onClick={() => {
							updateLayerTemp({
								fields: {
									containers: [
										{
											screenIndices: [0],
											delay: 0,
											components: [],
										},
										{
											screenIndices: [1],
											delay: 0,
											components: [],
										},
										{
											screenIndices: [2],
											delay: 0,
											components: [],
										},
									],
								},
							});
							setSelectedLayout("a");
						}}
					>
						<div
							className={
								"col-span-1 h-11 rounded " +
								(selectedLayout === "a" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
							}
						></div>
						<div
							className={
								"col-span-1 h-11 rounded " +
								(selectedLayout === "a" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
							}
						></div>
						<div
							className={
								"col-span-1 h-11 rounded " +
								(selectedLayout === "a" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
							}
						></div>
					</div>
					<div
						className="grid cursor-pointer grid-cols-3 gap-2 hover:brightness-90"
						onClick={() => {
							updateLayerTemp({
								fields: {
									containers: [
										{
											screenIndices: [0],
											delay: 0,
											components: [],
										},
										{
											screenIndices: [1, 2],
											delay: 0,
											components: [],
										},
									],
								},
							});
							setSelectedLayout("b");
						}}
					>
						<div
							className={
								"col-span-1 h-11 rounded " +
								(selectedLayout === "b" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
							}
						></div>
						<div
							className={
								"col-span-2 h-11 rounded " +
								(selectedLayout === "b" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
							}
						></div>
					</div>
					<div
						className="grid cursor-pointer grid-cols-3 gap-2 hover:brightness-90"
						onClick={() => {
							updateLayerTemp({
								fields: {
									containers: [
										{
											screenIndices: [0, 1],
											delay: 0,
											components: [],
										},
										{
											screenIndices: [2],
											delay: 0,
											components: [],
										},
									],
								},
							});
							setSelectedLayout("c");
						}}
					>
						<div
							className={
								"col-span-2 h-11 rounded " +
								(selectedLayout === "c" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
							}
						></div>
						<div
							className={
								"col-span-1 h-11 rounded " +
								(selectedLayout === "c" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
							}
						></div>
					</div>
					<div
						className="grid cursor-pointer grid-cols-3 gap-2 hover:brightness-90"
						onClick={() => {
							updateLayerTemp({
								fields: {
									containers: [
										{
											screenIndices: [0, 1, 2],
											delay: 0,
											components: [],
										},
									],
								},
							});
							setSelectedLayout("d");
						}}
					>
						<div
							className={
								"col-span-3 h-11 rounded " +
								(selectedLayout === "d" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
							}
						></div>
					</div>
				</div>
			</form>
			{isRequiredError && <div className="text-uwgc-red">Please select Layout</div>}
			<div className="flex justify-between gap-4">
				<Button className="red" onClick={() => _cancel()}>
					Cancel <XIcon className="h-5 w-5" />
				</Button>
				<Button className="blue" disabled={!selectedLayout} onClick={() => _continue()}>
					Done <MdDone className="h-5 w-5" />
				</Button>
			</div>
		</div>
	);
};

export default LayerForm;
