import DisplayModes from "../../../../shared/types/DisplayModes";
import ControlMapCommandData from "./ControlMapCommandData";
import ControlStoryCommandData from "./ControlStoryCommandData";

export enum OutControlWsDataTypes {
	SET_DISPLAY_MODE,
	STORY_COMMAND,
	MAP_COMMAND,
	SET_IS_LOCKED,
	OVERRIDE_CONNECTION,
	RELOAD_DISPLAY,
}

type OutControlWsDataTypeMap = {
	[OutControlWsDataTypes.SET_DISPLAY_MODE]: DisplayModes;
	[OutControlWsDataTypes.STORY_COMMAND]: ControlStoryCommandData;
	[OutControlWsDataTypes.MAP_COMMAND]: ControlMapCommandData;
	[OutControlWsDataTypes.SET_IS_LOCKED]: boolean;
	[OutControlWsDataTypes.OVERRIDE_CONNECTION]: undefined;
	[OutControlWsDataTypes.RELOAD_DISPLAY]: undefined;
};

interface OutControlWsData<T extends OutControlWsDataTypes = OutControlWsDataTypes> {
	type: T;
	data: OutControlWsDataTypeMap[T];
}

export const createOutControlWsData = <T extends OutControlWsDataTypes>(
	type: T,
	data: OutControlWsDataTypeMap[T],
): OutControlWsData<T> => {
	return {
		type,
		data,
	};
};

export default OutControlWsData;
