import { useStoryForm } from "../../../../../story.recoil";
import EditSectionButton from "../../../sectionForm/EditSectionButton";

export interface Props {
	sectionIndex: number;
}

const SectionHeader: React.FC<Props> = ({ sectionIndex }) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const section = story.sections?.[sectionIndex];

	return (
		<div className="section-header">
			Section: {section?.name}
			<EditSectionButton sectionIndex={sectionIndex} />
		</div>
	);
};

export default SectionHeader;
