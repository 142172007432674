import { XIcon } from "@heroicons/react/outline";
import React from "react";
import "./modal.scss";

export interface ModalProps {
	render: JSX.Element;
	title?: JSX.Element | string;
	onCloseClick?: () => void;
}

const Modal: React.FC<ModalProps> = ({ render, onCloseClick, title }) => {
	return (
		<div className="modal">
			<div
				className="card animate__animated animate__fadeInUp animate__faster"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="card-header flex">
					<div className="modal-title">{title}</div>
					<XIcon
						className="w-5 h-5 self-end m-4 hover:bg-gray-300 rounded-full cursor-pointer"
						onClick={() => onCloseClick?.()}
					/>
				</div>
				<div className="modal-content">{render}</div>
			</div>
		</div>
	);
};

export default Modal;
