import { useStoryForm } from "../../story.recoil";
import Component from "./component/Component";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
}

const ComponentsList: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
}) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const components =
		story.sections?.[sectionIndex]?.frames?.[frameIndex]?.layers?.[layerIndex].containers?.[
			containerIndex
		].components;

	return (
		<div className="flex flex-col gap-4">
			{components?.map((component, i) => {
				return (
					<Component
						key={i}
						sectionIndex={sectionIndex}
						frameIndex={frameIndex}
						layerIndex={layerIndex}
						containerIndex={containerIndex}
						componentIndex={i}
					/>
				);
			})}
		</div>
	);
};

export default ComponentsList;
