import SectionContent from "./sectionContent/SectionContent";
import SectionHeader from "./sectionHeader/SectionHeader";

export interface Props {
	sectionIndex: number;
}

const Section: React.FC<Props> = ({ sectionIndex }) => {
	return (
		<div>
			<SectionHeader sectionIndex={sectionIndex} />
			<SectionContent sectionIndex={sectionIndex} />
		</div>
	);
};

export default Section;
