import { Redirect, Route, Switch } from "react-router-dom";
import { PathName } from "../../../../constants/PathName";
import useControlWs from "../../../../state/controlWs/useControlWs";
import Dashboard from "./dashboard/Dashboard";

const Control: React.FC = () => {
	useControlWs();

	return (
		<div className="control">
			<Switch>
				<Route path={PathName.CONTROL_DASHBOARD} component={Dashboard} />
				<Route render={() => <Redirect push to={PathName.NOT_FOUND} />} />
			</Switch>
		</div>
	);
};

export default Control;
