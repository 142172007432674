import MapData from "../../../../../../types/map/MapData";
import { useSetRecoilState } from "recoil";
import { mapSelectedAtom } from "./maps.recoil";
import Card from "../../../../../../components/Card";

//@TODO deleteme data
const maps: MapData[] = [
	{
		id: 1,
		storyId: "food_insecurity",
		name: "Hunger Map",
		description: "View breakdown of hunger statistics across service area.",
		thumbnail: "/assets/jpg/hunger-heatmap.jpg",
	},
	{
		id: 2,
		storyId: "mental_health",
		name: "Mental Health Map",
		description: "View breakdown of mental health statistics across service area.",
		thumbnail: "/assets/jpg/MH-heatmap.jpg",
	},
	{
		id: 3,
		storyId: "housing",
		name: "Housing Map",
		description: "View breakdown of housing statistics across service area.",
		thumbnail: "/assets/jpg/housing-heatmap.jpg",
	},
];

const MapList: React.FC = () => {
	const setMapSelected = useSetRecoilState(mapSelectedAtom);

	return (
		<>
			{maps.map((map, i) => {
				return (
					<Card
						key={i}
						className={"card flex text-uwgc-charcoal w-1/3 p-0 cursor-pointer hover:contrast-75 "}
						onClick={() => setMapSelected(map)}
					>
						<img
							className="w-1/2 h-full object-cover bg-uwgc-slate rounded"
							src={map.thumbnail}
							alt="NO IMG FOUND"
						/>
						<div className="w-1/2 p-4">
							<div className="text-2xl font-bold text-uwgc-midblue">{map.name}</div>
							<div className="text-base leading-6">{map.description}</div>
						</div>
					</Card>
				);
			})}
		</>
	);
};

export default MapList;
