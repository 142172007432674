import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { ModalProps } from "../../../../../shared/g3Components/modal/Modal";
import { cloneArrPush, cloneArrSplice } from "gate3-utils";

const builderModalsAtom = atom<ModalProps[]>({
	key: "builderModal",
	default: [],
});

export const useBuilderModals = () => {
	const [builderModals, setBuilderModals] = useRecoilState(builderModalsAtom);

	const pushBuilderModal = useCallback(
		(props: ModalProps) => {
			setBuilderModals((state) => {
				return cloneArrPush(state, props);
			});
		},
		[setBuilderModals],
	);
	const popBuilderModal = useCallback(() => {
		setBuilderModals((state) => {
			return cloneArrSplice(state, state.length - 1, 1);
		});
	}, [setBuilderModals]);

	return {
		builderModals,
		pushBuilderModal,
		popBuilderModal,
	};
};
