import React, { useMemo } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useRecoilValue } from "recoil";
import { MapDetailDataTypes } from "./types/MapDetailData";
import { mapStateAtom, MAP_COLOR_MAP } from "./map.recoil";

const MapDetailsRight: React.FC = () => {
	const mapState = useRecoilValue(mapStateAtom);

	const { type, data, shouldHideLegends } = useMemo(() => {
		if (mapState.detail) {
			return {
				type: mapState.detail.type,
				data: mapState.detail.pairs.map((pair, i) => {
					return {
						name: pair.name,
						value: pair.count,
						fill: MAP_COLOR_MAP[i] ?? "black",
					};
				}),
				shouldHideLegends: mapState.detail.shouldHideLegends,
			};
		}

		return {
			type: MapDetailDataTypes.PIE_CHART,
			data: [],
			shouldHideLegends: false,
		};
	}, [mapState.detail]);

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
		index,
	}: any) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				fill="white"
				textAnchor={x > cx ? "start" : "end"}
				dominantBaseline="central"
				fontSize={"1.1rem"}
				fontWeight={600}
			>
				{percent > 0.2 && `${(percent * 100).toFixed(0)}% `}
			</text>
		);
	};
	return (
		<div className="bg-uwgc-blue text-white w-full h-full flex flex-col justify-center">
			{type === MapDetailDataTypes.PIE_CHART && (
				<div className="flex flex-col items-center relative">
					<div className="flex w-full h-56">
						<ResponsiveContainer>
							<PieChart>
								<Pie
									dataKey="value"
									startAngle={90}
									endAngle={-270}
									data={data}
									cx={"50%"}
									cy={"50%"}
									innerRadius={"55%"}
									outerRadius={"80%"}
									isAnimationActive={false}
									labelLine={false}
									label={renderCustomizedLabel}
								>
									{data.map((entry, i) => (
										<>
											<Cell key={`cell-${i}`} fill={entry.fill} stroke={entry.fill} />
										</>
									))}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
					</div>
					<div className="flex gap-3 mt-5 mb-5">
						{data.map((entry, i) => (
							<div key={i} className="flex items-center gap-1 text-xl">
								<i
									className={`w-5 h-5 rounded-full inline-block`}
									style={{ background: entry.fill }}
								/>
								{entry.name}
							</div>
						))}
					</div>
				</div>
			)}
			{type === MapDetailDataTypes.TABLE && (
				<div className="flex flex-col text-xl px-6">
					{data.map((el, i) => {
						return (
							<div
								key={i}
								className={
									"flex justify-between first:border-y border-b border-solid border-white py-3"
								}
							>
								<div className="flex items-center gap-4">
									{!shouldHideLegends && (
										<i
											className={`w-5 h-5 rounded-full inline-block`}
											style={{ background: el.fill }}
										/>
									)}
									{el.name}
								</div>
								<div>{el.value.toLocaleString("en-US")}</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default MapDetailsRight;
