import { useEffect } from "react";
import { useGetStoryLazyQuery } from "../../../../../../../shared/generated/graphql";
import StorySideBar from "./storySideBar/StorySideBar";
import { useStoryForm } from "./story.recoil";
import StoryContent from "./storyContent/StoryContent";
import StoryHeader from "./storyHeader/StoryHeader";
import { useParams } from "react-router-dom";
import { strToNumber } from "gate3-utils";
import "./story-builder.scss";

const Story: React.FC = () => {
	const params = useParams<{
		storyId: string;
	}>();

	const storyId = strToNumber(params.storyId);

	const { resetStoryForm, initStory } = useStoryForm();

	const [getStory, getStoryRes] = useGetStoryLazyQuery({
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		if (storyId !== undefined) {
			void getStory({
				variables: {
					id: storyId,
				},
			});
		}

		return () => {
			resetStoryForm();
		};
	}, [getStory, resetStoryForm, storyId]);

	useEffect(() => {
		if (getStoryRes.called && !getStoryRes.loading && getStoryRes.data) {
			const story = getStoryRes.data.getStories.rows[0];

			void initStory(story);
		} else if (getStoryRes.error) {
			// @TODO handle error
		}
	}, [getStoryRes.loading, getStoryRes.error, getStoryRes.called, getStoryRes.data, initStory]);

	return (
		<div className="story-builder">
			<StoryHeader />
			<StorySideBar />
			<StoryContent />
		</div>
	);
};

export default Story;
