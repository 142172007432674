import { MusicNoteIcon, XIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdDone } from "react-icons/md";
import { toast } from "react-toastify";
import Button from "../../../../../../../../../../shared/components/button/Button";
import Input from "../../../../../../../../../../shared/components/input/Input";
import Select from "../../../../../../../../../../shared/components/select/Select";
import {
	AssetFileFragment,
	AssetFileTypeIds,
} from "../../../../../../../../../../shared/generated/graphql";
import { COLOR_THEMES } from "../../../../../../../../../constants/Constants";
import { useBuilderModals } from "../../../../../builder.recoil";
import {
	useSectionTempForm,
	useUpdateSection,
	useUpdateSectionTemp,
	useUpdateStory,
} from "../../../story.recoil";
import OpenAssetPickerButton from "../../assetPicker/OpenAssetPickerButton";

const SectionForm: React.FC = () => {
	const formRef = useRef<HTMLFormElement>(null);

	const { popBuilderModal } = useBuilderModals();

	const {
		sectionTempForm: { isDirty, sectionIndex, sectionTemp },
		resetSectionTempForm,
	} = useSectionTempForm();
	const { updateSectionTemp } = useUpdateSectionTemp();
	const { insertSection } = useUpdateStory();
	const { updateSection } = useUpdateSection();
	const [isRequiredError, setRequiredError] = useState(false);

	const _cancel = useCallback(() => {
		popBuilderModal();
	}, [popBuilderModal]);

	const _continue = useCallback(() => {
		if (!formRef.current || !formRef.current.checkValidity()) {
			toast.error("Save error");
			setRequiredError(true);
			return;
		}

		if (sectionIndex !== undefined) {
			if (isDirty) {
				updateSection({
					filter: {
						index: sectionIndex,
					},
					fields: {
						...sectionTemp,
					},
				});
			}
		} else {
			insertSection({
				fields: {
					...sectionTemp,
				},
			});
		}
		setRequiredError(false);
		popBuilderModal();
	}, [sectionIndex, popBuilderModal, isDirty, updateSection, sectionTemp, insertSection]);

	const _onAssetPick = useCallback(
		(assetFile: AssetFileFragment) => {
			updateSectionTemp({
				fields: {
					bgAudioAssetFile: {
						...assetFile,
					},
				},
			});
		},
		[updateSectionTemp],
	);

	useEffect(() => {
		return () => {
			resetSectionTempForm();
		};
	}, [resetSectionTempForm]);

	return (
		<div className="flex max-w-md flex-col gap-8">
			<form ref={formRef} className="flex flex-col gap-4">
				<div>
					<label>Section Name*</label>
					<Input
						required
						value={sectionTemp.name ?? ""}
						onChange={(e) => {
							updateSectionTemp({
								fields: {
									name: e.target.value,
								},
							});
						}}
					/>
				</div>
				<div>
					<label>Color Theme</label>
					<Select
						value={sectionTemp.background ?? ""}
						onChange={(e) => {
							updateSectionTemp({
								fields: {
									background: e.target.value,
								},
							});
						}}
					>
						{COLOR_THEMES.map((theme) => (
							<option key={theme.value} value={theme.value}>
								{theme.text}
							</option>
						))}
					</Select>
				</div>
				<div>
					<label>Background Music</label>
					<div className="flex justify-between gap-4">
						<div className="flex w-80 max-w-xs flex-1 items-center rounded border border-uwgc-darkGray bg-uwgc-midGrey text-uwgc-charcoal">
							{sectionTemp.bgAudioAssetFile?.file?.name}
						</div>
						<OpenAssetPickerButton typeId={AssetFileTypeIds.Audio} onAssetPick={_onAssetPick}>
							PICK AUDIO <MusicNoteIcon className="h-5 w-5" />
						</OpenAssetPickerButton>
					</div>
				</div>
			</form>
			{isRequiredError && <div className="text-uwgc-red">Please enter required Section Name</div>}
			<div className="flex justify-between gap-4">
				<Button className="red" onClick={() => _cancel()}>
					Cancel <XIcon className="h-5 w-5" />
				</Button>
				<Button className="blue" onClick={() => _continue()}>
					Done <MdDone className="h-5 w-5" />
				</Button>
			</div>
		</div>
	);
};

export default SectionForm;
