import { XIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useRef } from "react";
import { MdDone } from "react-icons/md";
import Button from "../../../../../../../../../../shared/components/button/Button";
import Input from "../../../../../../../../../../shared/components/input/Input";
import Select from "../../../../../../../../../../shared/components/select/Select";
import { strToNumber } from "gate3-utils";
import { useBuilderModals } from "../../../../../builder.recoil";
import {
	useContainerTempForm,
	useUpdateContainer,
	useUpdateContainerTemp,
} from "../../../story.recoil";
import { COLOR_THEMES } from "../../../../../../../../../constants/Constants";

interface AnimationData {
	animation: string;
	name: string;
}

const FADE_IN_ANIMATIONS: AnimationData[] = [
	{
		animation: "fadeIn",
		name: "Fade In",
	},
];

const FADE_OUT_ANIMATIONS: AnimationData[] = [
	{
		animation: "fadeOut",
		name: "Fade Out",
	},
];

interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
}

const ContainerForm: React.FC<Props> = ({ sectionIndex, frameIndex, layerIndex }) => {
	const formRef = useRef<HTMLFormElement>(null);

	const { popBuilderModal } = useBuilderModals();

	const {
		containerTempForm: { isDirty, containerIndex, containerTemp },
		resetContainerTempForm,
	} = useContainerTempForm();
	const { updateContainerTemp } = useUpdateContainerTemp();
	const { updateContainer } = useUpdateContainer(sectionIndex, frameIndex, layerIndex);

	const _cancel = useCallback(() => {
		popBuilderModal();
	}, [popBuilderModal]);

	const _continue = useCallback(() => {
		if (!formRef.current || !formRef.current.checkValidity()) {
			return;
		}
		if (containerIndex !== undefined) {
			if (isDirty) {
				updateContainer({
					filter: {
						index: containerIndex,
					},
					fields: {
						...containerTemp,
					},
				});
			}
		}

		popBuilderModal();
	}, [containerIndex, containerTemp, isDirty, popBuilderModal, updateContainer]);

	useEffect(() => {
		return () => {
			resetContainerTempForm();
		};
	}, [resetContainerTempForm]);

	return (
		<div className="flex max-w-md flex-col gap-8">
			<form ref={formRef} className="flex flex-col gap-4">
				<div>
					<label>Delay Start (seconds)*</label>
					<Input
						type={"number"}
						required
						value={containerTemp.delay ?? ""}
						onChange={(e) => {
							updateContainerTemp({
								fields: {
									delay: strToNumber(e.target.value),
								},
							});
						}}
					/>
				</div>
				<div>
					<label>Color Theme</label>
					<Select
						value={containerTemp.background ?? ""}
						onChange={(e) => {
							updateContainerTemp({
								fields: {
									background: e.target.value,
								},
							});
						}}
					>
						{[{ value: "", text: "" }, ...COLOR_THEMES].map((theme) => (
							<option key={theme.value} value={theme.value}>
								{theme.text}
							</option>
						))}
					</Select>
				</div>
				{/* @TODO animation selection? */}
				<div>
					<label>Intro Animation</label>
					<Select
						value={containerTemp.inAnimation ?? ""}
						onChange={(e) => {
							updateContainerTemp({
								fields: {
									inAnimation: e.target.value,
								},
							});
						}}
					>
						{[{ animation: "", name: "" }, ...FADE_IN_ANIMATIONS].map((animation) => {
							return (
								<option key={animation.animation} value={animation.animation}>
									{animation.name}
								</option>
							);
						})}
					</Select>
				</div>
				<div>
					<label>Outro Animation</label>
					<Select
						value={containerTemp.outAnimation ?? ""}
						onChange={(e) => {
							updateContainerTemp({
								fields: {
									outAnimation: e.target.value,
								},
							});
						}}
					>
						{[{ animation: "", name: "" }, ...FADE_OUT_ANIMATIONS].map((animation) => {
							return (
								<option key={animation.animation} value={animation.animation}>
									{animation.name}
								</option>
							);
						})}
					</Select>
				</div>
			</form>
			<div className="flex justify-between gap-4">
				<Button className="red" onClick={() => _cancel()}>
					Cancel
					<XIcon className="h-5 w-5" />
				</Button>
				<Button className="blue" onClick={() => _continue()}>
					Done
					<MdDone className="h-5 w-5" />
				</Button>
			</div>
		</div>
	);
};

export default ContainerForm;
