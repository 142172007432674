import { Redirect, Route, Switch } from "react-router-dom";
import { PathName } from "../../../../../../../constants/PathName";
import Sections from "./sections/Sections";
import StoryForm from "./StoryForm";

const StoryContent: React.FC = () => {
	return (
		<div
			className="story-builder-content scrollspy-example scroll-smooth"
			data-bs-spy="scroll"
			data-bs-target="#sectionsAccordion"
			data-bs-offset="200"
		>
			<Switch>
				<Route exact path={PathName.BUILDER_STORIES(":storyId")} component={StoryForm} />
				<Route path={PathName.BUILDER_STORIES_SECTIONS(":storyId")} component={Sections} />
				<Route render={() => <Redirect push to={PathName.NOT_FOUND} />} />
			</Switch>
		</div>
	);
};

export default StoryContent;
