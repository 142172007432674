import { useCallback, useEffect } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
	AssetFilesModes,
	selectedAssetFilesModeAtom,
	selectedAssetFileTagAtom,
	selectedAssetFileTypeIdAtom,
} from "../assetFiles.recoil";
import { useAssetFilesGridReloader } from "../assetFilesContent/assetFilesGrid/assetFilesGrid.recoil";
import AssetTypeTabs from "./AssetTypeTabs";
import DeleteFilesButton from "./DeleteAssetFilesButton";
import EditFileButton from "./EditFileButton";
import UploadAssetFileInput from "./UploadAssetFileInput";

const AssetFilesHeader: React.FC = () => {
	const resetSelectedAssetFileTag = useResetRecoilState(selectedAssetFileTagAtom);
	const selectedAssetFilesMode = useRecoilValue(selectedAssetFilesModeAtom);
	const resetSelectedAssetFileTypeId = useResetRecoilState(selectedAssetFileTypeIdAtom);
	const { reloadAssetFilesGrid } = useAssetFilesGridReloader();

	const _onUploadFinish = useCallback(() => {
		reloadAssetFilesGrid();
	}, [reloadAssetFilesGrid]);

	useEffect(() => {
		return () => {
			if (selectedAssetFilesMode === AssetFilesModes.Page) {
				resetSelectedAssetFileTypeId();
			}
		};
	}, [resetSelectedAssetFileTypeId, selectedAssetFilesMode]);

	return (
		<div className="flex flex-wrap justify-between gap-4 py-8 px-4">
			<div className="flex flex-wrap gap-8">
				<div
					className="flex items-center font-bold text-uwgc-midblue hover:cursor-pointer"
					onClick={() => {
						resetSelectedAssetFileTag();
					}}
				>
					<MdArrowBackIos className="h-5 w-5" /> Folders
				</div>

				{selectedAssetFilesMode === AssetFilesModes.Page && <AssetTypeTabs />}
			</div>

			{selectedAssetFilesMode === AssetFilesModes.Page && (
				<div className="flex gap-2">
					<UploadAssetFileInput onUploadFinish={_onUploadFinish} />
					<EditFileButton />
					<DeleteFilesButton />
				</div>
			)}
		</div>
	);
};

export default AssetFilesHeader;
