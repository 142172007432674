import { Link, Redirect, Route, Switch } from "react-router-dom";
import useProcessNavItems from "../../../../../shared/hooks/useProcessNavItems";
import { PathName } from "../../../../constants/PathName";
import AssetLibrary from "./assetLibrary/AssetLibrary";
import BuilderModals from "./BuilderModals";
import ChartDataSets from "./chartDataSets/ChartDataSets";
import Stories from "./stories/Stories";

const Builder: React.FC = () => {
	const navItems = useProcessNavItems([
		{
			href: PathName.BUILDER_STORIES(),
			name: "Stories",
		},
		{
			href: PathName.BUILDER_ASSETS,
			name: "Asset Library",
		},
		{
			href: PathName.BUILDER_CHART_DATA_SETS,
			name: "Chart Data Sets",
		},
	]);

	return (
		<div className="builder tabs-comp">
			<div className="tabs">
				{navItems.map((navItem) => {
					return (
						<Link
							className={"tab " + (navItem.isCurrent ? "selected" : "")}
							key={navItem.href}
							to={navItem.href}
						>
							{navItem.name}
						</Link>
					);
				})}
			</div>
			<Switch>
				<Route
					exact
					path={PathName.BUILDER}
					render={() => <Redirect push to={PathName.BUILDER_STORIES()} />}
				/>
				<Route path={PathName.BUILDER_STORIES()} component={Stories} />
				<Route path={PathName.BUILDER_ASSETS} component={AssetLibrary} />
				<Route path={PathName.BUILDER_CHART_DATA_SETS} component={ChartDataSets} />
				<Route render={() => <Redirect push to={PathName.NOT_FOUND} />} />
			</Switch>
			<BuilderModals />
		</div>
	);
};

export default Builder;
