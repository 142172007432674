import { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import {
	isMutedAtom,
	useStoryEngineBgAudioVolume,
	useStoryEngineState,
} from "../../../shared/state/storyEngine/storyEngine.recoil";
import { getFullFilePath } from "../../../shared/utils/Utils";

const BgAudio: React.FC = () => {
	const audioRef = useRef<HTMLAudioElement>(null);

	const storyEngineState = useStoryEngineState();
	const storyEngineBgAudioVolume = useStoryEngineBgAudioVolume();
	const isMuted = useRecoilValue(isMutedAtom);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.src =
				storyEngineState.bgAudioAssetFile !== undefined
					? getFullFilePath(storyEngineState.bgAudioAssetFile.file)
					: "";
		}
	}, [audioRef, storyEngineState.bgAudioAssetFile]);

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.volume = storyEngineBgAudioVolume;
		}
	}, [audioRef, storyEngineBgAudioVolume]);

	useEffect(() => {
		if (audioRef.current) {
			if (storyEngineState.isPaused) {
				void audioRef.current.pause();
			} else {
				void audioRef.current.play();
			}
		}
	}, [audioRef, storyEngineState.isPaused]);

	return (
		<div>
			<audio className="opacity-0 h-0 w-0 absolute" autoPlay loop ref={audioRef} muted={isMuted}>
				Your browser does not support the audio element.
			</audio>
		</div>
	);
};

export default BgAudio;
