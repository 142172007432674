import { AiOutlinePlus } from "react-icons/ai";
import OpenChartDataSetFormButton from "../../chartDataSetForm/OpenChartDataSetFormButton";

const ChartDataSetsHeader: React.FC = () => {
	return (
		<div className="flex  w-full justify-end p-4">
			{/* <OpenChartDataSetFormButton>
				Create Data Set
				<AiOutlinePlus className="h-4 w-4" />
			</OpenChartDataSetFormButton> */}
		</div>
	);
};

export default ChartDataSetsHeader;
