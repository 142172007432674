import {
	ArrowLeftIcon,
	ClockIcon,
	CollectionIcon,
	LockClosedIcon,
	LockOpenIcon,
	PauseIcon,
	PlayIcon,
	StopIcon,
} from "@heroicons/react/outline";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { storyEngineStateAtom } from "./storyEngine.recoil";
import env from "../../../../../../../../env";
import {
	ControlStoryCommandDataTypes,
	createControlStoryCommandData,
} from "../../../../../../../types/controlWs/outControlWsData/ControlStoryCommandData";
import { useEffect, useState } from "react";
import useSendOutControlWsData from "../../../../../../../state/controlWs/sendOutData/useSendOutControlWsData";
import {
	createOutControlWsData,
	OutControlWsDataTypes,
} from "../../../../../../../types/controlWs/outControlWsData/OutControlWsData";
import { selectedStoryAtom } from "../stories.recoil";
import useSendControlStoryCommandData from "../../../../../../../state/controlWs/sendOutData/useSendControlStoryCommandData";
import Input from "../../../../../../../../shared/components/input/Input";
import Slider from "../../../../../../../../shared/components/slider/Slider";

const RunStory: React.FC = () => {
	const setSelectedStory = useSetRecoilState(selectedStoryAtom);
	const sendSendOutControlWsData = useSendOutControlWsData();
	const sendControlStoryCommand = useSendControlStoryCommandData();
	const storyEngineState = useRecoilValue(storyEngineStateAtom);
	const [isLocked, setIsLocked] = useState(false);

	useEffect(() => {
		return () => {
			sendSendOutControlWsData(createOutControlWsData(OutControlWsDataTypes.SET_IS_LOCKED, false));
		};
	}, []);

	const sectionDuration =
		storyEngineState.section?.frames.reduce((curr1, prev1) => {
			return curr1 + prev1.duration;
		}, 0) ?? 0;

	const getFramePos = () => {
		if (storyEngineState.section) {
			let startTime = 0;
			for (let i = 0; i < storyEngineState.section.frames.length; ++i) {
				const frame = storyEngineState.section.frames[i];
				const endTime = frame.duration + startTime;
				if (storyEngineState.time >= startTime && storyEngineState.time < endTime) {
					return i + 1;
				}
				startTime = endTime;
			}
		}

		return 0;
	};

	const getImgurl = () => {
		let url = "";
		if (storyEngineState.story && storyEngineState.section) {
			url =
				"/assets/storySnapshots/" +
				storyEngineState.story.id +
				"/" +
				storyEngineState.section.name +
				framePos +
				".jpg";
		}

		return url;
	};

	const setTimeAtFramePos = (framePos: number) => {
		if (storyEngineState.section?.frames === undefined) {
			return;
		}

		let time = 0;
		for (let i = 0; i < framePos - 1; ++i) {
			const frame = storyEngineState.section.frames[i];
			time += frame.duration;
		}

		sendControlStoryCommand(
			createControlStoryCommandData(ControlStoryCommandDataTypes.SET_TIME, time),
		);
	};

	const frameLen = storyEngineState.section?.frames.length ?? 0;
	const framePos = getFramePos();

	const imgUrl = getImgurl();

	return (
		<div className="run-story">
			<div
				className="flex w-1/12 cursor-pointer items-center gap-2 self-start p-4 hover:text-uwgc-yellow"
				onClick={() => setSelectedStory(undefined)}
			>
				<ArrowLeftIcon className="h-5 w-5" />
				Back
			</div>
			<div className="flex h-14 items-center justify-center text-center text-3xl font-bold">
				{storyEngineState.story?.name}
			</div>
			<div className="w-full bg-black">
				<img className="w-full bg-black" src={imgUrl} alt="Snapshot"></img>
				<div
					className="h-1 w-full"
					style={{
						background:
							"linear-gradient(to right, #ff5700 0%, #ff5700 " +
							(storyEngineState.time / sectionDuration) * 100 +
							"%, #252a69 " +
							(storyEngineState.time / sectionDuration) * 100 +
							"%, #252a69 100%)",
					}}
				></div>
			</div>
			<div className="input-container w-full">
				<Slider
					type="range"
					min={1}
					max={frameLen}
					value={framePos}
					onChange={(e) => setTimeAtFramePos(Number(e.target.value))}
					style={{
						background:
							"linear-gradient(to right, #499248 0%, #499248 " +
							((framePos - 1) / (frameLen - 1)) * 100 +
							"%, #f5f5f5 " +
							((framePos - 1) / (frameLen - 1)) * 100 +
							"%, #f5f5f5 100%)",
					}}
				/>

				<div className="ticks mt-2 flex justify-between px-[10px]">
					{[...Array(frameLen)].map((f, i) => {
						return (
							<div key={i} className="spacing relative h-2 w-[2px] bg-black">
								<div className="absolute top-2 left-[-4px]">{i + 1}</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="mt-4 flex items-center gap-4">
				<div className="flex items-center gap-1">
					<CollectionIcon className="h-5 w-5 text-uwgc-orange" />
					<div>
						Frame: {framePos}/{frameLen}
					</div>
				</div>
				<div className="flex items-center gap-1">
					<ClockIcon className="h-5 w-5 text-uwgc-orange" />
					<div>
						Time: {storyEngineState.time.toFixed(2)}/{sectionDuration}
					</div>
				</div>
			</div>
			{/* <div>Select section</div> */}
			<div className="flex gap-2">
				{storyEngineState.story?.sections.map((section) => {
					return (
						<button
							key={section.id}
							className={
								"b-section " + (storyEngineState.section?.id === section.id ? "active " : " ")
							}
							onClick={() =>
								sendControlStoryCommand(
									createControlStoryCommandData(
										ControlStoryCommandDataTypes.SET_SECTION,
										section.id,
									),
								)
							}
						>
							{section.name}
						</button>
					);
				})}
			</div>
			{/* for dev to control time easily */}
			{env.NODE_ENV === "development" && (
				<>
					<Input
						type="range"
						min={0}
						max={sectionDuration}
						value={storyEngineState.time}
						onChange={(e) => {
							sendControlStoryCommand(
								createControlStoryCommandData(
									ControlStoryCommandDataTypes.SET_TIME,
									Number(e.target.value),
								),
							);
						}}
					/>
				</>
			)}
			<div className="mb-6 flex w-60 flex-col gap-4">
				<button
					onClick={() =>
						sendControlStoryCommand(
							createControlStoryCommandData(
								ControlStoryCommandDataTypes.SET_IS_PAUSED,
								!storyEngineState.isPaused,
							),
						)
					}
					className="flex h-9 items-center justify-center gap-4 rounded-md bg-green-500 p-2 font-bold text-white"
				>
					{storyEngineState.isPaused ? "PLAY" : "PAUSE"}
					{storyEngineState.isPaused ? (
						<PlayIcon className="h-5 w-5" />
					) : (
						<PauseIcon className="h-5 w-5" />
					)}
				</button>
				<button
					onClick={() =>
						sendControlStoryCommand(
							createControlStoryCommandData(ControlStoryCommandDataTypes.SET_STORY, undefined),
						)
					}
					className="flex h-9 items-center justify-center gap-4 rounded-md bg-uwgc-orange p-2 font-bold text-white"
				>
					STOP STORY
					<StopIcon className="h-5 w-5" />
				</button>

				<button
					onClick={() => {
						setIsLocked(!isLocked);
						sendSendOutControlWsData(
							createOutControlWsData(OutControlWsDataTypes.SET_IS_LOCKED, !isLocked),
						);
					}}
					className="flex h-9 items-center justify-center gap-4 rounded-md bg-uwgc-blue p-2 font-bold text-white"
				>
					{isLocked ? "UNLOCK DISPLAY" : "LOCK DISPLAY"}
					{isLocked ? (
						<LockClosedIcon className="h-5 w-5 text-uwgc-lightGray" />
					) : (
						<LockOpenIcon className="h-5 w-5 text-uwgc-lightGray" />
					)}
				</button>
			</div>
		</div>
	);
};

export default RunStory;
