import { ChangeEvent, useCallback, useEffect } from "react";
import { FiUpload } from "react-icons/fi";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { AssetFile } from "../../../../../../../../../shared/generated/graphql";
import useFiles from "../../../../../../../../../shared/hooks/useFiles";
import {
	selectedAssetFileTypeIdAtom,
	useAssetFilesFoldersReloader,
	useUploadAssetFileTags,
} from "../assetFiles.recoil";

interface Props {
	onUploadStart?: () => void;
	onUploadFinish?: (assetFile: AssetFile) => void;
}

const UploadAssetFileInput: React.FC<Props> = ({ onUploadStart, onUploadFinish }) => {
	const selectedAssetFileTypeId = useRecoilValue(selectedAssetFileTypeIdAtom);
	const { addAssetFile, addAssetFileQueryState } = useFiles();
	const { uploadAssetFileTags, resetUploadAssetFileTags } = useUploadAssetFileTags();
	const { reloadAssetFilesFolders } = useAssetFilesFoldersReloader();

	const _upload = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onUploadStart?.();
			void addAssetFile(e.target.files![0], selectedAssetFileTypeId, uploadAssetFileTags);
		},
		[onUploadStart, addAssetFile, selectedAssetFileTypeId, uploadAssetFileTags],
	);

	useEffect(() => {
		return () => {
			resetUploadAssetFileTags();
		};
	}, [resetUploadAssetFileTags]);

	useEffect(() => {
		if (
			addAssetFileQueryState.wasCalled &&
			!addAssetFileQueryState.isLoading &&
			addAssetFileQueryState.data
		) {
			reloadAssetFilesFolders();
			onUploadFinish?.(addAssetFileQueryState.data);
			toast.success("Uploaded Successfully!");
		} else if (addAssetFileQueryState.error != null) {
			toast.error("Upload error");
		}
	}, [
		addAssetFileQueryState.wasCalled,
		addAssetFileQueryState.isLoading,
		addAssetFileQueryState.data,
		onUploadFinish,
		reloadAssetFilesFolders,
		addAssetFileQueryState.error,
	]);

	return (
		<div className="uaf-input button green relative w-max">
			<label htmlFor="file">
				<span>{addAssetFileQueryState.isLoading ? "UPLOADING" : "UPLOAD"}</span>
			</label>
			{!addAssetFileQueryState.isLoading && (
				<input
					className="absolute h-full w-full cursor-pointer opacity-0"
					id="file"
					type="file"
					onChange={(e) => _upload(e)}
				/>
			)}
			<FiUpload className="h-5 w-5" />
		</div>
	);
};

export default UploadAssetFileInput;
