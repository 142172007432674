import { TrashIcon } from "@heroicons/react/outline";
import { useUpdateContainer } from "../../../story.recoil";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
	componentIndex: number;
}

const RemoveComponentButton: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
	componentIndex,
}) => {
	const { destroyComponent } = useUpdateContainer(sectionIndex, frameIndex, layerIndex);

	return (
		<button
			onClick={() => {
				destroyComponent(containerIndex, {
					filter: {
						index: componentIndex,
					},
				});
			}}
		>
			<TrashIcon className="w-5 h-5" />
		</button>
	);
};

export default RemoveComponentButton;
