import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import Input from "../../../../../../../../shared/components/input/Input";
import Table from "../../../../../../../../shared/components/table/Table";
import { useChartDataSetForm, useUpdateChartDataPoint } from "../../chartDataSets.recoil";
import AddChartDataPointButton from "./AddChartDataPointButton";

const ChartDataPointsTable: React.FC = () => {
	const {
		chartDataSetForm: { chartDataSet },
	} = useChartDataSetForm();
	const { updateChartDataPoint } = useUpdateChartDataPoint();

	return (
		<div className="px-4">
			<Table
				columns={[
					{
						name: "Name",
						formattedField: (row, i) => {
							return (
								<Input
									value={row.data.name ?? ""}
									onChange={(e) => {
										updateChartDataPoint({
											filter: {
												index: i,
											},
											fields: {
												name: e.target.value,
											},
										});
									}}
								/>
							);
						},
					},
					{
						name: "Value",
						formattedField: (row, i) => {
							return (
								<Input
									type={"number"}
									value={row.data.value ?? ""}
									onChange={(e) => {
										updateChartDataPoint({
											filter: {
												index: i,
											},
											fields: {
												value: parseFloat(e.target.value),
											},
										});
									}}
								/>
							);
						},
					},
					{
						name: "Color",
						formattedField: (row, i) => {
							return (
								<Input
									value={row.data.fill ?? ""}
									onChange={(e) => {
										updateChartDataPoint({
											filter: {
												index: i,
											},
											fields: {
												fill: e.target.value,
											},
										});
									}}
								/>
							);
						},
					},
				]}
				rows={chartDataSet.chartDataPoints.map((data) => {
					return {
						data,
					};
				})}
				className="stories"
				// pagination={{
				// 	pages,
				// 	currentPage,
				// 	onPageClick: (page: number) => {
				// 		setState({
				// 			currentPage: page,
				// 		});
				// 	},
				// }}
				onSort={(field, isDesc) => {
					if (field) {
						// setState({
						// 	orderBys: pushToOrderBys(orderBys, field, isDesc),
						// });
					}
				}}
				ascIcon={<BsChevronUp className="h-5 w-5" />}
				descIcon={<BsChevronDown className="h-5 w-5" />}
				extraRow={
					<tr>
						<td>
							<AddChartDataPointButton />
						</td>
						<td></td>
						<td></td>
					</tr>
				}
			/>
		</div>
	);
};

export default ChartDataPointsTable;
