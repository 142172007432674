import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { selectedAssetFilesModeAtom, selectedAssetFileTagAtom } from "./assetFiles.recoil";
import AssetFilesContent from "./assetFilesContent/AssetFilesContent";
import AssetFilesFolders from "./assetFilesFolders/AssetFilesFolders";
import AssetFilesHeader from "./assetFilesHeader/AssetFilesHeader";

const AssetFiles: React.FC = () => {
	const resetSelectedAssetFilesMode = useResetRecoilState(selectedAssetFilesModeAtom);
	const resetSelectedAssetFileTag = useResetRecoilState(selectedAssetFileTagAtom);
	const selectedAssetFileTag = useRecoilValue(selectedAssetFileTagAtom);

	useEffect(() => {
		return () => {
			resetSelectedAssetFilesMode();
		};
	}, [resetSelectedAssetFilesMode]);

	useEffect(() => {
		return () => {
			resetSelectedAssetFileTag();
		};
	}, [resetSelectedAssetFileTag]);

	return (
		<div>
			{selectedAssetFileTag !== undefined ? (
				<div>
					<AssetFilesHeader />
					<AssetFilesContent />
				</div>
			) : (
				<AssetFilesFolders />
			)}
		</div>
	);
};

export default AssetFiles;
