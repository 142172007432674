import { useEffect } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useRecoilState, useResetRecoilState } from "recoil";
import Input from "../../../../../../../shared/components/input/Input";
import Loading from "../../../../../../../shared/components/Loading/Loading";
import Table from "../../../../../../../shared/components/table/Table";
import { useGetChartDataSetsLazyQuery } from "../../../../../../../shared/generated/graphql";
import OpenChartDataSetFormButton from "../chartDataSetForm/OpenChartDataSetFormButton";
import { selectedChartDataSetAtom } from "../chartDataSets.recoil";

interface Props {
	isPicking?: boolean;
}

const ChartDataSetsTable: React.FC<Props> = ({ isPicking }) => {
	const [selectedChartDataSet, setSelectedChartDataSet] = useRecoilState(selectedChartDataSetAtom);
	const resetSelectedChartDataSet = useResetRecoilState(selectedChartDataSetAtom);

	const [getChartDataSets, getChartDataSetsRes] = useGetChartDataSetsLazyQuery({
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		return () => {
			resetSelectedChartDataSet();
		};
	}, [resetSelectedChartDataSet]);

	useEffect(() => {
		void getChartDataSets();
	}, [getChartDataSets]);

	const isLoading = getChartDataSetsRes.loading;
	const chartDataSets = getChartDataSetsRes.data?.getChartDataSets.rows || [];

	return (
		<div className="px-4">
			<Table
				columns={[
					isPicking
						? {
								name: "Select",
								formattedField: (row) => {
									return (
										<Input
											type={"checkbox"}
											checked={selectedChartDataSet?.id === row.data.id}
											onChange={() => {
												setSelectedChartDataSet(row.data);
											}}
										/>
									);
								},
						  }
						: undefined,
					{
						name: "Name",
						field: "name",
						formattedField: (row) => {
							// return (
							// 	<OpenChartDataSetFormButton chartDataSetId={row.data.id}>
							// 		{row.data.name}
							// 	</OpenChartDataSetFormButton>
							// );
							return <div>{row.data.name}</div>;
						},
					},
				]}
				rows={chartDataSets.map((data) => {
					return {
						data,
					};
				})}
				className="stories"
				// pagination={{
				// 	pages,
				// 	currentPage,
				// 	onPageClick: (page: number) => {
				// 		setState({
				// 			currentPage: page,
				// 		});
				// 	},
				// }}
				onSort={(field, isDesc) => {
					if (field) {
						// setState({
						// 	orderBys: pushToOrderBys(orderBys, field, isDesc),
						// });
					}
				}}
				ascIcon={<BsChevronUp className="h-5 w-5" />}
				descIcon={<BsChevronDown className="h-5 w-5" />}
				render={
					isLoading
						? () => {
								return <>{<Loading loadingType="lds-ring" />}</>;
						  }
						: undefined
				}
			/>
		</div>
	);
};

export default ChartDataSetsTable;
