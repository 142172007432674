import ChartDataSetsHeader from "./chartDataSetsHeader/ChartDataSetsHeader";
import ChartDataSetsTable from "./ChartDataSetsTable";

interface Props {
	isPicking?: boolean;
}

const ChartDataSetsPage: React.FC<Props> = ({ isPicking }) => {
	return (
		<div className="flex flex-col gap-4">
			<ChartDataSetsHeader />
			<ChartDataSetsTable isPicking={isPicking} />
		</div>
	);
};

export default ChartDataSetsPage;
