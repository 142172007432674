import ChartDataSets from "../../../../../chartDataSets/ChartDataSets";

const ChartDataSetPickerContent: React.FC = () => {
	return (
		<div>
			<ChartDataSets isPicking />
		</div>
	);
};

export default ChartDataSetPickerContent;
