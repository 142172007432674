import { atom } from "recoil";
import WsConnectionStatus from "../../../shared/types/WsConnectionStatus";
import PublicControlWs from "../../classes/PublicControlWs";

export const publicControlWsAtom = atom<PublicControlWs | undefined>({
	key: "publicControlWs",
	default: undefined,
	dangerouslyAllowMutability: true,
});

export const publicControlWsConnectionStatusAtom = atom<WsConnectionStatus>({
	key: "publicControlWsConnectionStatus",
	default: WsConnectionStatus.NOT_CONNECTED,
});
