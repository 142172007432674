import { XIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdDone } from "react-icons/md";
import { toast } from "react-toastify";
import Button from "../../../../../../../../../../shared/components/button/Button";
import Input from "../../../../../../../../../../shared/components/input/Input";
import { strToNumber } from "gate3-utils";
import { useBuilderModals } from "../../../../../builder.recoil";
import {
	useFrameTempForm,
	useUpdateFrame,
	useUpdateFrameTemp,
	useUpdateSection,
} from "../../../story.recoil";
import Select from "../../../../../../../../../../shared/components/select/Select";
import { COLOR_THEMES } from "../../../../../../../../../constants/Constants";

interface Props {
	sectionIndex: number;
}

const FrameForm: React.FC<Props> = ({ sectionIndex }) => {
	const formRef = useRef<HTMLFormElement>(null);

	const { popBuilderModal } = useBuilderModals();
	const [selectedLayout, setSelectedLayout] = useState<"a" | "b" | "c" | "d" | undefined>(
		undefined,
	);

	const {
		frameTempForm: { isDirty, frameIndex, frameTemp },
		resetFrameTempForm,
	} = useFrameTempForm();
	const { updateFrameTemp, updateFrameTempContainers } = useUpdateFrameTemp();
	const { insertFrame } = useUpdateSection();
	const { updateFrame } = useUpdateFrame(sectionIndex!);
	const [isRequiredError, setRequiredError] = useState(false);

	const _cancel = useCallback(() => {
		popBuilderModal();
	}, [popBuilderModal]);

	const _continue = useCallback(() => {
		if (!formRef.current || !formRef.current.checkValidity()) {
			toast.error("Save error");
			setRequiredError(true);
			return;
		}

		if (frameIndex !== undefined) {
			if (isDirty) {
				updateFrame({
					filter: {
						index: frameIndex,
					},
					fields: {
						...frameTemp,
					},
				});
			}
		} else {
			insertFrame(sectionIndex, {
				fields: {
					...frameTemp,
				},
			});
		}
		setRequiredError(false);
		popBuilderModal();
	}, [sectionIndex, popBuilderModal, frameIndex, isDirty, updateFrame, frameTemp, insertFrame]);

	useEffect(() => {
		return () => {
			resetFrameTempForm();
		};
	}, [resetFrameTempForm]);

	const isAdding = frameIndex === undefined;

	return (
		<div className="flex max-w-md flex-col gap-8">
			<form ref={formRef}>
				<div className="mb-8 flex flex-col items-center">
					<label>Duration (seconds)*</label>
					<Input
						required
						className="max-w-xs"
						type={"number"}
						min={0}
						value={frameTemp.duration ?? ""}
						onChange={(e) => {
							updateFrameTemp({
								fields: {
									duration: strToNumber(e.target.value),
								},
							});
						}}
					/>
				</div>
				<div>
					<label>Color Theme</label>
					<Select
						value={frameTemp.background ?? ""}
						onChange={(e) => {
							updateFrameTemp({
								fields: {
									background: e.target.value,
								},
							});
						}}
					>
						{[{ value: "", text: "" }, ...COLOR_THEMES].map((theme) => (
							<option key={theme.value} value={theme.value}>
								{theme.text}
							</option>
						))}
					</Select>
				</div>
				{isAdding && (
					<div className="flex flex-col gap-8">
						<div className="text-center">SELECT LAYOUT*</div>

						<div
							className="grid grid-cols-3 gap-2"
							onClick={() => {
								updateFrameTempContainers({
									containers: [
										{
											screenIndices: [0],
											delay: 0,
											components: [],
										},
										{
											screenIndices: [1],
											delay: 0,
											components: [],
										},
										{
											screenIndices: [2],
											delay: 0,
											components: [],
										},
									],
								});
								setSelectedLayout("a");
							}}
						>
							<div
								className={
									"col-span-1 h-11 rounded " +
									(selectedLayout === "a" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
								}
							></div>
							<div
								className={
									"col-span-1 h-11 rounded " +
									(selectedLayout === "a" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
								}
							></div>
							<div
								className={
									"col-span-1 h-11 rounded " +
									(selectedLayout === "a" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
								}
							></div>
						</div>
						<div
							className="grid grid-cols-3 gap-2"
							onClick={() => {
								updateFrameTempContainers({
									containers: [
										{
											screenIndices: [0],
											delay: 0,
											components: [],
										},
										{
											screenIndices: [1, 2],
											delay: 0,
											components: [],
										},
									],
								});
								setSelectedLayout("b");
							}}
						>
							<div
								className={
									"col-span-1 h-11 rounded " +
									(selectedLayout === "b" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
								}
							></div>
							<div
								className={
									"col-span-2 h-11 rounded " +
									(selectedLayout === "b" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
								}
							></div>
						</div>
						<div
							className="grid grid-cols-3 gap-2"
							onClick={() => {
								updateFrameTempContainers({
									containers: [
										{
											screenIndices: [0, 1],
											delay: 0,
											components: [],
										},
										{
											screenIndices: [2],
											delay: 0,
											components: [],
										},
									],
								});
								setSelectedLayout("c");
							}}
						>
							<div
								className={
									"col-span-2 h-11 rounded " +
									(selectedLayout === "c" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
								}
							></div>
							<div
								className={
									"col-span-1 h-11 rounded " +
									(selectedLayout === "c" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
								}
							></div>
						</div>
						<div
							className="grid grid-cols-3 gap-2"
							onClick={() => {
								updateFrameTempContainers({
									containers: [
										{
											screenIndices: [0, 1, 2],
											delay: 0,
											components: [],
										},
									],
								});
								setSelectedLayout("d");
							}}
						>
							<div
								className={
									"col-span-3 h-11 rounded " +
									(selectedLayout === "d" ? " bg-uwgc-midblue " : " bg-uwgc-slate ")
								}
							></div>
						</div>
					</div>
				)}
			</form>
			{isRequiredError && (
				<div className="text-uwgc-red">Please enter Duration and select Layout</div>
			)}
			{/* @TODO clicking LAYOUT is the continue */}
			<div className="flex justify-between gap-4">
				<Button className="red" onClick={() => _cancel()}>
					Cancel <XIcon className="h-5 w-5" />
				</Button>
				<Button className="blue" disabled={isAdding && !selectedLayout} onClick={() => _continue()}>
					Done <MdDone className="h-5 w-5" />
				</Button>
			</div>
		</div>
	);
};

export default FrameForm;
