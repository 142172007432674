import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { mapStateAtom } from "../../../../shared/views/map/map.recoil";
import { MapCommandDataTypes } from "../../../types/displayWs/inDisplayWsData/mapCommandData/MapCommandData";
import MapCommandData from "../../../types/displayWs/inDisplayWsData/mapCommandData/MapCommandData";
import { LatLngTuple } from "leaflet";
import MapLayerData from "../../../../shared/views/map/types/MapLayerData";
import MapDetailData from "../../../../shared/views/map/types/MapDetailData";

const useHandleMapCommandData = () => {
	const setMapState = useSetRecoilState(mapStateAtom);

	return useCallback((command: MapCommandData) => {
		switch (command.type) {
			case MapCommandDataTypes.FIT_LAT_LNGS:
				setMapState((state) => {
					return {
						...state,
						fitLatLngs: command.data as LatLngTuple[],
					};
				});
				break;
			case MapCommandDataTypes.SET_LAYER:
				setMapState((state) => {
					return {
						...state,
						layer: command.data as MapLayerData,
					};
				});
				break;
			case MapCommandDataTypes.SET_DETAIL:
				setMapState((state) => {
					return {
						...state,
						detail: command.data as MapDetailData,
					};
				});
				break;
			default: {
				console.error("Unknown MapCommandDataTypes");
			}
		}
	}, []);
};

export default useHandleMapCommandData;
