import { createBrowserHistory } from "history";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { PathName } from "../constants/PathName";
import PublicRoute from "../components/PublicRoute";
import Login from "./login/Login";
import PrivateRoute from "../components/PrivateRoute";
import Public from "./public/Public";
import Private from "./private/Private";
import "../styles/generic.scss";
import ShareStory from "./shareStory/ShareStory";

const history = createBrowserHistory();

const ControlMain: React.FC = () => {
	return (
		<div>
			<Router history={history}>
				<Switch>
					<Route exact path="/" render={() => <Redirect push to={PathName.DEFAULT} />} />
					<PublicRoute exact path={PathName.LOGIN} component={<Login />} />
					<Route exact path={PathName.UNKNOWN_ERROR}>
						An error has occured
					</Route>
					<Route exact path={PathName.UNAUTHORIZED}>
						Unauthorized
					</Route>
					<Route exact path={PathName.NOT_FOUND}>
						Not found
					</Route>
					<Route exact path={PathName.PUBLIC} component={Public} />
					<Route exact path={PathName.SHARE_STORY(":storyNameAndId")} component={ShareStory} />
					{/* Keep Private at the end of the Router */}
					<PrivateRoute path={"/"} component={<Private />} />
					<Route render={() => <Redirect push to={PathName.NOT_FOUND} />} />
				</Switch>
			</Router>
		</div>
	);
};

export default ControlMain;
