import StoryEngineState from "./StoryEngineState";

export enum OutDisplayWsDataTypes {
	STORY_ENGINE_STATE,
}

type OutDisplayWsDataTypeMap = {
	[OutDisplayWsDataTypes.STORY_ENGINE_STATE]: StoryEngineState;
};

interface OutDisplayWsData<T extends OutDisplayWsDataTypes = OutDisplayWsDataTypes> {
	type: T;
	data: OutDisplayWsDataTypeMap[T];
}

export const createOutDisplayWsData = <T extends OutDisplayWsDataTypes>(
	type: T,
	data: OutDisplayWsDataTypeMap[T],
): OutDisplayWsData<T> => {
	return {
		type,
		data,
	};
};

export default OutDisplayWsData;
