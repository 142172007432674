import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import {
	AddOrEditComponentInput,
	AddOrEditComponentsInput,
	AddOrEditContainerInput,
	AddOrEditContainersInput,
	AddOrEditFrameInput,
	AddOrEditFramesInput,
	AddOrEditLayerInput,
	AddOrEditLayersInput,
	AddOrEditSectionInput,
	AddOrEditSectionsInput,
	AssetFileFragment,
	ChartComponentPartial,
	ComponentPartial,
	ContainerPartial,
	EditChartComponentInput,
	EditHtmlComponentInput,
	EditImageComponentInput,
	EditVideoComponentInput,
	FramePartial,
	HtmlComponentPartial,
	HtmlComponentTypeIds,
	ImageComponentPartial,
	LayerPartial,
	SectionPartial,
	StoryPartial,
	StoryStatusIds,
	useAddStoryMutation,
	useEditStoryMutation,
	useRemoveStoryMutation,
	VideoComponentPartial,
} from "../../../../../../../shared/generated/graphql";
import { cloneArrPush, cloneArrSplice } from "gate3-utils";
import { COLOR_THEMES } from "../../../../../../constants/Constants";

export interface StoryForm {
	isDirty: boolean;
	story: StoryPartial;
}

export const storyFormAtom = atom<StoryForm>({
	key: "storyForm",
	default: {
		isDirty: false,
		story: {
			isFeatured: false,
			statusId: StoryStatusIds.InProgress,
			sections: [
				{
					name: "TITLE",
					frames: [
						{
							duration: 8,
							layers: [
								{
									containers: [
										{
											screenIndices: [0],
											delay: 0,
											components: [],
											inAnimation: "fadeIn",
											outAnimation: "fadeOut",
										},
										{
											screenIndices: [1],
											delay: 0,
											components: [],
											inAnimation: "fadeIn",
											outAnimation: "fadeOut",
										},
										{
											screenIndices: [2],
											delay: 0,
											components: [],
											inAnimation: "fadeIn",
											outAnimation: "fadeOut",
										},
									],
								},
								{
									containers: [
										{
											screenIndices: [0, 1, 2],
											delay: 3,
											components: [],
											inAnimation: "fadeIn",
											outAnimation: "fadeOut",
										},
									],
								},
							],
						},
					],
				},
			],
		},
	},
});

export type StoryFormPartial = Partial<StoryForm>;

export interface UpdateStoryFormInput {
	fields: StoryFormPartial;
}

export const useStoryForm = () => {
	const [storyForm, setStoryForm] = useRecoilState(storyFormAtom);
	const resetStoryForm = useResetRecoilState(storyFormAtom);

	const updateStoryForm = useCallback(
		(input: UpdateStoryFormInput) => {
			setStoryForm((state) => {
				return {
					...state,
					...input.fields,
				};
			});
		},
		[setStoryForm],
	);

	const initStory = useCallback(
		(story: StoryPartial) => {
			updateStoryForm({
				fields: {
					story,
				},
			});
		},
		[updateStoryForm],
	);

	return {
		storyForm,
		updateStoryForm,
		resetStoryForm,
		initStory,
	};
};

export interface UpdateStoryInput {
	fields?: StoryPartial;
	sections?: {
		insert?: InsertSectionInput;
		destroy?: DestroySectionInput;
		update?: UpdateSectionInput;
	};
}

export interface InsertSectionInput {
	fields: SectionPartial;
}

export interface DestroySectionInput {
	filter: {
		index: number;
	};
}

export interface UpdateSectionInput {
	filter: {
		index: number;
	};
	fields?: SectionPartial;
	frames?: {
		insert?: InsertFrameInput;
		destroy?: DestroyFrameInput;
		update?: UpdateFrameInput;
	};
}

export interface InsertFrameInput {
	fields: FramePartial;
}

export interface DestroyFrameInput {
	filter: {
		index: number;
	};
}

export interface UpdateFrameInput {
	filter: {
		index: number;
	};
	fields?: FramePartial;
	layers?: {
		insert?: InsertLayerInput;
		destroy?: DestroyLayerInput;
		update?: UpdateLayerInput;
	};
}

export interface InsertLayerInput {
	fields: LayerPartial;
}

export interface DestroyLayerInput {
	filter: {
		index: number;
	};
}

export interface UpdateLayerInput {
	filter: {
		index: number;
	};
	fields?: LayerPartial;
	containers?: {
		insert?: InsertContainerInput;
		destroy?: DestroyContainerInput;
		update?: UpdateContainerInput;
	};
}

export interface InsertContainerInput {
	fields: ContainerPartial;
}

export interface DestroyContainerInput {
	filter: {
		index: number;
	};
}

export interface UpdateContainerInput {
	filter: {
		index: number;
	};
	fields?: ContainerPartial;
	components?: {
		insert?: InsertComponentInput;
		destroy?: DestroyComponentInput;
		update?: UpdateComponentInput;
	};
}

export interface InsertComponentInput {
	fields: ComponentPartial;
}

export interface DestroyComponentInput {
	filter: {
		index: number;
	};
}

export interface UpdateComponentInput {
	filter: {
		index: number;
	};
	fields?: ComponentPartial;
}

const _useUpdateStoryInternal = () => {
	const setStoryForm = useSetRecoilState(storyFormAtom);

	const updateStoryInternal = useCallback(
		(input: UpdateStoryInput) => {
			setStoryForm((state) => {
				return {
					...state,
					isDirty: true,
					story: _updateStory(state.story, input),
				};
			});
		},
		[setStoryForm],
	);

	return {
		_setStoryValue: updateStoryInternal,
	};
};

const _updateStory = (story: StoryPartial, input: UpdateStoryInput) => {
	const storyClone: StoryPartial = {
		...story,
		...input.fields,
	};

	if (input.sections) {
		const sections = input.sections;
		if (sections.insert) {
			storyClone.sections = cloneArrPush(storyClone.sections, sections.insert.fields);
		}
		if (sections.destroy) {
			storyClone.sections = cloneArrSplice(storyClone.sections, sections.destroy.filter.index, 1);
		}
		if (sections.update) {
			storyClone.sections = cloneArrSplice(
				storyClone.sections,
				sections.update.filter.index,
				1,
				_updateSection(storyClone.sections![sections.update.filter.index], sections.update),
			);
		}
	}

	return storyClone;
};

const _updateSection = (section: SectionPartial, input: UpdateSectionInput) => {
	const sectionClone = {
		...section,
		...input.fields,
	};

	if (input.frames) {
		const frames = input.frames;
		if (frames.insert) {
			sectionClone.frames = cloneArrPush(sectionClone.frames, frames.insert.fields);
		}
		if (frames.destroy) {
			sectionClone.frames = cloneArrSplice(sectionClone.frames, frames.destroy.filter.index, 1);
		}
		if (frames.update) {
			sectionClone.frames = cloneArrSplice(
				sectionClone.frames,
				frames.update.filter.index,
				1,
				_updateFrame(sectionClone.frames![frames.update.filter.index], frames.update),
			);
		}
	}

	return sectionClone;
};

const _updateFrame = (frame: FramePartial, input: UpdateFrameInput) => {
	const frameClone = {
		...frame,
		...input.fields,
	};

	if (input.layers) {
		const layers = input.layers;
		if (layers.insert) {
			frameClone.layers = cloneArrPush(frameClone.layers, layers.insert.fields);
		}
		if (layers.destroy) {
			frameClone.layers = cloneArrSplice(frameClone.layers, layers.destroy.filter.index, 1);
		}
		if (layers.update) {
			frameClone.layers = cloneArrSplice(
				frameClone.layers,
				layers.update.filter.index,
				1,
				_updateLayer(frameClone.layers![layers.update.filter.index], layers.update),
			);
		}
	}

	return frameClone;
};

const _updateLayer = (layer: LayerPartial, input: UpdateLayerInput) => {
	const layerClone = {
		...layer,
		...input.fields,
	};

	if (input.containers) {
		const containers = input.containers;
		if (containers.insert) {
			layerClone.containers = cloneArrPush(layerClone.containers, containers.insert.fields);
		}
		if (containers.destroy) {
			layerClone.containers = cloneArrSplice(
				layerClone.containers,
				containers.destroy.filter.index,
				1,
			);
		}
		if (containers.update) {
			layerClone.containers = cloneArrSplice(
				layerClone.containers,
				containers.update.filter.index,
				1,
				_updateContainer(layerClone.containers![containers.update.filter.index], containers.update),
			);
		}
	}

	return layerClone;
};

const _updateContainer = (container: ContainerPartial, input: UpdateContainerInput) => {
	const containerClone = {
		...container,
		...input.fields,
	};

	if (input.components) {
		const components = input.components;
		if (components.insert) {
			containerClone.components = cloneArrPush(containerClone.components, components.insert.fields);
		}
		if (components.destroy) {
			containerClone.components = cloneArrSplice(
				containerClone.components,
				components.destroy.filter.index,
				1,
			);
		}
		if (components.update) {
			containerClone.components = cloneArrSplice(
				containerClone.components,
				components.update.filter.index,
				1,
				_updateComponent(
					containerClone.components![components.update.filter.index],
					components.update,
				),
			);
		}
	}

	return containerClone;
};

const _updateComponent = (component: ComponentPartial, input: UpdateComponentInput) => {
	const componentClone = {
		...component,
		...input.fields,
	};

	return componentClone;
};

export const useUpdateStory = () => {
	const { _setStoryValue } = _useUpdateStoryInternal();

	const updateStory = useCallback(
		(input: UpdateStoryInput) => {
			_setStoryValue(input);
		},
		[_setStoryValue],
	);

	const insertSection = useCallback(
		(input: InsertSectionInput) => {
			_setStoryValue({
				sections: {
					insert: input,
				},
			});
		},
		[_setStoryValue],
	);

	const destroySection = useCallback(
		(input: DestroySectionInput) => {
			_setStoryValue({
				sections: {
					destroy: input,
				},
			});
		},
		[_setStoryValue],
	);

	return {
		updateStory,
		insertSection,
		destroySection,
	};
};

export const useUpdateSection = () => {
	const { _setStoryValue } = _useUpdateStoryInternal();

	const updateSection = useCallback(
		(input: UpdateSectionInput) => {
			_setStoryValue({
				sections: {
					update: input,
				},
			});
		},
		[_setStoryValue],
	);

	const insertFrame = useCallback(
		(sectionIndex: number, input: InsertFrameInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							insert: input,
						},
					},
				},
			});
		},
		[_setStoryValue],
	);

	const destroyFrame = useCallback(
		(sectionIndex: number, input: DestroyFrameInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							destroy: input,
						},
					},
				},
			});
		},
		[_setStoryValue],
	);

	return {
		updateSection,
		insertFrame,
		destroyFrame,
	};
};

export const useUpdateFrame = (sectionIndex: number) => {
	const { _setStoryValue } = _useUpdateStoryInternal();

	const updateFrame = useCallback(
		(input: UpdateFrameInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: input,
						},
					},
				},
			});
		},
		[_setStoryValue, sectionIndex],
	);

	const insertLayer = useCallback(
		(frameIndex: number, input: InsertLayerInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									insert: input,
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, sectionIndex],
	);

	const destroyLayer = useCallback(
		(frameIndex: number, input: DestroyLayerInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									destroy: input,
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, sectionIndex],
	);

	return {
		updateFrame,
		insertLayer,
		destroyLayer,
	};
};

export const useUpdateLayer = (sectionIndex: number, frameIndex: number) => {
	const { _setStoryValue } = _useUpdateStoryInternal();

	const updateLayer = useCallback(
		(input: UpdateLayerInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									update: input,
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, frameIndex, sectionIndex],
	);

	const insertContainer = useCallback(
		(layerIndex: number, input: InsertContainerInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									update: {
										filter: {
											index: layerIndex,
										},
										containers: {
											insert: input,
										},
									},
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, frameIndex, sectionIndex],
	);

	const destroyContainer = useCallback(
		(layerIndex: number, input: DestroyContainerInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									update: {
										filter: {
											index: layerIndex,
										},
										containers: {
											destroy: input,
										},
									},
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, frameIndex, sectionIndex],
	);

	return {
		updateLayer,
		insertContainer,
		destroyContainer,
	};
};

export const useUpdateContainer = (
	sectionIndex: number,
	frameIndex: number,
	layerIndex: number,
) => {
	const { _setStoryValue } = _useUpdateStoryInternal();

	const updateContainer = useCallback(
		(input: UpdateContainerInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									update: {
										filter: {
											index: layerIndex,
										},
										containers: {
											update: input,
										},
									},
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, frameIndex, layerIndex, sectionIndex],
	);

	const insertComponent = useCallback(
		(containerIndex: number, input: InsertComponentInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									update: {
										filter: {
											index: layerIndex,
										},
										containers: {
											update: {
												filter: {
													index: containerIndex,
												},
												components: {
													insert: input,
												},
											},
										},
									},
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, frameIndex, layerIndex, sectionIndex],
	);

	const destroyComponent = useCallback(
		(containerIndex: number, input: DestroyComponentInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									update: {
										filter: {
											index: layerIndex,
										},
										containers: {
											update: {
												filter: {
													index: containerIndex,
												},
												components: {
													destroy: input,
												},
											},
										},
									},
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, frameIndex, layerIndex, sectionIndex],
	);

	return {
		updateContainer,
		insertComponent,
		destroyComponent,
	};
};

export const useUpdateComponent = (
	sectionIndex: number,
	frameIndex: number,
	layerIndex: number,
	containerIndex: number,
) => {
	const { _setStoryValue } = _useUpdateStoryInternal();

	const updateComponent = useCallback(
		(input: UpdateComponentInput) => {
			_setStoryValue({
				sections: {
					update: {
						filter: {
							index: sectionIndex,
						},
						frames: {
							update: {
								filter: {
									index: frameIndex,
								},
								layers: {
									update: {
										filter: {
											index: layerIndex,
										},
										containers: {
											update: {
												filter: {
													index: containerIndex,
												},
												components: {
													update: input,
												},
											},
										},
									},
								},
							},
						},
					},
				},
			});
		},
		[_setStoryValue, containerIndex, frameIndex, layerIndex, sectionIndex],
	);

	return {
		updateComponent,
	};
};

// hardcoded section
export const TITLE_SECTION_VIDEO_LAYER_INDEX = 0;
export const TITLE_SECTION_TITLE_LAYER_INDEX = 1;

export const useUpdateTitleSection = () => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const { updateContainer: updateVideoLayerContainer, insertComponent: insertVideoLayerComponent } =
		useUpdateContainer(0, 0, TITLE_SECTION_VIDEO_LAYER_INDEX);

	const { insertComponent: insertTitleLayerComponent } = useUpdateContainer(
		0,
		0,
		TITLE_SECTION_TITLE_LAYER_INDEX,
	);
	const { updateComponent: updateTitleLayerComponent } = useUpdateComponent(
		0,
		0,
		TITLE_SECTION_TITLE_LAYER_INDEX,
		0,
	);

	const insertOrUpdateTitleSectionVideoComponent = useCallback(
		(containerIndex: number, assetFile: AssetFileFragment) => {
			const components =
				story.sections![0].frames![0].layers![TITLE_SECTION_VIDEO_LAYER_INDEX].containers![
					containerIndex
				].components;
			if (components!.length !== 0) {
				updateVideoLayerContainer({
					filter: {
						index: containerIndex,
					},
					components: {
						update: {
							filter: {
								index: 0,
							},
							fields: {
								...components,
								videoComponents: [
									{
										assetFile,
									},
								],
							},
						},
					},
				});
			} else {
				insertVideoLayerComponent(containerIndex, {
					fields: {
						videoComponents: [
							{
								assetFile,
							},
						],
						imageComponents: [],
						htmlComponents: [],
						chartComponents: [],
					},
				});
			}
		},
		[insertVideoLayerComponent, story.sections, updateVideoLayerContainer],
	);

	const insertOrUpdateTitleSectionTitleComponent = useCallback(
		(title: string) => {
			const components =
				story.sections![0].frames![0].layers![TITLE_SECTION_TITLE_LAYER_INDEX].containers![0]
					.components;
			// @TODO style title
			const titleHtml = `<div class="title">${title}</div>`;

			if (components!.length !== 0) {
				updateTitleLayerComponent({
					filter: {
						index: 0,
					},
					fields: {
						htmlComponents: [
							{
								typeId: HtmlComponentTypeIds.Html,
								html: titleHtml,
							},
						],
						imageComponents: [],
						videoComponents: [],
						chartComponents: [],
					},
				});
			} else {
				insertTitleLayerComponent(0, {
					fields: {
						htmlComponents: [
							{
								typeId: HtmlComponentTypeIds.Html,
								html: titleHtml,
							},
						],
						imageComponents: [],
						videoComponents: [],
						chartComponents: [],
					},
				});
			}
		},
		[insertTitleLayerComponent, story.sections, updateTitleLayerComponent],
	);

	return {
		insertOrUpdateTitleSectionVideoComponent,
		insertOrUpdateTitleSectionTitleComponent,
	};
};

const saveStoryResAtom = atom<{
	isCalled: boolean;
	isSaving: boolean;
	isRequiredError: boolean;
	addStoryData?: {
		storyId: number;
	};
	shouldRedirectToSections: boolean;
	error?: any;
}>({
	key: "saveStory",
	default: {
		isCalled: false,
		isSaving: false,
		isRequiredError: false,
		shouldRedirectToSections: false,
	},
});

export const useSaveStory = () => {
	const {
		storyForm: { story },
		updateStoryForm,
	} = useStoryForm();
	const [saveStoryRes, setSaveStoryRes] = useRecoilState(saveStoryResAtom);
	const resetSaveStoryRes = useResetRecoilState(saveStoryResAtom);

	const [addStory, addStoryRes] = useAddStoryMutation();
	const [editStory, editStoryRes] = useEditStoryMutation();

	const saveStory = useCallback(
		async (form?: HTMLFormElement, shouldRedirectToSections: boolean = false) => {
			if (form && !form.checkValidity()) {
				setSaveStoryRes((state) => {
					return {
						...state,
						isRequiredError: true,
					};
				});
				return;
			}

			setSaveStoryRes((state) => {
				return {
					...state,
					isCalled: true,
					isSaving: true,
					isRequiredError: false,
					addStoryData: undefined,
					shouldRedirectToSections,
					error: undefined,
				};
			});

			const getEditHtmlComponent = (
				htmlComponent: HtmlComponentPartial,
			): EditHtmlComponentInput => {
				return {
					fields: {
						html: htmlComponent.html!,
						typeId: htmlComponent.typeId!,
					},
				};
			};

			const getEditImageComponent = (
				imageComponent: ImageComponentPartial,
			): EditImageComponentInput => {
				return {
					fields: {
						assetFileId: imageComponent.assetFile!.id!,
					},
				};
			};

			const getEditVideoComponent = (
				videoComponent: VideoComponentPartial,
			): EditVideoComponentInput => {
				return {
					fields: {
						assetFileId: videoComponent.assetFile!.id!,
						isMuted: videoComponent.isMuted ?? null,
						shouldBgAudioNotFadeInAfterVideo:
							videoComponent.shouldBgAudioNotFadeInAfterVideo ?? null,
						shouldBgAudioNotFadeOutBeforeVideo:
							videoComponent.shouldBgAudioNotFadeOutBeforeVideo ?? null,
					},
				};
			};

			const getEditChartComponent = (
				chartComponent: ChartComponentPartial,
			): EditChartComponentInput => {
				return {
					fields: {
						chartDataSetId: chartComponent.chartDataSet!.id!,
						typeId: chartComponent.typeId!,
						specialChartId: chartComponent.specialChartId ?? null,
					},
				};
			};

			const getAddOrEditComponents = (
				container: ContainerPartial,
			): AddOrEditComponentsInput | undefined => {
				return {
					inputs:
						container.components?.map((component): AddOrEditComponentInput => {
							return {
								filter:
									component.id !== undefined
										? {
												id: component.id,
										  }
										: undefined,
								fields: {
									height: component.height ?? null,
									width: component.width ?? null,
								},
								editHtmlComponent:
									component.htmlComponents && component.htmlComponents.length !== 0
										? getEditHtmlComponent(component.htmlComponents[0])
										: undefined,

								editImageComponent:
									component.imageComponents && component.imageComponents.length !== 0
										? getEditImageComponent(component.imageComponents[0])
										: undefined,

								editVideoComponent:
									component.videoComponents && component.videoComponents.length !== 0
										? getEditVideoComponent(component.videoComponents[0])
										: undefined,

								editChartComponent:
									component.chartComponents && component.chartComponents.length !== 0
										? getEditChartComponent(component.chartComponents[0])
										: undefined,
							};
						}) || [],
				};
			};

			const getAddOrEditContainers = (
				layer: LayerPartial,
			): AddOrEditContainersInput | undefined => {
				return {
					inputs:
						layer.containers?.map((container): AddOrEditContainerInput => {
							return {
								filter:
									container.id !== undefined
										? {
												id: container.id,
										  }
										: undefined,
								fields: {
									delay: container.delay!,
									screenIndices: container.screenIndices!,
									background: container.background ?? null,
									inAnimation: container.inAnimation ?? null,
									outAnimation: container.outAnimation ?? null,
								},
								addOrEditComponents: getAddOrEditComponents(container),
							};
						}) || [],
				};
			};

			const getAddOrEditLayers = (frame: FramePartial): AddOrEditLayersInput | undefined => {
				return {
					inputs:
						frame.layers?.map((layer): AddOrEditLayerInput => {
							return {
								filter:
									layer.id !== undefined
										? {
												id: layer.id,
										  }
										: undefined,
								fields: {
									inAnimation: layer.inAnimation ?? null,
									outAnimation: layer.outAnimation ?? null,
								},
								addOrEditContainers: getAddOrEditContainers(layer),
							};
						}) || [],
				};
			};

			const getAddOrEditFrames = (section: SectionPartial): AddOrEditFramesInput | undefined => {
				return {
					inputs:
						section.frames?.map((frame): AddOrEditFrameInput => {
							return {
								filter:
									frame.id !== undefined
										? {
												id: frame.id,
										  }
										: undefined,
								fields: {
									duration: frame.duration!,
									background: frame.background ?? null,
									inAnimation: frame.inAnimation ?? null,
									outAnimation: frame.outAnimation ?? null,
								},
								addOrEditLayers: getAddOrEditLayers(frame),
							};
						}) || [],
				};
			};

			const getAddOrEditSections = (): AddOrEditSectionsInput | undefined => {
				return {
					inputs:
						story.sections?.map((section): AddOrEditSectionInput => {
							return {
								filter:
									section.id !== undefined
										? {
												id: section.id,
										  }
										: undefined,
								fields: {
									name: section.name!,
									bgAudioAssetFileId: section.bgAudioAssetFile?.id ?? null,
									background: section.background ?? null,
								},
								addOrEditFrames: getAddOrEditFrames(section),
							};
						}) || [],
				};
			};

			if (story.id !== undefined) {
				void editStory({
					variables: {
						input: {
							filter: {
								id: story.id,
							},
							fields: {
								isFeatured: story.isFeatured!,
								name: story.name!,
								statusId: story.statusId!,
								topic: story.topic!,
								orderNum: story.orderNum ?? null,
								coverImageAssetFileId: story.coverImageAssetFile?.id ?? null,
								description: story.description ?? null,
							},
							addOrEditSections: getAddOrEditSections(),
						},
					},
				});
			} else {
				void addStory({
					variables: {
						input: {
							fields: {
								isFeatured: story.isFeatured!,
								name: story.name!,
								statusId: story.statusId!,
								topic: story.topic!,
								orderNum: story.orderNum ?? null,
								coverImageAssetFileId: story.coverImageAssetFile?.id ?? null,
								description: story.description ?? null,
							},
							addOrEditSections: getAddOrEditSections(),
						},
					},
				});
			}
		},
		[story, setSaveStoryRes, addStory, editStory],
	);

	useEffect(() => {
		return () => {
			resetSaveStoryRes();
		};
	}, [resetSaveStoryRes]);

	useEffect(() => {
		if (addStoryRes.called && !addStoryRes.loading) {
			setSaveStoryRes((state) => {
				return {
					...state,
					isSaving: false,
					addStoryData: addStoryRes.data
						? {
								storyId: addStoryRes.data.addStories.rows[0].id!,
						  }
						: undefined,
					error: addStoryRes.error,
				};
			});
		}
	}, [
		addStoryRes.called,
		addStoryRes.loading,
		addStoryRes.data,
		setSaveStoryRes,
		addStoryRes.error,
	]);

	useEffect(() => {
		if (editStoryRes.called && !editStoryRes.loading) {
			setSaveStoryRes((state) => {
				return {
					...state,
					isSaving: false,
					error: editStoryRes.error,
				};
			});
		}
	}, [editStoryRes.called, editStoryRes.error, editStoryRes.loading, setSaveStoryRes]);

	useEffect(() => {
		if (saveStoryRes.isCalled && !saveStoryRes.isSaving && !saveStoryRes.error) {
			updateStoryForm({
				fields: {
					isDirty: false,
				},
			});
		}
	}, [saveStoryRes.error, saveStoryRes.isCalled, saveStoryRes.isSaving, updateStoryForm]);

	return {
		saveStory,
		saveStoryRes,
	};
};

export const useDeleteStory = () => {
	const [deleteStoryRes, setDeleteStoryRes] = useState<{
		isCalled: boolean;
		isLoading: boolean;
		error?: any;
	}>({
		isCalled: false,
		isLoading: false,
	});

	const {
		storyForm: { story },
	} = useStoryForm();

	const [removeStory, removeStoryRes] = useRemoveStoryMutation();

	const deleteStory = useCallback(() => {
		if (story.id !== undefined) {
			setDeleteStoryRes((state) => {
				return {
					...state,
					isCalled: true,
					isLoading: true,
					error: undefined,
				};
			});

			void removeStory({
				variables: {
					input: {
						filter: {
							id: story.id,
						},
					},
				},
			});
		}
	}, [removeStory, story.id]);

	useEffect(() => {
		if (removeStoryRes.called && !removeStoryRes.loading) {
			if (removeStoryRes.data) {
				setDeleteStoryRes((state) => {
					return {
						...state,
						isLoading: false,
					};
				});
			} else if (removeStoryRes.error) {
				setDeleteStoryRes((state) => {
					return {
						...state,
						isLoading: false,
						error: removeStoryRes.error,
					};
				});
			}
		}
	}, [removeStoryRes.called, removeStoryRes.data, removeStoryRes.error, removeStoryRes.loading]);

	return {
		deleteStory,
		deleteStoryRes,
	};
};

// Section

export interface SectionTempForm {
	isDirty: boolean;
	sectionIndex?: number;
	sectionTemp: SectionPartial;
	bgAudioFile?: File;
}

export const sectionTempFormAtom = atom<SectionTempForm>({
	key: "sectionTempForm",
	default: {
		isDirty: false,
		sectionTemp: {
			background: COLOR_THEMES[0].value,
			frames: [],
		},
	},
});

export type SectionTempFormPartial = Partial<SectionTempForm>;

export interface UpdateSectionTempFormInput {
	fields: SectionTempFormPartial;
}

export const useSectionTempForm = () => {
	const [sectionTempForm, setSectionTempForm] = useRecoilState(sectionTempFormAtom);
	const resetSectionTempForm = useResetRecoilState(sectionTempFormAtom);

	const updateSectionTempForm = useCallback(
		(input: UpdateSectionTempFormInput) => {
			setSectionTempForm((state) => {
				return {
					...state,
					...input.fields,
				};
			});
		},
		[setSectionTempForm],
	);

	return {
		sectionTempForm,
		updateSectionTempForm,
		resetSectionTempForm,
	};
};

export interface UpdateSectionTempInput {
	fields: SectionPartial;
}

export const useUpdateSectionTemp = () => {
	const setSectionTempForm = useSetRecoilState(sectionTempFormAtom);

	const updateSectionTemp = useCallback(
		(input: UpdateSectionTempInput) => {
			setSectionTempForm((state) => {
				const sectionTempTemp: SectionPartial = {
					...state.sectionTemp,
					...input.fields,
				};

				return {
					...state,
					isDirty: true,
					sectionTemp: sectionTempTemp,
				};
			});
		},
		[setSectionTempForm],
	);

	return {
		updateSectionTemp,
	};
};

// Frame

export interface FrameTempForm {
	isDirty: boolean;
	frameIndex?: number;
	frameTemp: FramePartial;
}

export const frameTempFormAtom = atom<FrameTempForm>({
	key: "frameTempForm",
	default: {
		isDirty: false,
		frameTemp: {
			layers: [],
		},
	},
});

export type FrameTempFormPartial = Partial<FrameTempForm>;

export interface UpdateFrameTempFormInput {
	fields: FrameTempFormPartial;
}

export const useFrameTempForm = () => {
	const [frameTempForm, setFrameTempForm] = useRecoilState(frameTempFormAtom);
	const resetFrameTempForm = useResetRecoilState(frameTempFormAtom);

	const updateFrameTempForm = useCallback(
		(input: UpdateFrameTempFormInput) => {
			setFrameTempForm((state) => {
				return {
					...state,
					...input.fields,
				};
			});
		},
		[setFrameTempForm],
	);

	return {
		frameTempForm,
		updateFrameTempForm,
		resetFrameTempForm,
	};
};

export interface UpdateFrameTempInput {
	fields: FramePartial;
}

export interface UpdateFrameTempContainersInput {
	containers: ContainerPartial[];
}

export const useUpdateFrameTemp = () => {
	const setFrameTempForm = useSetRecoilState(frameTempFormAtom);

	const updateFrameTemp = useCallback(
		(input: UpdateFrameTempInput) => {
			setFrameTempForm((state) => {
				const frameTempTemp: FramePartial = {
					...state.frameTemp,
					...input.fields,
				};

				return {
					...state,
					isDirty: true,
					frameTemp: frameTempTemp,
				};
			});
		},
		[setFrameTempForm],
	);

	const updateFrameTempContainers = useCallback(
		(input: UpdateFrameTempContainersInput) => {
			updateFrameTemp({
				fields: {
					layers: [
						{
							containers: input.containers,
						},
					],
				},
			});
		},
		[updateFrameTemp],
	);

	return {
		updateFrameTemp,
		updateFrameTempContainers,
	};
};

export const ALL_LAYERS_INDEX = -1;

// Layer

export interface LayerTempForm {
	isDirty: boolean;
	layerIndex?: number;
	layerTemp: LayerPartial;
}

export const layerTempFormAtom = atom<LayerTempForm>({
	key: "layerTempForm",
	default: {
		isDirty: false,
		layerTemp: {
			containers: [],
		},
	},
});

export type LayerTempFormPartial = Partial<LayerTempForm>;

export interface UpdateLayerTempFormInput {
	fields: LayerTempFormPartial;
}

export const useLayerTempForm = () => {
	const [layerTempForm, setLayerTempForm] = useRecoilState(layerTempFormAtom);
	const resetLayerTempForm = useResetRecoilState(layerTempFormAtom);

	const updateLayerTempForm = useCallback(
		(input: UpdateLayerTempFormInput) => {
			setLayerTempForm((state) => {
				return {
					...state,
					...input.fields,
				};
			});
		},
		[setLayerTempForm],
	);

	return {
		layerTempForm,
		updateLayerTempForm,
		resetLayerTempForm,
	};
};

export interface UpdateLayerTempInput {
	fields: LayerPartial;
}

export interface UpdateLayerTempContainersInput {
	containers: ContainerPartial[];
}

export const useUpdateLayerTemp = () => {
	const setLayerTempForm = useSetRecoilState(layerTempFormAtom);

	const updateLayerTemp = useCallback(
		(input: UpdateLayerTempInput) => {
			setLayerTempForm((state) => {
				const layerTempTemp: LayerPartial = {
					...state.layerTemp,
					...input.fields,
				};

				return {
					...state,
					isDirty: true,
					layerTemp: layerTempTemp,
				};
			});
		},
		[setLayerTempForm],
	);

	return {
		updateLayerTemp,
	};
};

// Container

export interface ContainerTempForm {
	isDirty: boolean;
	containerIndex?: number;
	containerTemp: ContainerPartial;
}

export const containerTempFormAtom = atom<ContainerTempForm>({
	key: "containerTempForm",
	default: {
		isDirty: false,
		containerTemp: {
			components: [],
		},
	},
});

export type ContainerTempFormPartial = Partial<ContainerTempForm>;

export interface UpdateContainerTempFormInput {
	fields: ContainerTempFormPartial;
}

export const useContainerTempForm = () => {
	const [containerTempForm, setContainerTempForm] = useRecoilState(containerTempFormAtom);
	const resetContainerTempForm = useResetRecoilState(containerTempFormAtom);

	const updateContainerTempForm = useCallback(
		(input: UpdateContainerTempFormInput) => {
			setContainerTempForm((state) => {
				return {
					...state,
					...input.fields,
				};
			});
		},
		[setContainerTempForm],
	);

	return {
		containerTempForm,
		updateContainerTempForm,
		resetContainerTempForm,
	};
};

export interface UpdateContainerTempInput {
	fields: ContainerPartial;
}

export interface UpdateContainerTempContainersInput {
	containers: ContainerPartial[];
}

export const useUpdateContainerTemp = () => {
	const setContainerTempForm = useSetRecoilState(containerTempFormAtom);

	const updateContainerTemp = useCallback(
		(input: UpdateContainerTempInput) => {
			setContainerTempForm((state) => {
				const containerTempTemp: ContainerPartial = {
					...state.containerTemp,
					...input.fields,
				};

				return {
					...state,
					isDirty: true,
					containerTemp: containerTempTemp,
				};
			});
		},
		[setContainerTempForm],
	);

	return {
		updateContainerTemp,
	};
};

// Component

export interface ComponentTempForm {
	isDirty: boolean;
	componentIndex?: number;
	componentTemp: ComponentPartial;
}

export const componentTempFormAtom = atom<ComponentTempForm>({
	key: "componentTempForm",
	default: {
		isDirty: false,
		componentTemp: {
			htmlComponents: [
				{
					typeId: HtmlComponentTypeIds.Text,
					html: JSON.stringify([
						{
							type: "paragaph",
							children: [
								{
									type: "paddingBlock",
									children: [
										{
											text: "",
											fontSize: "paragraphs",
											paddingBlock: true,
										},
									],
								},
							],
						},
					]),
				},
			],
			imageComponents: [],
			videoComponents: [],
			chartComponents: [],
		},
	},
});

export type ComponentTempFormPartial = Partial<ComponentTempForm>;

export interface UpdateComponentTempFormInput {
	fields: ComponentTempFormPartial;
}

export const useComponentTempForm = () => {
	const [componentTempForm, setComponentTempForm] = useRecoilState(componentTempFormAtom);
	const resetComponentTempForm = useResetRecoilState(componentTempFormAtom);

	const updateComponentTempForm = useCallback(
		(input: UpdateComponentTempFormInput) => {
			setComponentTempForm((state) => {
				return {
					...state,
					...input.fields,
				};
			});
		},
		[setComponentTempForm],
	);

	return {
		componentTempForm,
		updateComponentTempForm,
		resetComponentTempForm,
	};
};

export interface UpdateComponentTempInput {
	fields: ComponentPartial;
}

export interface UpdateHtmlComponentTempInput {
	fields: HtmlComponentPartial;
}

export interface UpdateImageComponentTempInput {
	fields: ImageComponentPartial;
}

export interface UpdateVideoComponentTempInput {
	fields: VideoComponentPartial;
}

export interface UpdateChartComponentTempInput {
	fields: ChartComponentPartial;
}

export interface UpdateComponentTempContainersInput {
	containers: ContainerPartial[];
}

export const useUpdateComponentTemp = () => {
	const setComponentTempForm = useSetRecoilState(componentTempFormAtom);

	const updateComponentTemp = useCallback(
		(input: UpdateComponentTempInput) => {
			setComponentTempForm((state) => {
				const componentTempTemp: ComponentPartial = {
					...state.componentTemp,
					...input.fields,
				};

				return {
					...state,
					isDirty: true,
					componentTemp: componentTempTemp,
				};
			});
		},
		[setComponentTempForm],
	);

	const updateHtmlComponentTemp = useCallback(
		(input: UpdateHtmlComponentTempInput) => {
			setComponentTempForm((state) => {
				const componentTempTemp: ComponentPartial = {
					...state.componentTemp,
					htmlComponents: [
						{
							...state.componentTemp!.htmlComponents![0],
							...input.fields,
						},
					],
				};

				return {
					...state,
					isDirty: true,
					componentTemp: componentTempTemp,
				};
			});
		},
		[setComponentTempForm],
	);

	const updateImageComponentTemp = useCallback(
		(input: UpdateImageComponentTempInput) => {
			setComponentTempForm((state) => {
				const componentTempTemp: ComponentPartial = {
					...state.componentTemp,
					imageComponents: [
						{
							...state.componentTemp!.imageComponents![0],
							...input.fields,
						},
					],
				};

				return {
					...state,
					isDirty: true,
					componentTemp: componentTempTemp,
				};
			});
		},
		[setComponentTempForm],
	);

	const updateVideoComponentTemp = useCallback(
		(input: UpdateVideoComponentTempInput) => {
			setComponentTempForm((state) => {
				const { bgAudioFadeIn, bgAudioFadeOut, ...strippedFields } = input.fields;

				const videoComponentClone = {
					...state.componentTemp!.videoComponents![0],
					...strippedFields,
				};

				if (bgAudioFadeIn) {
					videoComponentClone.bgAudioFadeIn = {
						...videoComponentClone.bgAudioFadeIn,
						...bgAudioFadeIn,
					};
				}

				if (bgAudioFadeOut) {
					videoComponentClone.bgAudioFadeOut = {
						...videoComponentClone.bgAudioFadeOut,
						...bgAudioFadeOut,
					};
				}

				const componentTempTemp: ComponentPartial = {
					...state.componentTemp,
					videoComponents: [videoComponentClone],
				};

				return {
					...state,
					isDirty: true,
					componentTemp: componentTempTemp,
				};
			});
		},
		[setComponentTempForm],
	);

	const updateChartComponentTemp = useCallback(
		(input: UpdateChartComponentTempInput) => {
			setComponentTempForm((state) => {
				const componentTempTemp: ComponentPartial = {
					...state.componentTemp,
					chartComponents: [
						{
							...state.componentTemp!.chartComponents![0],
							...input.fields,
						},
					],
				};

				return {
					...state,
					isDirty: true,
					componentTemp: componentTempTemp,
				};
			});
		},
		[setComponentTempForm],
	);

	return {
		updateComponentTemp,
		updateHtmlComponentTemp,
		updateImageComponentTemp,
		updateVideoComponentTemp,
		updateChartComponentTemp,
	};
};

enum ContentTypes {
	Text = "Text",
	Image = "Single Image",
	Video = "Video",
	Chart = "Chart",
	Html = "HTML",
}

export const getComponentContentType = (component: ComponentPartial) => {
	if (component.htmlComponents && component.htmlComponents.length !== 0) {
		if (component.htmlComponents[0].typeId === HtmlComponentTypeIds.Text) {
			return ContentTypes.Text;
		} else {
			return ContentTypes.Html;
		}
	} else if (component.imageComponents && component.imageComponents.length !== 0) {
		return ContentTypes.Image;
	} else if (component.videoComponents && component.videoComponents.length !== 0) {
		return ContentTypes.Video;
	} else if (component.chartComponents && component.chartComponents.length !== 0) {
		return ContentTypes.Chart;
	} else {
		return ContentTypes.Text;
	}
};
