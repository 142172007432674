import React from "react";

interface Props {
	message: string | JSX.Element;
}

const Tooltip: React.FC<Props> = ({ message, children }) => {
	return (
		<div className="relative flex flex-col items-center group">
			{children}
			<div className="absolute bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
				<span className="relative z-10 p-6 text-base leading-none text-[#4B4F58] whitespace-no-wrap bg-[#B0C5E4] rounded-md">
					{message}
				</span>
				<div className="w-3 h-3 -mt-2 rotate-45 bg-[#B0C5E4]"></div>
			</div>
		</div>
	);
};

export default Tooltip;
