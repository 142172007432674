import SaveStoryButton from "../SaveStoryButton";
import CloseSectionsLink from "./CloseSectionsLink";
import AddSectionButton from "./sectionForm/AddSectionButton";
import SectionsList from "./sectionsList/SectionsList";

const Sections: React.FC = () => {
	return (
		<div className="p-4">
			<SectionsList />
			<div className="flex flex-col justify-center items-center gap-5">
				<div className="blue-dashed w-full flex justify-center">
					<AddSectionButton />
				</div>
				<div className="flex gap-7">
					<CloseSectionsLink />
					<SaveStoryButton />
				</div>
			</div>
		</div>
	);
};

export default Sections;
