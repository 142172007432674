import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import StoryEngineStateSender from "../../classes/StoryEngineStateSender";
import {
	isControlConnectedAtom,
	displayWsAtom,
	displayWsConnectionStatusAtom,
} from "./displayWs.recoil";
import { useStoryEngine } from "../../../shared/state/storyEngine/storyEngine.recoil";
import WsConnectionStatus from "../../../shared/types/WsConnectionStatus";

const useStoryEngineStateSender = () => {
	const displayWs = useRecoilValue(displayWsAtom);
	const displayWsConnectionStatus = useRecoilValue(displayWsConnectionStatusAtom);
	const isControlConnected = useRecoilValue(isControlConnectedAtom);
	const { storyEngine } = useStoryEngine();

	const [storyEngineStateSender, setStoryEngineStateSender] = useState<
		StoryEngineStateSender | undefined
	>();

	useEffect(() => {
		if (displayWs && storyEngine) {
			if (!storyEngineStateSender) {
				setStoryEngineStateSender(new StoryEngineStateSender(displayWs, storyEngine));
			}
		} else {
			if (storyEngineStateSender) {
				storyEngineStateSender.isStopped = true;
				setStoryEngineStateSender(undefined);
			}
		}

		return () => {
			if (storyEngineStateSender) {
				storyEngineStateSender.isStopped = true;
			}
		};
	}, [displayWs, storyEngine, storyEngineStateSender]);

	useEffect(() => {
		if (storyEngineStateSender) {
			if (displayWsConnectionStatus === WsConnectionStatus.CONNECTED && isControlConnected) {
				storyEngineStateSender.isStopped = false;
			} else {
				storyEngineStateSender.isStopped = true;
			}
		}
	}, [storyEngineStateSender, displayWsConnectionStatus, isControlConnected]);
};

export default useStoryEngineStateSender;
