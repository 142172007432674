import { useCallback, useRef } from "react";
import { Subscription } from "rxjs";

const useSubscriptionsHandler = () => {
	const subscriptionsRef = useRef<Subscription[]>([]);

	const addSubscription = useCallback((subscription: Subscription) => {
		subscriptionsRef.current.push(subscription);
	}, []);

	const unsubscribeAll = useCallback(() => {
		for (const subscription of subscriptionsRef.current) {
			subscription.unsubscribe();
		}
		subscriptionsRef.current = [];
	}, []);

	return {
		addSubscription,
		unsubscribeAll,
	};
};

export default useSubscriptionsHandler;
