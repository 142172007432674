import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import OutPublicControlWsData from "../../../types/publicControlWs/outPublicControlWsData/OutPublicControlWsData";
import { publicControlWsAtom } from "../publicControlWs.recoil";

const useSendOutPublicControlWsData = () => {
	const publicControlWs = useRecoilValue(publicControlWsAtom);

	return useCallback(
		(data: OutPublicControlWsData) => {
			if (!publicControlWs) {
				console.error("publicControlWs not initialized");
				return;
			}

			publicControlWs.send(data);
		},
		[publicControlWs],
	);
};

export default useSendOutPublicControlWsData;
