import { MdOutlineAddBox } from "react-icons/md";
import Button from "../../../../../../../../../../shared/components/button/Button";
import { useBuilderModals } from "../../../../../builder.recoil";
import SectionForm from "./SectionForm";

const AddSectionButton: React.FC = () => {
	const { pushBuilderModal } = useBuilderModals();

	return (
		<Button
			className="orange"
			onClick={() => {
				pushBuilderModal({
					render: <SectionForm />,
					title: "Add Section",
				});
			}}
		>
			Add section
			<MdOutlineAddBox className="h-5 w-5" />
		</Button>
	);
};

export default AddSectionButton;
