import React from "react";
import {
	AiOutlineTable,
	AiOutlineInsertRowBelow,
	AiOutlineInsertRowRight,
	AiOutlineDelete,
} from "react-icons/ai";
import { BiFontSize } from "react-icons/bi";
import { BsTypeH1, BsTypeH2, BsTypeH3 } from "react-icons/bs";
import { FaSuperscript, FaSubscript } from "react-icons/fa";
import {
	MdAdd,
	MdFormatAlignCenter,
	MdFormatAlignLeft,
	MdFormatAlignRight,
	MdFormatBold,
	MdFormatItalic,
	MdFormatListBulleted,
	MdFormatListNumbered,
	MdFormatQuote,
	MdFormatUnderlined,
	MdImage,
	MdInsertLink,
	MdOutlineFormatColorFill,
	MdOutlineFormatColorText,
	MdOutlinePadding,
	MdStrikethroughS,
	MdVideoLibrary,
} from "react-icons/md";
import { TbLineHeight } from "react-icons/tb";

const ToolbarIcon = ({ icon }: { icon: string }) => {
	const iconList = {
		bold: <MdFormatBold className="icon" />,
		italic: <MdFormatItalic size={20} />,
		strikethrough: <MdStrikethroughS size={20} />,
		underline: <MdFormatUnderlined size={20} />,
		headingOne: <BsTypeH1 size={20} />,
		headingTwo: <BsTypeH2 size={20} />,
		headingThree: <BsTypeH3 size={20} />,

		blockquote: <MdFormatQuote size={20} />,
		superscript: <FaSuperscript size={15} />,
		subscript: <FaSubscript size={15} />,
		alignLeft: <MdFormatAlignLeft size={20} />,
		alignCenter: <MdFormatAlignCenter size={20} />,
		alignRight: <MdFormatAlignRight size={20} />,
		orderedList: <MdFormatListNumbered size={20} />,
		unorderedList: <MdFormatListBulleted size={20} />,
		link: <MdInsertLink size={20} />,
		image: <MdImage size={20} />,
		video: <MdVideoLibrary size={20} />,
		add: <MdAdd size={20} />,
		table: <AiOutlineTable size={20} />,
		row: <AiOutlineInsertRowBelow size={20} />,
		column: <AiOutlineInsertRowRight size={20} />,
		removeTable: <AiOutlineDelete size={20} />,
		fontSize: <BiFontSize className="icon" />,
		lineHeight: <TbLineHeight className="icon" />,
		color: <MdOutlineFormatColorText className="icon" />,
		bgColor: <MdOutlineFormatColorFill className="icon" />,
		paddingBlock: <MdOutlinePadding className="icon" />,
	};

	return iconList[icon as keyof typeof iconList];
};

export default ToolbarIcon;
