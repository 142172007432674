import { PlusIcon, ServerIcon } from "@heroicons/react/outline";
import React from "react";
import Button from "../../../../../../../../shared/components/button/Button";
import { useUpdateChartDataSet } from "../../chartDataSets.recoil";

const AddChartDataPointButton: React.FC = () => {
	// state hooks

	// recoils
	const { insertChartDataPoint } = useUpdateChartDataSet();

	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<Button
			className="blue"
			type="button"
			onClick={() => {
				insertChartDataPoint({
					fields: {},
				});
			}}
		>
			Add <PlusIcon className="h-5 w-5" />
		</Button>
	);
};

export default AddChartDataPointButton;
