import env from "./env";
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { LocalStorageKeys } from "./constants/LocalStorageKeys";
import { withScalars } from "apollo-link-scalars";
import { buildClientSchema, IntrospectionQuery } from "graphql";
import introspectionSchema from "./shared/generated/schema.json";

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem(LocalStorageKeys.TOKEN);
	return {
		headers: {
			...headers,
			authorization: token !== null ? `Bearer ${token}` : "",
		},
	};
});

const typesMap = {
	Date: {
		serialize: (outputValue: unknown) => {
			const date = outputValue as Date;
			return date.toJSON();
		},
		parseValue: (inputValue: unknown): Date => {
			const dateStr = inputValue as string;
			return new Date(dateStr);
		},
	},
	NullableDate: {
		serialize: (outputValue: unknown) => {
			const date = outputValue as Date | null;
			if (date === null) {
				return null;
			}

			return date.toJSON();
		},
		parseValue: (inputValue: unknown): Date | null => {
			const dateStr = inputValue as string | null;
			if (dateStr === null) {
				return null;
			}

			return new Date(dateStr);
		},
	},
};

const schema = buildClientSchema(introspectionSchema as unknown as IntrospectionQuery);
const scalarTypeDefLink = withScalars({ schema, typesMap });

const httpLink = createHttpLink({
	uri: env.REACT_APP_API_BASE_URL + "/api/graphql",
});

export const apolloClient = new ApolloClient({
	// link order matters
	link: ApolloLink.from([authLink, scalarTypeDefLink, httpLink]),
	cache: new InMemoryCache(),
});
