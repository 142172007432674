import { useCallback } from "react";
import { AssetFileFragment } from "../../../../../../../../../../shared/generated/graphql";
import { useAssetFilesGridReloader } from "../../../../../assetLibrary/assetLibraryContent/assetFiles/assetFilesContent/assetFilesGrid/assetFilesGrid.recoil";
import UploadAssetFileInput from "../../../../../assetLibrary/assetLibraryContent/assetFiles/assetFilesHeader/UploadAssetFileInput";
import AssetPickerDoneButton from "./AssetPickerDoneButton";

interface Props {
	onAssetPick?: (assetFile: AssetFileFragment) => void;
}

const AssetsFooter: React.FC<Props> = ({ onAssetPick }) => {
	const { reloadAssetFilesGrid } = useAssetFilesGridReloader();

	const _onUploadFinish = useCallback(() => {
		reloadAssetFilesGrid();
	}, [reloadAssetFilesGrid]);

	return (
		<div className="flex justify-center gap-4">
			<UploadAssetFileInput onUploadFinish={_onUploadFinish} />
			<AssetPickerDoneButton onAssetPick={onAssetPick} />
		</div>
	);
};

export default AssetsFooter;
