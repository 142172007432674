import React from "react";
import {
	Bar,
	BarChart,
	Cell,
	LabelList,
	Pie,
	PieChart,
	ReferenceLine,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from "recharts";
import {
	ChartComponentFragment,
	ChartDataPointFragment,
	ChartTypeIds,
} from "../../../../../../../shared/generated/graphql";

interface Props {
	chartComponent: ChartComponentFragment;
}

const ChartComponent: React.FC<Props> = ({
	chartComponent: {
		typeId,
		chartDataSet: { chartDataPoints },
	},
}) => {
	let fixedData: any[] = [];

	const pieChartSetup = () => {
		//count data total
		const dataTotal = chartDataPoints.reduce((sum, data) => data.value + sum, 0);
		let remainingPercent = 100 - dataTotal;

		if (remainingPercent < 0) {
			remainingPercent = 0;
		}

		fixedData = [
			...chartDataPoints,
			{ name: "remaining", value: remainingPercent, fill: "#F5F5F5" },
		];
	};

	const stackedBarChart = () => {
		const obj: { [key: string]: ChartDataPointFragment } = {};
		//count data total
		chartDataPoints.forEach((el, i) => {
			obj["data" + i] = el;
		});

		fixedData = [obj];
	};

	const renderCustomizedLabel = (props: any) => {
		//props has many data
		return (
			<text
				x={props.width + props.x}
				y={props.y + props.height / 2}
				fill="#FFD51A"
				dominantBaseline="central"
				fontSize={"calc(var(--font-size) * 1.5)"}
				fontWeight={700}
			>
				&nbsp;{chartDataPoints[props.index].value}
				<tspan fill="white"> {props.value}</tspan>
			</text>
		);
	};

	if (chartDataPoints.length <= 0) {
		return <></>;
	}

	switch (typeId) {
		case ChartTypeIds.PieChart:
			pieChartSetup();
			break;
		case ChartTypeIds.StackedBarChart:
			stackedBarChart();
			break;
		default:
			pieChartSetup();
	}

	return (
		<>
			{typeId === ChartTypeIds.PieChart && (
				<div className="pie-chart relative flex flex-col items-center">
					<div className="chart-ring">
						<ResponsiveContainer>
							<PieChart width={100} height={100}>
								<Pie
									dataKey="value"
									startAngle={90}
									endAngle={-270}
									data={fixedData}
									cx={"50%"}
									cy={"50%"}
									innerRadius={"80%"}
									outerRadius={"100%"}
								>
									{fixedData.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={entry.fill} stroke={entry.fill} />
									))}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
					</div>
					<div className="value yellow absolute font-extrabold">
						{chartDataPoints[0].value}
						<span className="text-base-dynamic ">%</span>
					</div>
					{chartDataPoints[0].name !== "" && (
						<div className="text-2xl-dynamic">{chartDataPoints[0].name}</div>
					)}
				</div>
			)}
			{typeId === ChartTypeIds.StackedBarChart && (
				<div className="relative flex h-full flex-col items-center">
					<ResponsiveContainer width="100%" height="100%">
						<BarChart width={100} height={100} data={fixedData} className="text-base">
							<YAxis hide={true} tickCount={0} />
							{Object.keys(fixedData[0]).map((entry, index) => (
								<Bar
									key={index}
									dataKey={entry + ".value"}
									stackId={fixedData[0][entry].stackId}
									fill={fixedData[0][entry].fill}
								>
									{index === 0 && (
										<LabelList
											// dataKey={entry + ".name"}
											className="test-label"
											position="center"
											fill="#FFF"
											fontSize={"calc(var(--font-size) * 1.5)"}
											x={"2em"}
											y={"5em"}
											content={
												<text>
													<tspan dx="0" dy="1.2em">
														40
													</tspan>
													<tspan dx="-1.3em" dy="1.2em">
														hrs
													</tspan>
												</text>
											}
										/>
									)}
								</Bar>
							))}
							<ReferenceLine
								y={372}
								stroke="yellow"
								strokeDasharray="6 4"
								strokeWidth={"calc(var(--font-size) * 0.2)"}
							></ReferenceLine>
						</BarChart>
					</ResponsiveContainer>
				</div>
			)}
			{typeId === ChartTypeIds.HorizontalBarChart && (
				<div className="relative flex h-full w-full flex-col items-center">
					<ResponsiveContainer width="100%" height="100%">
						<BarChart
							layout="vertical"
							data={chartDataPoints}
							margin={{
								top: 0,
								right: 0,
								bottom: 0,
								left: 0,
							}}
							maxBarSize={100}
							barCategoryGap={0}
							barGap={1}
							barSize={50}
							compact={true}
						>
							<XAxis
								hide={true}
								type="number"
								tickCount={0}
								domain={[0, (dataMax: any) => dataMax * 1.5]}
							/>
							<YAxis hide={true} dataKey="name" type="category" scale="band" />
							<Bar dataKey="value" barSize={1000}>
								<LabelList
									dataKey="name"
									position="right"
									formatter={(v: any) => {
										return v;
									}}
									fill={"#fff"}
									offset={"50"}
									fontSize={"calc(var(--font-size) * 1.5)"}
									fontWeight={700}
									content={renderCustomizedLabel}
								/>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			)}
		</>
	);
};

export default ChartComponent;
