import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import ChartDataSetForm from "./chartDataSetForm/ChartDataSetForm";
import { chartDataSetFormOpenerAtom } from "./chartDataSets.recoil";
import ChartDataSetsPage from "./chartDataSetsPage/ChartDataSetsPage";

interface Props {
	isPicking?: boolean;
}

const ChartDataSets: React.FC<Props> = ({ isPicking }) => {
	const chartDataSetFormOpener = useRecoilValue(chartDataSetFormOpenerAtom);
	const resetChartDataSetFormOpener = useResetRecoilState(chartDataSetFormOpenerAtom);

	useEffect(() => {
		return () => {
			resetChartDataSetFormOpener();
		};
	}, [resetChartDataSetFormOpener]);

	return (
		<div>
			{chartDataSetFormOpener !== undefined ? (
				<ChartDataSetForm chartDataSetId={chartDataSetFormOpener.id} />
			) : (
				<ChartDataSetsPage isPicking={isPicking} />
			)}
		</div>
	);
};

export default ChartDataSets;
