import { useEffect, useMemo } from "react";
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useRecoilValue } from "recoil";
import { useGetStoryCallZipCodeAgeGroupCountsArrLazyQuery } from "../../../shared/generated/graphql";
import Card from "../../components/Card";
import { selectedStoryAtom, selectedZipCodeAtom } from "./public.recoil";

const AgeGroupsChart: React.FC = () => {
	const selectedStory = useRecoilValue(selectedStoryAtom)!;
	const selectedZipCode = useRecoilValue(selectedZipCodeAtom)!;

	const [getStoryCallZipCodeAgeGroupCountsArr, getStoryCallZipCodeAgeGroupCountsArrRes] =
		useGetStoryCallZipCodeAgeGroupCountsArrLazyQuery();

	useEffect(() => {
		void getStoryCallZipCodeAgeGroupCountsArr({
			variables: {
				input: {
					ageGroups: [
						{
							min: 0,
							max: 18,
						},
						{
							min: 19,
							max: 34,
						},
						{
							min: 35,
							max: 49,
						},
						{
							min: 50,
							max: 64,
						},
						{
							min: 65,
							max: 999,
						},
					],
					filter: {
						storyId: selectedStory.id,
						zipCodes: [selectedZipCode.zipCode],
					},
				},
			},
		});
	}, [selectedZipCode]);

	const data = useMemo(() => {
		const zipCodeAgeGroupCountsArr =
			getStoryCallZipCodeAgeGroupCountsArrRes.data?.getStoryCallZipCodeAgeGroupCountsArr.rows ?? [];
		if (zipCodeAgeGroupCountsArr.length === 0) {
			return [];
		}
		const zipCodeAgeGroupCounts = zipCodeAgeGroupCountsArr[0];

		return zipCodeAgeGroupCounts.ageGroupCounts.reduce<{ name: string; value: number }[]>(
			(prev, curr) => {
				return [
					...prev,
					{
						name: curr.ageGroup.replace("65-999", "65+"),
						value: curr.count,
					},
				];
			},
			[],
		);
	}, [getStoryCallZipCodeAgeGroupCountsArrRes]);

	return (
		<Card className="age-groups-chart">
			<div className="font-semibold ">Age groups impacted in your community</div>
			<ResponsiveContainer width="100%" height={250}>
				<BarChart width={100} height={100} data={data}>
					<YAxis hide={true} tickCount={5} />
					<XAxis dataKey="name" />
					<Bar dataKey={"value"} fill={"#427abd"}>
						<LabelList dataKey={"value"} position={"top"} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</Card>
	);
};

export default AgeGroupsChart;
