import Map from "../../shared/views/map/Map";
import { useRecoilState, useRecoilValue } from "recoil";
import { displayModeAtom, reloaderAtom, storiesAtom } from "../state/displayWs/display.recoil";
import { useEffect, useMemo } from "react";
import DisplayModes from "../../shared/types/DisplayModes";
import { useGetStoriesFullLazyQuery } from "../../shared/generated/graphql";
import env, { BuildTypes } from "../../env";
import SystemInit from "./SystemInit";
import StoryDisplay from "./story/StoryDisplay";
import "../styles/general.scss";
import "../styles/food_insecurity.scss";
import Loading from "../../shared/components/Loading/Loading";
import { useStoryEngine } from "../../shared/state/storyEngine/storyEngine.recoil";

const DisplayMain: React.FC = () => {
	const displayMode = useRecoilValue(displayModeAtom);

	const [stories, setStories] = useRecoilState(storiesAtom);
	const { storyEngine, initStoryEngine, destroyStoryEngine } = useStoryEngine();
	const reloader = useRecoilValue(reloaderAtom);

	const [getStories, getStoriesRes] = useGetStoriesFullLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		initStoryEngine();
	}, [initStoryEngine]);

	useEffect(() => {
		return () => {
			destroyStoryEngine();
		};
	}, [destroyStoryEngine]);

	useEffect(() => {
		if (env.REACT_APP_BUILD_TYPE === BuildTypes.DisplaySystem) {
			if (storyEngine) {
				storyEngine.stop();
				void getStories({
					variables: {
						input: {
							filter: {
								isFeatured: true,
							},
							orderBys: [
								{
									by: "orderNum",
								},
								{
									by: "createdAt",
								},
								{
									by: "name",
								},
							],
						},
					},
				});
			}
		}
	}, [storyEngine, reloader, getStories]);

	const hasFinishedGettingStories = useMemo(() => {
		return getStoriesRes.called && !getStoriesRes.loading;
	}, [getStoriesRes.called, getStoriesRes.loading]);

	useEffect(() => {
		if (hasFinishedGettingStories) {
			if (getStoriesRes.data) {
				setStories([...getStoriesRes.data.getStories.rows]);
			} else {
				setStories([]);
			}
		}
	}, [hasFinishedGettingStories, getStoriesRes.data, setStories]);

	useEffect(() => {
		if (storyEngine) {
			if (stories.length !== 0) {
				storyEngine.stories = stories;
				storyEngine.isPaused = false;
			} else {
				storyEngine.stories = [];
			}
		}
	}, [storyEngine, stories]);

	const render = useMemo(() => {
		switch (displayMode) {
			case DisplayModes.MAP:
				return <Map />;
			case DisplayModes.STORY:
			default:
				return <StoryDisplay />;
		}
	}, [displayMode]);

	return (
		<div className="main">
			{env.REACT_APP_BUILD_TYPE === BuildTypes.DisplaySystem && <SystemInit />}
			{stories.length !== 0 ? (
				render
			) : hasFinishedGettingStories ? (
				<div className="splash">
					No stories found
					<button
						type="button"
						onClick={() => window.location.reload()}
						className="text-3xl text-uwgc-midblue hover:text-uwgc-slate"
					>
						Try Again
					</button>
				</div>
			) : (
				<div className="splash">
					Fetching stories
					<Loading loadingType="lds-ring" />
				</div>
			)}
		</div>
	);
};

export default DisplayMain;
