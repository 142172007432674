import React from "react";
import { ImageComponentFragment } from "../../../../../../../shared/generated/graphql";
import { getFullFilePath } from "../../../../../../../shared/utils/Utils";

interface Props {
	imageComponent: ImageComponentFragment;
}

const ImageComponent: React.FC<Props> = ({ imageComponent: { assetFile } }) => {
	return (
		<img
			style={{ width: "100%", height: "100%", objectFit: "contain" }}
			src={getFullFilePath(assetFile.file)}
			alt={assetFile.file.name}
		/>
	);
};

export default ImageComponent;
