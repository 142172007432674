import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useRecoilState } from "recoil";
import useProcessNavItems from "../../../../shared/hooks/useProcessNavItems";
import { PathName } from "../../../constants/PathName";
import { isSidebarOpenAtom } from "../../../state/sidebar.recoil";
import "../../../styles/components/header.scss";
import DisplayStatus from "./DisplayStatus";

const PrivateHeader: React.FC = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenAtom);
	const navItems = useProcessNavItems([
		{
			href: PathName.CONTROL,
			name: "Control",
		},
	]);

	return (
		<div className="header">
			<div className="flex items-center gap-3">
				<div
					className="flex h-14 w-14 cursor-pointer items-center justify-center rounded hover:backdrop-brightness-90"
					onClick={() => setIsSidebarOpen(!isSidebarOpen)}
				>
					{isSidebarOpen ? <XIcon className="h-6s w-6" /> : <MenuIcon className="h-6s w-6" />}
				</div>
				Community Dashboard
			</div>

			<div className="flex gap-3">
				{navItems[0].isCurrent && <DisplayStatus />}
				<img src="/assets/svg/UWGC_Logo.svg" alt="uwgc_logo" />
			</div>
		</div>
	);
};

export default PrivateHeader;
