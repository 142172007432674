import Button from "../../../../../../../../../shared/components/button/Button";
import {
	AssetFileFragment,
	AssetFileTypeIds,
} from "../../../../../../../../../shared/generated/graphql";
import { useBuilderModals } from "../../../../builder.recoil";
import AssetPicker from "./AssetPicker";

const ASSET_FILE_TYPE_ID__TITLE__MAP = new Map<AssetFileTypeIds, string>([
	[AssetFileTypeIds.Image, "Image Library"],
	[AssetFileTypeIds.Video, "Video Library"],
	[AssetFileTypeIds.Audio, "Audio Library"],
]);

interface Props {
	typeId: AssetFileTypeIds;
	onAssetPick?: (assetFile: AssetFileFragment) => void;
}

const OpenAssetPickerButton: React.FC<Props> = ({ children, typeId, onAssetPick }) => {
	const { pushBuilderModal, popBuilderModal } = useBuilderModals();

	return (
		<Button
			className="orange"
			type={"button"}
			onClick={() => {
				pushBuilderModal({
					render: (
						<AssetPicker
							typeId={typeId}
							onAssetPick={(assetFile) => {
								onAssetPick?.(assetFile);
								popBuilderModal();
							}}
						/>
					),
					title: ASSET_FILE_TYPE_ID__TITLE__MAP.get(typeId),
				});
			}}
		>
			{children}
		</Button>
	);
};

export default OpenAssetPickerButton;
