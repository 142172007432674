import { useComponentTempForm, useUpdateComponentTemp } from "../../../story.recoil";
import TextEditor from "./textEditor/TextEditor";

const TextComponentForm: React.FC = () => {
	const {
		componentTempForm: { componentTemp },
	} = useComponentTempForm();
	const { updateHtmlComponentTemp } = useUpdateComponentTemp();

	return (
		<>
			<div>
				<TextEditor
					initlialValue={JSON.parse(componentTemp.htmlComponents![0].html ?? "")}
					// initlialValue={[
					// 	{
					// 		type: "paragaph",
					// 		children: [
					// 			{
					// 				bold: true,
					// 				text: "Zero",
					// 			},
					// 			{
					// 				text: "First line of text in Slate JS.",
					// 			},
					// 		],
					// 	},
					// ]}
					onChange={(v) => {
						updateHtmlComponentTemp({
							fields: {
								html: JSON.stringify(v),
							},
						});
					}}
				/>
			</div>
		</>
	);
};

export default TextComponentForm;
