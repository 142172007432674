export enum StoryCommandDataTypes {
	SET_STORY, // number | undefined
	SET_SECTION, // number
	SET_TIME, // number
	SET_IS_PAUSED, // boolean
}

interface StoryCommandData {
	type: StoryCommandDataTypes;
	data: undefined | string | number | boolean;
}

export default StoryCommandData;
