import env, { BuildTypes, EnvTypes } from "../../env";
import path from "path-browserify";
import { FileFragment } from "../generated/graphql";
import { LocalStorageKeys } from "../../constants/LocalStorageKeys";

export const getFullFilePathFromPartialPath = (partialPath: string) => {
	switch (env.REACT_APP_BUILD_TYPE) {
		case BuildTypes.DisplaySystem:
			return "file:///" + path.join(env.REACT_APP_FILES_DIR, partialPath);

		case BuildTypes.DisplayStandalone:
			return env.NODE_ENV === EnvTypes.Development
				? "file:///" + path.join(env.REACT_APP_FILES_DIR, partialPath)
				: path.join("../../assets", partialPath);
		default:
			return partialPath;
	}
};

export const getPartialFilePath = (file: FileFragment) => {
	return "/" + file.id;
};

export const getRemoteFilePathAuth = (file: FileFragment) => {
	const token = localStorage.getItem(LocalStorageKeys.TOKEN);

	return env.REACT_APP_API_BASE_URL + `/api/v1/files/${file.id}/${file.name}?token=${token}`;
};

export const getFullFilePath = (file: FileFragment) => {
	if (env.REACT_APP_BUILD_TYPE === BuildTypes.Control) {
		return getRemoteFilePathAuth(file);
	} else {
		return getFullFilePathFromPartialPath(getPartialFilePath(file));
	}
};
