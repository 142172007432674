import { useEffect } from "react";
import { useRecoilState } from "recoil";
import Input from "../../../../../../../../shared/components/input/Input";
import Select from "../../../../../../../../shared/components/select/Select";
import {
	StoryStatusIds,
	useGetStoryStatusesLazyQuery,
} from "../../../../../../../../shared/generated/graphql";
import { storiesTableFilterAtom } from "../stories.recoil";

const StoriesFilters: React.FC = () => {
	const [storiesTableFilter, setStoriesTableFilter] = useRecoilState(storiesTableFilterAtom);

	const [getStoryStatuses, getStoryStatusesRes] = useGetStoryStatusesLazyQuery({
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		void getStoryStatuses();
	}, [getStoryStatuses]);

	const storyStatuses = getStoryStatusesRes.data?.getStoryStatuses.rows || [];

	return (
		<div className="flex gap-4 text-uwgc-charcoal">
			<Input
				placeholder="Topic - All"
				value={storiesTableFilter.likeTopic ?? ""}
				onChange={(e) => {
					setStoriesTableFilter({
						likeTopic: e.target.value === "" ? undefined : e.target.value,
					});
				}}
			/>
			<Select
				className="text-gray-400"
				value={storiesTableFilter.statusId ?? ""}
				onChange={(e) => {
					if (e.target.value === "") {
						e.target.classList.add("text-gray-400");
					} else {
						e.target.classList.remove("text-gray-400");
					}
					setStoriesTableFilter({
						statusId: e.target.value === "" ? undefined : (e.target.value as StoryStatusIds),
					});
				}}
			>
				{[{ id: "", name: "" }, ...storyStatuses].map((storyStatus, i) => (
					<option
						className="text-uwgc-charcoal first:text-gray-400"
						key={storyStatus.id}
						value={storyStatus.id}
					>
						{storyStatus.name === "" ? "Status - All" : storyStatus.name}
					</option>
				))}
			</Select>
			{/* <Select>
				{["Created By - All"].map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
				))}
			</Select> */}
		</div>
	);
};

export default StoriesFilters;
