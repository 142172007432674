import React from "react";
import { useRecoilValue } from "recoil";
import { storiesAtom } from "../../state/displayWs/display.recoil";
import {
	useStoryEngine,
	useStoryEngineState,
} from "../../../shared/state/storyEngine/storyEngine.recoil";
import { getFullFilePathFromPartialPath } from "../../../shared/utils/Utils";

const StoryHeader = () => {
	const { storyEngine: storyEngineU } = useStoryEngine();
	const storyEngine = storyEngineU!;
	const storyEngineState = useStoryEngineState();

	const stories = useRecoilValue(storiesAtom);
	return (
		<div className="story-header">
			<select
				disabled
				value={storyEngineState.story?.id ?? ""}
				onChange={(e) => storyEngine.setStoryById(parseInt(e.currentTarget.value))}
			>
				{stories.map((story) => {
					return (
						<option key={story.id} value={story.id}>
							{story.name}
						</option>
					);
				})}
			</select>
			<img
				src={getFullFilePathFromPartialPath("/images/UWGC_Logo_GC.svg")}
				alt={"UWGC_Logo_GC.svg"}
			></img>
		</div>
	);
};

export default StoryHeader;
