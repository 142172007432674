import { BehaviorSubject, map } from "rxjs";
import {
	AssetFileFragment,
	ContainerFragment,
	FrameFragment,
	LayerFragment,
	SectionFragment,
	StoryFragment,
} from "../generated/graphql";
import AudioFadeData from "../types/storyEngine/AudioFadeData";
import { clampRange } from "gate3-utils";

const BG_AUDIO_FADE_DURATION = 4;
const BG_AUDIO_FADE_IN_DEFAULT_START_VOLUME = 0;
const BG_AUDIO_FADE_IN_DEFAULT_END_VOLUME = 1;
const BG_AUDIO_FADE_OUT_DEFAULT_START_VOLUME = 1;
const BG_AUDIO_FADE_OUT_DEFAULT_END_VOLUME = 0;

class StoryEngine {
	private _stories = new BehaviorSubject<StoryFragment[]>([]);
	private _story = new BehaviorSubject<StoryFragment | undefined>(undefined);
	private _section = new BehaviorSubject<SectionFragment | undefined>(undefined); // always defined if there's a story with section
	private _frame = new BehaviorSubject<FrameFragment | undefined>(undefined);
	private _layers = new BehaviorSubject<LayerFragment[]>([]);
	private _time = new BehaviorSubject<number>(0); // section
	private _frameStartTime = 0;
	private _interval = new BehaviorSubject<NodeJS.Timer | undefined>(undefined);
	private _duration = 0; // section
	private _bgAudioAssetFile = new BehaviorSubject<AssetFileFragment | undefined>(undefined);
	private _bgAudioVolume = new BehaviorSubject<number>(1);
	private _bgAudioFadeDataArr: AudioFadeData[] = [];

	private _looper() {
		this.time += 1 / 24;
	}

	get stories() {
		return this._stories.getValue();
	}

	get storiesObservable() {
		return this._stories.asObservable();
	}

	set stories(val) {
		this._stories.next(val);
		if (this.stories.length !== 0) {
			this._setStory(this.stories[0]);
		} else {
			this._setStory(undefined);
		}
	}

	get story() {
		return this._story.getValue();
	}

	get storyObservable() {
		return this._story.asObservable();
	}

	setStoryById(id: number) {
		const story = this.stories.find((story) => story.id === id);
		if (!story) {
			console.error(`Cannot set story, story id ${id} does not exist`);
			return;
		}

		this._setStory(story);
	}

	nextStory() {
		if (this.stories.length !== 0) {
			let storyIndex = this.stories.findIndex((story) => story.id === this.story!.id);
			if (storyIndex === this.stories.length - 1) {
				storyIndex = 0;
			} else {
				storyIndex += 1;
			}

			this._setStory(this.stories[storyIndex]);
		}
	}

	private _setStory(story: StoryFragment | undefined) {
		this._story.next(story);
		if (this.story && this.story.sections.length !== 0) {
			this._setSection(this.story.sections[0]);
		} else {
			// also clears frame and containers
			this._setSection(undefined);
		}
	}

	get section() {
		return this._section.getValue();
	}

	get sectionObservable() {
		return this._section.asObservable();
	}

	setSectionById(id: number) {
		if (!this.story) {
			console.error("Cannot set section, no story");
			return;
		}
		const section = this.story.sections.find((section) => section.id === id);
		if (!section) {
			console.error(`Cannot set section, section id ${id} does not exist`);
			return;
		}

		this._setSection(section);
	}

	nextSection() {
		if (this.story && this.story.sections.length !== 0) {
			let sectionIndex = this.story.sections.findIndex(
				(section) => section.id === this.section!.id,
			);
			if (sectionIndex === this.story.sections.length - 1) {
				if (this.stories.length > 1) {
					this.nextStory();
					return;
				} else {
					sectionIndex = 0;
				}
			} else {
				sectionIndex += 1;
			}
			this._setSection(this.story.sections[sectionIndex]);
		}
	}

	private _setSection(section: SectionFragment | undefined) {
		this._section.next(section);
		this._duration = 0;

		// prevents frame of different sections with the same id from persisting
		if (this.frame !== undefined) {
			this._frame.next(undefined);
		}

		// prevents layers of different sections with the same id from persisting
		if (this.layers.length !== 0) {
			this._layers.next([]);
		}

		if (!this.section) {
			// clears frame
			this.time = 0;
			this._pause();
			return;
		}

		if (this._bgAudioAssetFile.getValue()?.id !== this.section.bgAudioAssetFile?.id) {
			this._bgAudioAssetFile.next(this.section.bgAudioAssetFile ?? undefined);
		}
		this._bgAudioFadeDataArr = [];
		if (this.section.bgAudioFadeIn) {
			this._bgAudioFadeDataArr.push({
				startTime: 0,
				endTime: this.section.bgAudioFadeIn.duration ?? BG_AUDIO_FADE_DURATION,
				startVolume:
					this.section.bgAudioFadeIn.startVolume ?? BG_AUDIO_FADE_IN_DEFAULT_START_VOLUME,
				endVolume: this.section.bgAudioFadeIn.endVolume ?? BG_AUDIO_FADE_IN_DEFAULT_END_VOLUME,
			});
		}

		let frameStartTime = 0;
		// calculate section duration
		// create bg audio fade data
		// @TODO handle overlapping bg audio fade data
		// @TODO handle bg audio within story rather than section
		for (const frame of this.section.frames) {
			this._duration += frame.duration;

			// assumes there's no overlapping video between layers
			for (const layer of frame.layers) {
				let containerStartTime = frameStartTime;
				for (let containerI = 0; containerI < layer.containers.length; ++containerI) {
					const container = layer.containers[containerI];

					containerStartTime = frameStartTime + container.delay;
					for (const component of container.components) {
						if (component.videoComponents.length !== 0) {
							const videoComponent = component.videoComponents[0];
							if (videoComponent.isMuted) {
								continue;
							}

							if (!videoComponent.shouldBgAudioNotFadeOutBeforeVideo) {
								this._bgAudioFadeDataArr.push({
									startTime:
										containerStartTime -
										(videoComponent.bgAudioFadeOut?.duration ?? BG_AUDIO_FADE_DURATION),
									endTime: containerStartTime,
									startVolume:
										videoComponent.bgAudioFadeOut?.startVolume ??
										BG_AUDIO_FADE_OUT_DEFAULT_START_VOLUME,
									endVolume:
										videoComponent.bgAudioFadeOut?.endVolume ??
										BG_AUDIO_FADE_OUT_DEFAULT_END_VOLUME,
								});
							}

							if (!videoComponent.shouldBgAudioNotFadeInAfterVideo) {
								// @TODO video that persists
								const frameEndTime = this._duration;
								this._bgAudioFadeDataArr.push({
									startTime: frameEndTime,
									endTime:
										frameEndTime +
										(videoComponent.bgAudioFadeIn?.duration ?? BG_AUDIO_FADE_DURATION),
									startVolume:
										videoComponent.bgAudioFadeIn?.startVolume ??
										BG_AUDIO_FADE_IN_DEFAULT_START_VOLUME,
									endVolume:
										videoComponent.bgAudioFadeIn?.endVolume ?? BG_AUDIO_FADE_IN_DEFAULT_END_VOLUME,
								});
							}
						}
					}
				}
			}

			frameStartTime += frame.duration;
		}
		// prevents infinite loop
		if (this._duration < 0) {
			this._duration = 0;
		}

		if (this.section.bgAudioFadeOut) {
			this._bgAudioFadeDataArr.push({
				startTime:
					this._duration - (this.section.bgAudioFadeOut.duration ?? BG_AUDIO_FADE_DURATION),
				endTime: this._duration,
				startVolume:
					this.section.bgAudioFadeOut.startVolume ?? BG_AUDIO_FADE_OUT_DEFAULT_START_VOLUME,
				endVolume: this.section.bgAudioFadeOut.endVolume ?? BG_AUDIO_FADE_OUT_DEFAULT_END_VOLUME,
			});
		}

		this.time = 0;
	}

	get frame() {
		return this._frame.getValue();
	}

	get frameObservable() {
		return this._frame.asObservable();
	}

	get layers() {
		return this._layers.getValue();
	}

	get layersObservable() {
		return this._layers.asObservable();
	}

	get time() {
		return this._time.getValue();
	}

	get timeObservable() {
		return this._time.asObservable();
	}

	set time(val) {
		this._time.next(val);
		if (this.time > this._duration) {
			this.nextSection();
			this.time = 0;
			return;
		}

		this._updateFrame();
		this._updateContainers();
		this._updateBgAudio();
	}

	get isPaused() {
		return !this._interval.getValue();
	}

	get isPausedObservable() {
		return this._interval.asObservable().pipe(map((val) => !val));
	}

	set isPaused(val) {
		if (val) {
			this._pause();
		} else {
			this._play();
		}
	}

	get bgAudioAssetFile() {
		return this._bgAudioAssetFile.getValue();
	}

	get bgAudioAssetFileObservable() {
		return this._bgAudioAssetFile.asObservable();
	}

	get bgAudioVolume() {
		return this._bgAudioVolume.getValue();
	}

	get bgAudioVolumeObservable() {
		return this._bgAudioVolume.asObservable();
	}

	private _play() {
		if (!this.story) {
			console.error("Cannot play, no story");
			return;
		}

		if (!this.section) {
			console.error("Cannot play, no section");
			return;
		}

		if (this.isPaused) {
			this._interval.next(setInterval(() => this._looper(), 1000 / 24)); // 24 fps
		}
	}

	private _pause() {
		if (!this.isPaused) {
			clearInterval(this._interval.getValue()!);
			this._interval.next(undefined);
		}
	}

	stop() {
		this._setStory(undefined);
	}

	reset() {
		if (this.story !== undefined) {
			this.setStoryById(this.story.id);
		}
	}

	private _updateFrame() {
		if (this.section && this.section.frames.length !== 0) {
			let startTime = 0;
			for (const frame of this.section.frames) {
				const endTime = startTime + frame.duration;
				// startTime inclusive, endTime exclusive
				if (this.time >= startTime && this.time < endTime) {
					if (this.frame?.id !== frame.id) {
						this._frameStartTime = startTime;
						this._frame.next(frame);
					}
					return;
				}

				startTime = endTime;
			}
		}

		if (this.frame !== undefined) {
			this._frame.next(undefined);
		}
	}

	private _getRenderedContainers(layer: LayerFragment) {
		const renderedContainers: ContainerFragment[] = [];
		for (const container of layer.containers) {
			const startTime = this._frameStartTime + container.delay;
			if (this.time >= startTime) {
				renderedContainers.push(container);
			}
		}

		return renderedContainers;
	}

	private _updateContainers() {
		if (this.frame) {
			/**
			 * @TODO this might not be needed, but not unneeded either (just redundant)
			 * container.id is always gonna be different on different frame as opposed to what it used to be
			 * this was needed to prevent animation from firing when going to the next frame
			 * but because animation can now be turned off per container then this is no longer needed
			 * need to test though
			 * */
			const newLayers: LayerFragment[] = [];
			let isLayersUpdated = false;
			for (const newLayer of this.frame.layers) {
				const oldLayer = this.layers.find((layer) => layer.id === newLayer.id);
				if (oldLayer) {
					const newContainers = this._getRenderedContainers(newLayer);

					// check if layer is updated by checking its containers
					if (
						oldLayer.containers.length !== newContainers.length ||
						oldLayer.containers.some((container, i) => container.id !== newContainers[i].id) // this is now always true on new frame
					) {
						isLayersUpdated = true;
						newLayers.push({
							...oldLayer,
							containers: newContainers,
						});
					} else {
						newLayers.push(oldLayer);
					}
				} else {
					isLayersUpdated = true;

					newLayers.push({
						id: newLayer.id,
						inAnimation: newLayer.inAnimation,
						outAnimation: newLayer.outAnimation,
						containers: this._getRenderedContainers(newLayer),
					});
				}
			}

			if (isLayersUpdated) {
				this._layers.next(newLayers);
			}
		} else {
			if (this.layers.length !== 0) {
				this._layers.next([]);
			}
		}
	}

	private _updateBgAudio() {
		let lastBgAudioFadeData: AudioFadeData | undefined;
		let isFading = false;
		for (const bgAudioFadeData of this._bgAudioFadeDataArr) {
			if (this.time >= bgAudioFadeData.startTime) {
				lastBgAudioFadeData = bgAudioFadeData;
				if (this.time <= bgAudioFadeData.endTime) {
					isFading = true;
					const duration = bgAudioFadeData.endTime - bgAudioFadeData.startTime;
					const elapsedSinceStartTime = this.time - bgAudioFadeData.startTime; // 1.99 - 0 = 1.99

					const startVolumeClamped = clampRange(bgAudioFadeData.startVolume, 0, 1);
					const endVolumeClamped = clampRange(bgAudioFadeData.endVolume, 0, 1);

					const volumeLength = endVolumeClamped - startVolumeClamped;
					const volumeSign = Math.sign(volumeLength);
					const volumeMagnitude = Math.abs(volumeLength);

					const volumeScale = elapsedSinceStartTime / duration; // near end case 1.99 / 2 = ~1
					// const volumeScale = 1 - elapsedSinceStartTime / duration; // near end case 1 - (1.99 / 2) = ~0

					if (volumeSign > 0) {
						this._bgAudioVolume.next(startVolumeClamped + volumeScale * volumeMagnitude);
					} else {
						this._bgAudioVolume.next(startVolumeClamped - volumeScale * volumeMagnitude);
					}

					break;
				}
			}
		}

		if (!isFading) {
			if (lastBgAudioFadeData) {
				const endVolumeClamped = clampRange(lastBgAudioFadeData.endVolume, 0, 1);
				if (this.bgAudioVolume !== endVolumeClamped) {
					this._bgAudioVolume.next(endVolumeClamped);
				}
			} else {
				// default back to 1 if there's no fading for this section
				if (this.bgAudioVolume !== 1) {
					this._bgAudioVolume.next(1);
				}
			}
		}
	}
}

export default StoryEngine;
