import { useCallback } from "react";
import { useStoryEngine } from "../../../../shared/state/storyEngine/storyEngine.recoil";
import StoryCommandData, {
	StoryCommandDataTypes,
} from "../../../types/displayWs/inDisplayWsData/StoryCommandData";

const useHandleStoryCommandData = () => {
	const { storyEngine } = useStoryEngine();

	return useCallback(
		(command: StoryCommandData) => {
			switch (command.type) {
				case StoryCommandDataTypes.SET_STORY:
					if (storyEngine) {
						const storyId = command.data as number | undefined;
						if (storyId !== undefined) {
							storyEngine.setStoryById(storyId);
						} else {
							storyEngine.stop();
						}
						storyEngine.isPaused = false;
					}
					break;
				case StoryCommandDataTypes.SET_SECTION:
					storyEngine?.setSectionById(command.data as number);
					break;
				case StoryCommandDataTypes.SET_TIME:
					if (storyEngine) {
						storyEngine.time = command.data as number;
					}
					break;
				case StoryCommandDataTypes.SET_IS_PAUSED:
					if (storyEngine) {
						storyEngine.isPaused = command.data as boolean;
					}
					break;
				default: {
					console.error("Unknown StoryCommandDataTypes");
				}
			}
		},
		[storyEngine],
	);
};

export default useHandleStoryCommandData;
