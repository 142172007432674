import { atom } from "recoil";
import { SectionFragment, StoryFragment } from "../../../../../../../../shared/generated/graphql";

export const storyEngineStateAtom = atom<{
	story: StoryFragment | undefined;
	section: SectionFragment | undefined;
	time: number;
	isPaused: boolean;
}>({
	key: "storyEngineState_control",
	default: {
		story: undefined,
		section: undefined,
		time: 0,
		isPaused: false,
	},
});
