import React from "react";
import env, { BuildTypes } from "../../../env";
import Story from "./Story";
import StoryControl from "./StoryControl";
import StoryHeader from "./StoryHeader";

const StoryDisplay = () => {
	// state hooks

	// recoils

	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<div className="story-display">
			{env.REACT_APP_BUILD_TYPE === BuildTypes.DisplayStandalone && <StoryHeader />}
			<div className="main-story">
				<Story />
			</div>
			{env.REACT_APP_BUILD_TYPE === BuildTypes.DisplayStandalone && <StoryControl />}
		</div>
	);
};

export default StoryDisplay;
