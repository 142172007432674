import React, { InputHTMLAttributes } from "react";
import "./slider.scss";

type Props = InputHTMLAttributes<HTMLInputElement>;

const Slider: React.FC<Props> = (props) => {
	return <input {...props} className="input-range" type="range" />;
};

export default Slider;
