import { ALL_LAYERS_INDEX, useStoryForm } from "../../../../../../story.recoil";
import LayerTab from "./layerTab/LayerTab";
import "./layerTabs.scss";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	selectedLayerIndex: number;
	onSelectLayer: (layerIndex: number) => void;
}

const LayerTabs: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	selectedLayerIndex,
	onSelectLayer,
}) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const layers = story.sections?.[sectionIndex]?.frames?.[frameIndex].layers;

	return (
		<div className="layer-tabs">
			<LayerTab
				isSelected={selectedLayerIndex === ALL_LAYERS_INDEX}
				sectionIndex={sectionIndex}
				frameIndex={frameIndex}
				layerIndex={ALL_LAYERS_INDEX}
				onClick={() => onSelectLayer(ALL_LAYERS_INDEX)}
			/>
			{layers?.map((layer, i) => {
				return (
					<LayerTab
						key={i}
						isSelected={selectedLayerIndex === i}
						sectionIndex={sectionIndex}
						frameIndex={frameIndex}
						layerIndex={i}
						onClick={() => onSelectLayer(i)}
					/>
				);
			})}
		</div>
	);
};

export default LayerTabs;
