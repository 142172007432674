import { LatLngTuple } from "leaflet";
import Pair from "./Pair";

export enum MapLayerTypes {
	ZIP_CODES_HEAT_MAP,
	ZIP_CODES_SCATTER_PLOT,
}

export interface ZipCodeCount {
	zipCode: string;
	polygons: LatLngTuple[][];
	count: number;
}

export interface MapLayerZipCodeHeatMapData {
	maxCount: number;
	zipCodeCounts: ZipCodeCount[];
}

export interface ZipCodePairs {
	zipCode: string;
	polygons: LatLngTuple[][];
	totalCount: number;
	pairs: Pair[];
}

export interface MapLayerZipCodeScatterPlotData {
	zipCodePairsArr: ZipCodePairs[];
}

export interface MapLayerData {
	type: MapLayerTypes;
	data: MapLayerZipCodeHeatMapData | MapLayerZipCodeScatterPlotData;
}

export default MapLayerData;
