import React, { ButtonHTMLAttributes } from "react";
import "./button.scss";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: React.FC<Props> = ({ children, className, ...props }) => {
	return (
		<button {...props} className={" button " + (className !== undefined && className)}>
			{children}
		</button>
	);
};

export default Button;
