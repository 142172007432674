import Pair from "./Pair";

export enum MapDetailDataTypes {
	TABLE,
	PIE_CHART,
}

interface MapDetailData {
	type: MapDetailDataTypes;
	title: string;
	subtitle?: string;
	pairs: Pair[];
	shouldHideLegends?: boolean;
}

export default MapDetailData;
