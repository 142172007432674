import { useCallback } from "react";
import { AssetFileFragment } from "../../../../../../../../../../shared/generated/graphql";
import { BiHdd } from "react-icons/bi";
import Button from "../../../../../../../../../../shared/components/button/Button";
import { useSelectedAssetFiles } from "../../../../../assetLibrary/assetLibraryContent/assetFiles/assetFilesContent/assetFilesGrid/assetFilesGrid.recoil";

interface Props {
	onAssetPick?: (assetFile: AssetFileFragment) => void;
}

const AssetPickerDoneButton: React.FC<Props> = ({ onAssetPick }) => {
	const { selectedAssetFiles } = useSelectedAssetFiles();

	const _done = useCallback(() => {
		if (selectedAssetFiles.length !== 0) {
			onAssetPick?.(selectedAssetFiles[0]);
		}
	}, [onAssetPick, selectedAssetFiles]);

	return (
		<Button
			className="blue"
			disabled={selectedAssetFiles.length === 0}
			onClick={() => {
				_done();
			}}
		>
			DONE <BiHdd className="w-5 h-5" />
		</Button>
	);
};

export default AssetPickerDoneButton;
