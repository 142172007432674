import { useCallback, useEffect } from "react";
import {
	AddOrEditChartDataPointInput,
	AddOrEditChartDataPointsInput,
	ChartDataSetFragment,
	useAddChartDataSetMutation,
	useGetChartDataSetLazyQuery,
} from "../../../../../../../../../../shared/generated/graphql";
import { BiHdd } from "react-icons/bi";
import Button from "../../../../../../../../../../shared/components/button/Button";
import { useRecoilValue } from "recoil";
import { selectedChartDataSetAtom } from "../../../../../chartDataSets/chartDataSets.recoil";
import { useStoryForm } from "../../../story.recoil";

interface Props {
	onChartDataSetPick?: (chartDataSet: ChartDataSetFragment) => void;
}

const ChartDataSetPickerDoneButton: React.FC<Props> = ({ onChartDataSetPick }) => {
	const {
		storyForm: { story },
	} = useStoryForm();

	const selectedChartDataSet = useRecoilValue(selectedChartDataSetAtom);

	const [getChartDataSet, getChartDataSetRes] = useGetChartDataSetLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [addChartDataSetMutation, addChartDataSetMutationRes] = useAddChartDataSetMutation();

	const _done = useCallback(() => {
		if (selectedChartDataSet) {
			// @TODO write copyChartDataSet in api
			void getChartDataSet({
				variables: {
					id: selectedChartDataSet.id,
				},
			});
		}
	}, [getChartDataSet, selectedChartDataSet]);

	useEffect(() => {
		if (
			getChartDataSetRes.called &&
			!getChartDataSetRes.loading &&
			getChartDataSetRes.data &&
			getChartDataSetRes.data.getChartDataSets.rows.length !== 0
		) {
			const chartDataSet = getChartDataSetRes.data.getChartDataSets.rows[0];

			const getAddChartDataPoints = (): AddOrEditChartDataPointsInput | undefined => {
				return {
					inputs: chartDataSet.chartDataPoints.map(
						(chartDataPoint): AddOrEditChartDataPointInput => {
							return {
								fields: {
									name: chartDataPoint.name!,
									value: chartDataPoint.value!,
									fill: chartDataPoint.fill!,
								},
							};
						},
					),
				};
			};

			void addChartDataSetMutation({
				variables: {
					input: {
						fields: {
							// @TODO story.name + section.name + frame.name + layer.id + container.id + component.id
							name: chartDataSet.name + "-" + story.name,
						},
						addOrEditChartDataPoints: getAddChartDataPoints(),
					},
				},
			});
		}
	}, [
		getChartDataSetRes.called,
		getChartDataSetRes.loading,
		getChartDataSetRes.data,
		addChartDataSetMutation,
		story.name,
	]);

	useEffect(() => {
		if (
			addChartDataSetMutationRes.called &&
			!addChartDataSetMutationRes.loading &&
			addChartDataSetMutationRes.data &&
			addChartDataSetMutationRes.data.addChartDataSets.rows.length !== 0
		) {
			const chartDataSet = addChartDataSetMutationRes.data.addChartDataSets.rows[0];
			onChartDataSetPick?.(chartDataSet);
		}
	}, [
		addChartDataSetMutationRes.called,
		addChartDataSetMutationRes.data,
		addChartDataSetMutationRes.loading,
		onChartDataSetPick,
	]);

	return (
		<Button
			className="blue"
			disabled={!selectedChartDataSet}
			onClick={() => {
				_done();
			}}
		>
			DONE <BiHdd className="h-5 w-5" />
		</Button>
	);
};

export default ChartDataSetPickerDoneButton;
