import { useRecoilValue } from "recoil";
import OutControlWsData from "../../../types/controlWs/outControlWsData/OutControlWsData";
import { controlWsAtom } from "../controlWs.recoil";

const useSendOutControlWsData = () => {
	const controlWs = useRecoilValue(controlWsAtom);
	return (data: OutControlWsData) => {
		if (!controlWs) {
			console.error("controlWs not initialized");
			return;
		}

		controlWs.send(data);
	};
};

export default useSendOutControlWsData;
