import MapDataLayerTypes from "../../MapDataLayerTypes";
import OpenMapData from "./OpenMapData";

export enum ControlMapCommandDataTypes {
	OPEN_MAP,
	CLOSE_MAP,
	SET_ZIP_CODE,
	VIEW_ALL_CLEVELAND,
	SET_DATA_LAYER,
}

type ControlMapCommandDataTypeMap = {
	[ControlMapCommandDataTypes.OPEN_MAP]: OpenMapData;
	[ControlMapCommandDataTypes.CLOSE_MAP]: undefined;
	[ControlMapCommandDataTypes.SET_ZIP_CODE]: string;
	[ControlMapCommandDataTypes.VIEW_ALL_CLEVELAND]: undefined;
	[ControlMapCommandDataTypes.SET_DATA_LAYER]: MapDataLayerTypes;
};

interface ControlMapCommandData<T extends ControlMapCommandDataTypes = ControlMapCommandDataTypes> {
	type: T;
	data: ControlMapCommandDataTypeMap[T];
}

export const createControlMapCommandData = <T extends ControlMapCommandDataTypes>(
	type: T,
	data: ControlMapCommandDataTypeMap[T],
): ControlMapCommandData<T> => {
	return {
		type,
		data,
	};
};

export default ControlMapCommandData;
