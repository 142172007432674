import {
	createOutDisplayWsData,
	OutDisplayWsDataTypes,
} from "../types/displayWs/outDisplayWsData/OutDisplayWsData";
import StoryEngine from "../../shared/classes/StoryEngine";
import DisplayWs from "./DisplayWs";

class StoryEngineStateSender {
	private _displayWs: DisplayWs;
	private _storyEngine: StoryEngine;
	private _interval: NodeJS.Timer | undefined;

	constructor(displayWs: DisplayWs, storyEngine: StoryEngine) {
		this._displayWs = displayWs;
		this._storyEngine = storyEngine;
	}

	private _looper() {
		this._send();
	}

	private _send() {
		this._displayWs.send(
			createOutDisplayWsData(OutDisplayWsDataTypes.STORY_ENGINE_STATE, {
				storyId: this._storyEngine.story?.id,
				sectionId: this._storyEngine.section?.id,
				isPaused: this._storyEngine.isPaused,
				time: this._storyEngine.time,
			}),
		);
	}

	get isStopped() {
		return !this._interval;
	}

	set isStopped(val) {
		if (val) {
			this._stop();
		} else {
			this._start();
		}
	}

	private _start() {
		if (!this._interval) {
			this._interval = setInterval(() => this._looper(), 1000 / 24); // 24 fps
		}
	}

	private _stop() {
		if (this._interval) {
			clearInterval(this._interval);
			this._interval = undefined;
		}
	}
}

export default StoryEngineStateSender;
