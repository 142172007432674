import { TrashIcon, XIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { MdCheck } from "react-icons/md";
import { toast } from "react-toastify";
import Button from "../../../../../../../../../shared/components/button/Button";
import { useModals } from "../../../../../../../../../shared/g3Components/modal/modals.recoil";
import { useRemoveAssetFilesMutation } from "../../../../../../../../../shared/generated/graphql";
import {
	useAssetFilesGridReloader,
	useSelectedAssetFiles,
} from "../assetFilesContent/assetFilesGrid/assetFilesGrid.recoil";

const DeleteAssetFilesButton: React.FC = () => {
	const { pushModal, popModal } = useModals();

	const { selectedAssetFiles, resetSelectedAssetFiles } = useSelectedAssetFiles();
	const { reloadAssetFilesGrid } = useAssetFilesGridReloader();

	const [removeAssetFiles, removeAssetFilesRes] = useRemoveAssetFilesMutation();

	useEffect(() => {
		if (removeAssetFilesRes.called && !removeAssetFilesRes.loading) {
			if (!removeAssetFilesRes.error) {
				toast.success("Deleted Successfully!");
				resetSelectedAssetFiles();
				reloadAssetFilesGrid();
			} else {
				toast.error("Delete error");
			}

			popModal();
		}
	}, [
		popModal,
		reloadAssetFilesGrid,
		removeAssetFilesRes.called,
		removeAssetFilesRes.error,
		removeAssetFilesRes.loading,
		resetSelectedAssetFiles,
	]);

	return (
		<Button
			className="red"
			disabled={selectedAssetFiles.length === 0}
			onClick={() => {
				pushModal({
					render: (
						<div className="flex gap-4">
							<Button className="blue" onClick={() => popModal()}>
								No <XIcon className="h-5 w-5" />
							</Button>
							<Button
								className="red"
								onClick={() => {
									void removeAssetFiles({
										variables: {
											input: {
												inputs: selectedAssetFiles.map((selectedAssetFile) => {
													return {
														filter: {
															fileId: selectedAssetFile.file.id,
														},
													};
												}),
											},
										},
									});
								}}
							>
								Yes <MdCheck className="h-5 w-5" />
							</Button>
						</div>
					),
					title: "Delete File(s)",
				});
			}}
		>
			Delete <TrashIcon className="h-5 w-5" />
		</Button>
	);
};

export default DeleteAssetFilesButton;
