import { BiEditAlt } from "react-icons/bi";
import { useBuilderModals } from "../../../../../builder.recoil";
import { useComponentTempForm, useStoryForm } from "../../../story.recoil";
import ComponentForm from "./ComponentForm";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
	layerIndex: number;
	containerIndex: number;
	componentIndex: number;
}

const EditComponentButton: React.FC<Props> = ({
	sectionIndex,
	frameIndex,
	layerIndex,
	containerIndex,
	componentIndex,
}) => {
	const {
		storyForm: { story },
	} = useStoryForm();
	const { pushBuilderModal } = useBuilderModals();
	const { updateComponentTempForm } = useComponentTempForm();

	const component =
		story.sections?.[sectionIndex]?.frames?.[frameIndex]?.layers?.[layerIndex]?.containers?.[
			containerIndex
		]?.components?.[componentIndex];

	return (
		<button
			onClick={() => {
				updateComponentTempForm({
					fields: {
						componentIndex,
						componentTemp: {
							...component,
						},
					},
				});

				pushBuilderModal({
					render: (
						<ComponentForm
							sectionIndex={sectionIndex}
							frameIndex={frameIndex}
							layerIndex={layerIndex}
							containerIndex={containerIndex}
						/>
					),
					title: "Edit Component",
				});
			}}
		>
			<BiEditAlt className="h-5 w-5" />
		</button>
	);
};

export default EditComponentButton;
