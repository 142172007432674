import { ServerIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import Button from "../../../../../../../../shared/components/button/Button";
import { useSaveStory, useStoryForm } from "../story.recoil";
import { toast } from "react-toastify";

interface Props {
	form?: HTMLFormElement;
}

const SaveStoryButton: React.FC<Props> = ({ form }) => {
	const {
		storyForm: { isDirty, story },
	} = useStoryForm();

	const { saveStory, saveStoryRes } = useSaveStory();

	useEffect(() => {
		if (saveStoryRes.isCalled && !saveStoryRes.isSaving) {
			if (!saveStoryRes.error) {
				toast.success("Saved Successfully!");
			} else {
				toast.error("Save error");
			}
		}
	}, [saveStoryRes.isCalled, saveStoryRes.isSaving, saveStoryRes.addStoryData, saveStoryRes.error]);

	return (
		<Button
			className="blue"
			disabled={story.id !== undefined && !isDirty}
			onClick={() => {
				if (form && !form.checkValidity()) {
					toast.error("Save error");
				}
				void saveStory(form);
			}}
		>
			Save <ServerIcon className="h-5 w-5" />
		</Button>
	);
};

export default SaveStoryButton;
