import React from "react";
import {
	PauseIcon,
	PlayIcon,
	StopIcon,
	VolumeOffIcon,
	VolumeUpIcon,
} from "@heroicons/react/outline";
import { useRecoilState, useRecoilValue } from "recoil";
import {
	isMutedAtom,
	useStoryEngine,
	useStoryEngineState,
	useStoryEngineTime,
} from "../../../shared/state/storyEngine/storyEngine.recoil";
import "./story.scss";
import { storiesAtom } from "../../state/displayWs/display.recoil";
import env, { EnvTypes } from "../../../env";
import Input from "../../../shared/components/input/Input";
import Slider from "../../../shared/components/slider/Slider";

const StoryControl: React.FC = () => {
	const stories = useRecoilValue(storiesAtom);

	const { storyEngine: storyEngineU } = useStoryEngine();
	const storyEngine = storyEngineU!;
	const storyEngineTime = useStoryEngineTime();
	const storyEngineState = useStoryEngineState();

	const [isMuted, setIsMuted] = useRecoilState(isMutedAtom);
	const getFramePos = () => {
		if (storyEngineState.section) {
			let startTime = 0;
			for (let i = 0; i < storyEngineState.section.frames.length; ++i) {
				const frame = storyEngineState.section.frames[i];
				const endTime = frame.duration + startTime;
				if (storyEngineTime >= startTime && storyEngineTime < endTime) {
					return i + 1;
				}
				startTime = endTime;
			}
		}

		return 0;
	};
	const setTimeAtFramePos = (framePos: number) => {
		if (storyEngineState.section?.frames === undefined) {
			return;
		}

		let time = 0;
		for (let i = 0; i < framePos - 1; ++i) {
			const frame = storyEngineState.section.frames[i];
			time += frame.duration;
		}

		storyEngine.time = time;
	};
	const frameLen = storyEngineState.section?.frames.length ?? 0;
	const framePos = getFramePos();

	return (
		<div className="story-control">
			{storyEngineState.story ? (
				<>
					<div className="section-buttons flex gap-[10px]">
						{storyEngineState.story.sections.map((section) => {
							return (
								<button
									disabled={storyEngineState.isPaused}
									key={section.id}
									className={
										"b-section " + (storyEngineState.section?.id === section.id ? "active " : " ")
									}
									onClick={() => {
										storyEngine.setSectionById(section.id);
									}}
								>
									{section.name}
								</button>
							);
						})}
					</div>
					<div className="mt-[10px] flex flex-wrap gap-[10px]">
						<button
							onClick={() => {
								storyEngine.isPaused = !storyEngineState.isPaused;
							}}
							className="play-button "
						>
							{storyEngineState.isPaused ? "PLAY" : "PAUSE"}
							{storyEngineState.isPaused ? (
								<PlayIcon className="h-[24px] w-[24px]" />
							) : (
								<PauseIcon className="h-[24px] w-[24px]" />
							)}
						</button>
						<button
							onClick={() => {
								setIsMuted(!isMuted);
							}}
							className="mute-button "
						>
							MUTE
							{isMuted ? (
								<VolumeOffIcon className="h-[24px] w-[24px]" />
							) : (
								<VolumeUpIcon className="h-[24px] w-[24px]" />
							)}
						</button>

						<button
							onClick={() => {
								storyEngine.stop();
							}}
							className="stop-button "
						>
							STOP
							<StopIcon className="h-[24px] w-[24px]" />
						</button>
					</div>
					{env.NODE_ENV === EnvTypes.Development && (
						<div className="input-container w-full">
							<Slider
								type="range"
								min={1}
								max={frameLen}
								value={framePos}
								onChange={(e) => setTimeAtFramePos(Number(e.target.value))}
								style={{
									background:
										"linear-gradient(to right, #499248 0%, #499248 " +
										((framePos - 1) / (frameLen - 1)) * 100 +
										"%, #f5f5f5 " +
										((framePos - 1) / (frameLen - 1)) * 100 +
										"%, #f5f5f5 100%)",
								}}
							/>
						</div>
					)}
				</>
			) : (
				<button
					onClick={() => {
						storyEngine.setStoryById(stories[0].id);
						storyEngine.isPaused = false;
					}}
					className="play-button "
				>
					PLAY
					<PlayIcon className="h-[24px] w-[24px]" />
				</button>
			)}
		</div>
	);
};

export default StoryControl;
