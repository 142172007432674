import { BiEditAlt } from "react-icons/bi";
import Button from "../../../../../../../../../shared/components/button/Button";
import { useModals } from "../../../../../../../../../shared/g3Components/modal/modals.recoil";
import {
	useAssetFilesGridReloader,
	useSelectedAssetFiles,
} from "../assetFilesContent/assetFilesGrid/assetFilesGrid.recoil";
import FileForm from "./fileForm/FileForm";

const EditFileButton: React.FC = () => {
	const { pushModal, popModal } = useModals();

	const { selectedAssetFiles } = useSelectedAssetFiles();
	const { reloadAssetFilesGrid } = useAssetFilesGridReloader();

	return (
		<Button
			className="red"
			disabled={selectedAssetFiles.length !== 1}
			onClick={() => {
				pushModal({
					render: (
						<FileForm
							fileId={selectedAssetFiles[0].file.id}
							onSave={() => {
								reloadAssetFilesGrid();
								popModal();
							}}
						/>
					),
					title: "Edit Asset",
				});
			}}
		>
			Edit <BiEditAlt className="h-5 w-5" />
		</Button>
	);
};

export default EditFileButton;
