import { atom } from "recoil";
import { StoryRowFragment } from "../../../shared/generated/graphql";

export const selectedStoryAtom = atom<StoryRowFragment | undefined>({
	key: "selectedStory_public",
	default: undefined,
});

export const zipCodeInputValueAtom = atom<string | undefined>({
	key: "zipCodeInputValue",
	default: undefined,
});

export const selectedZipCodeAtom = atom<
	| {
			zipCode: string;
			isValid: boolean;
	  }
	| undefined
>({
	key: "selectedZipCode",
	default: undefined,
});
