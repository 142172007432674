import { ChevronDownIcon } from "@heroicons/react/outline";
import { useHistory, useLocation } from "react-router-dom";
import { PathName } from "../../../../../../../constants/PathName";
import { useStoryForm } from "../story.recoil";
import "./storySideBar.scss";

const StorySideBar: React.FC = () => {
	const history = useHistory();
	const {
		storyForm: { story },
	} = useStoryForm();
	const location = useLocation();

	const isInSections = location.pathname.includes(PathName.BUILDER_STORIES_SECTIONS(story.id + ""));
	const activeHash = location.hash;

	return (
		<div className="story-builder-sidebar">
			<div className="accordion accordion-flush nav nav-pills" id="sectionsAccordion">
				{story.sections?.map((section, i) => {
					if (i === 0) {
						return (
							<div className="accordion-item" key={i}>
								<h2 className="accordion-header" id={"heading" + i}>
									<button
										className="accordion-button"
										type="button"
										onClick={() => {
											if (story.id !== undefined && isInSections) {
												history.push(PathName.BUILDER_STORIES(story.id + ""));
											}
										}}
									>
										Story Info
									</button>
								</h2>
							</div>
						);
					}
					if (!isInSections) {
						return null;
					}
					const isSectionActive = activeHash.includes("#sec-" + i);
					const isSectionEmpty = section.frames && section.frames.length <= 0;
					return (
						<div className="accordion-item" key={i}>
							<h2 className="accordion-header" id={"heading" + i}>
								<button
									className={
										"accordion-button " + (isSectionEmpty ? "!bg-gray-200 !text-uwgc-orange" : "")
									}
									type="button"
									data-bs-toggle="collapse"
									data-bs-target={"#collapse" + i}
									aria-expanded="true"
									aria-controls={"collapse" + i}
								>
									<div
										className={
											"flex w-full justify-between " +
											(isSectionActive && !isSectionEmpty ? "text-uwgc-green" : "")
										}
									>
										<div>{section.name}</div>
										<div className="flex items-center gap-1">
											({section.frames?.length}) <ChevronDownIcon className="ab-icon h-5 w-5" />
										</div>
									</div>
								</button>
							</h2>
							<div
								id={"collapse" + i}
								className="collapse show accordion-collapse"
								aria-labelledby={"heading" + i}
								data-bs-parent="#sectionsAccordion"
							>
								<div className="accordion-body">
									{section.frames?.map((frame, j) => {
										const isActive = "#sec-" + i + "-frame-" + j === activeHash;
										return (
											<a
												key={i + "-" + j}
												className={
													"block w-full bg-white py-4 px-5 text-left font-bold text-uwgc-charcoal " +
													(isActive ? "text-uwgc-green" : "")
												}
												href={"#sec-" + i + "-frame-" + j}
											>
												{section.name} {j + 1}
											</a>
										);
									})}
								</div>
							</div>
						</div>
					);
				})}
				{!isInSections && story.id !== undefined && (
					<div className="accordion-item" key={"gotoSections"}>
						<h2 className="accordion-header" id={"gotoSections"}>
							<button
								className="accordion-button"
								type="button"
								onClick={() => {
									history.push(PathName.BUILDER_STORIES_SECTIONS(story.id + ""));
								}}
							>
								Story Sections
							</button>
						</h2>
					</div>
				)}
			</div>
		</div>
	);
};

export default StorySideBar;
