import { useStoryForm } from "../../../story.recoil";
import Section from "./section/Section";

const SectionsList: React.FC = () => {
	const {
		storyForm: { story },
	} = useStoryForm();

	return (
		<div>
			{story.sections?.map((section, i) => {
				// hide title section
				if (i !== 0) {
					return <Section key={i} sectionIndex={i} />;
				} else {
					return undefined;
				}
			})}
		</div>
	);
};

export default SectionsList;
