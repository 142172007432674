import React, { InputHTMLAttributes, useMemo } from "react";
import "./toggle.scss";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
	onColor: string;
	onText?: string | JSX.Element;
	offText?: string | JSX.Element;
}

let lastInputId = 0;

const Toggle: React.FC<Props> = ({ onColor, onText, offText, ...props }) => {
	// This id is required if the Toggle is used more than once in a the current view
	// If you don't add a unique id htmlFor will find the first id(may or may not be the correct input) and toggle that input
	// You actually click the label instead of the input when clicking this Toggle, htmlFor makes that happen
	const inputId = useMemo(() => {
		return ++lastInputId;
	}, []);

	return (
		<>
			<input
				{...props}
				className="toggle-switch"
				id={"toggle-switch-checkbox" + inputId}
				type="checkbox"
			/>
			<label
				style={{ background: props.checked && onColor ? onColor : undefined }}
				className="toggle-switch-label"
				htmlFor={"toggle-switch-checkbox" + inputId}
			>
				<span className={"toggle-switch-button"} />

				{props.checked === true && <span className="on-text">{onText ?? "Yes"}</span>}
				{props.checked === false && <span className="off-text">{offText ?? "No"}</span>}
			</label>
		</>
	);
};

export default Toggle;
