import ChartDataPointsTable from "./ChartDataPointsTable";

const ChartDataPoints: React.FC = () => {
	return (
		<div>
			<ChartDataPointsTable />
		</div>
	);
};

export default ChartDataPoints;
