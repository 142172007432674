import { Redirect, Route, Switch } from "react-router-dom";
import { PathName } from "../../../../../constants/PathName";
import StoriesPage from "./storiesPage/StoriesPage";
import Story from "./story/Story";

const Stories: React.FC = () => {
	return (
		<div>
			<Switch>
				<Route exact path={PathName.BUILDER_STORIES()} component={StoriesPage} />
				<Route path={PathName.BUILDER_STORIES(":storyId")} component={Story} />
				<Route render={() => <Redirect push to={PathName.NOT_FOUND} />} />
			</Switch>
		</div>
	);
};

export default Stories;
