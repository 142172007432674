import { BaseEditor, Editor } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import Select from "../../../../../../../../../../../shared/components/select/Select";
import TooltipAlt from "../../../../../../../../../../../shared/g3Components/tooltipAlt/TooltipAlt";
import {
	activeMark,
	addMarkData,
	isBlockActive,
	toggleBlock,
	toggleMark,
} from "./SlateUtilityFunctions";
import ToolbarIcon from "./ToolbarIcon";
import toolbarGroups from "./toolbarGroups";

export const isMarkActive = (editor: BaseEditor, format: string) => {
	const marks = Editor.marks(editor) as any;

	return marks ? marks[format] !== undefined : false;
};

const Toolbar: React.FC = () => {
	const editor = useSlate() as ReactEditor;

	const BlockButton = ({ format }: { format: string }) => {
		const active = isBlockActive(editor, format);
		return (
			<button
				type="button"
				className={"toolbar-button " + (active ? "btnActive" : "lol")}
				title={format}
				onMouseDown={(e) => {
					e.preventDefault();
					toggleBlock(editor, format);
				}}
			>
				<ToolbarIcon icon={format} />
			</button>
		);
	};
	const MarkButton = ({ format }: { format: string }) => {
		const active = isMarkActive(editor, format);
		return (
			<button
				type="button"
				className={"toolbar-button " + (active ? "btnActive" : "lol")}
				title={format}
				onMouseDown={(e) => {
					e.preventDefault();
					toggleMark(editor, format);
				}}
			>
				<ToolbarIcon icon={format} />
			</button>
		);
	};
	const Dropdown = ({
		format,
		options,
	}: {
		format: string;
		options: { text: string; value: string }[];
	}) => {
		return (
			<div className="select-wrapper">
				<Select value={activeMark(editor, format)} onChange={(e) => changeMarkData(e, format)}>
					{options.map((item, index) => (
						<option key={index} value={item.value}>
							{item.text}
						</option>
					))}
				</Select>
				<ToolbarIcon icon={format} />
			</div>
		);
	};
	const ColorPicker = ({ format }: { format: string }) => {
		const activeMarkerColor = "text-" + activeMark(editor, format);
		return (
			<TooltipAlt
				position="bottom"
				type="hover"
				message={
					<div className="flex w-max max-w-[8rem] flex-wrap gap-1">
						{[
							"uwgc-white",
							"uwgc-lightGray",
							"uwgc-midGrey",
							"uwgc-darkGray",
							"uwgc-charcoal",
							"uwgc-yellow",
							"uwgc-blue",
							"uwgc-midblue",
							"uwgc-slate",
							"uwgc-green",
							"uwgc-red",
						].map((color, i) => {
							const optionColor = " bg-" + color;
							return (
								<button
									key={i}
									className={"h-5 w-5 rounded-full border border-uwgc-charcoal" + optionColor}
									type={"button"}
									onClick={() => {
										Editor.addMark(editor, format, color);
									}}
								></button>
							);
						})}
					</div>
				}
			>
				<div className={"toolbar-button " + activeMarkerColor}>
					<ToolbarIcon icon={format} />
				</div>
			</TooltipAlt>
		);
	};

	const changeMarkData = (event: React.ChangeEvent<HTMLSelectElement>, format: string) => {
		event.preventDefault();
		const value = event.target.value;
		addMarkData(editor, { format, value });
	};

	return (
		<div className="toolbar">
			{toolbarGroups.map((group, index) => (
				<span key={index} className="toolbar-grp">
					{group.map((element) => {
						switch (element.type) {
							case "block":
								return <BlockButton key={element.id} format={element.format!} />;
							case "mark":
								return <MarkButton key={element.id} format={element.format!} />;
							case "dropdown":
								return (
									<Dropdown key={element.id} format={element.format!} options={element.options!} />
								);
							case "color-picker":
								return <ColorPicker key={element.id} format={element.format!} />;
							default:
								return <button>Invalid Button</button>;
						}
					})}
				</span>
			))}
		</div>
	);
};

export default Toolbar;
