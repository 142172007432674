import { CogIcon } from "@heroicons/react/outline";
import { useBuilderModals } from "../../../../../builder.recoil";
import { useFrameTempForm, useStoryForm } from "../../../story.recoil";
import FrameForm from "./FrameForm";

export interface Props {
	sectionIndex: number;
	frameIndex: number;
}

const EditFrameButton: React.FC<Props> = ({ sectionIndex, frameIndex }) => {
	const { pushBuilderModal } = useBuilderModals();

	const {
		storyForm: { story },
	} = useStoryForm();
	const { updateFrameTempForm } = useFrameTempForm();

	const frame = story.sections?.[sectionIndex]?.frames?.[frameIndex];

	return (
		<button
			onClick={() => {
				updateFrameTempForm({
					fields: {
						frameIndex,
						frameTemp: {
							...frame,
						},
					},
				});

				pushBuilderModal({
					render: <FrameForm sectionIndex={sectionIndex} />,
					title: "Edit Frame",
				});
			}}
		>
			<CogIcon className="h-5 w-5" />
		</button>
	);
};

export default EditFrameButton;
