import { useCallback, useEffect, useMemo } from "react";
import {
	StoryStatusIds,
	useEditStoryMutation,
	useGetStoriesLazyQuery,
	useGetStoryStatusesLazyQuery,
} from "../../../../../../../shared/generated/graphql";
import { Link } from "react-router-dom";
import { PathName } from "../../../../../../constants/PathName";
import Table from "../../../../../../../shared/components/table/Table";
import { BsChevronDown, BsChevronUp, BsEye } from "react-icons/bs";
import { RiInformationLine } from "react-icons/ri";
import Toggle from "../../../../../../../shared/components/toggle/Toggle";
import Input from "../../../../../../../shared/components/input/Input";
import Select from "../../../../../../../shared/components/select/Select";
import Tooltip from "../../../../../../../shared/g3Components/tooltip/Tooltip";
import OpenStoryPreviewButton from "../storyPreview/OpenStoryPreviewButton";
import { formatDateUTCMMddyyyy } from "gate3-utils";
import Loading from "../../../../../../../shared/components/Loading/Loading";
import { useRecoilValue } from "recoil";
import { storiesTableFilterAtom } from "./stories.recoil";
import { ClipboardCopyIcon } from "@heroicons/react/outline";
import { createShareableStoryLink } from "../../../../../../utils/utils";
import { toast } from "react-toastify";

const StoriesTable: React.FC = () => {
	const storiesTableFilter = useRecoilValue(storiesTableFilterAtom);

	const [getStoryStatuses, getStoryStatusesRes] = useGetStoryStatusesLazyQuery({
		fetchPolicy: "no-cache",
	});

	const [getStories, getStoriesRes] = useGetStoriesLazyQuery({
		fetchPolicy: "no-cache",
	});

	const [editStory, editStoryRes] = useEditStoryMutation();

	const _getStories = useCallback(() => {
		void getStories({
			variables: {
				input: {
					filter: {
						...storiesTableFilter,
						likeTopic:
							storiesTableFilter.likeTopic !== undefined
								? "%" + storiesTableFilter.likeTopic + "%"
								: undefined,
					},
					orderBys: [
						{
							by: "orderNum",
						},
						{
							by: "createdAt",
						},
						{
							by: "name",
						},
					],
				},
			},
		});
	}, [getStories, storiesTableFilter]);

	useEffect(() => {
		void getStoryStatuses();
	}, [getStoryStatuses]);

	useEffect(() => {
		if (getStoryStatusesRes.called && !getStoryStatusesRes.loading && !getStoryStatusesRes.error) {
			void _getStories();
		}
	}, [
		_getStories,
		getStoryStatusesRes.called,
		getStoryStatusesRes.loading,
		getStoryStatusesRes.error,
	]);

	useEffect(() => {
		if (editStoryRes.called && !editStoryRes.loading && !editStoryRes.error) {
			void _getStories();
		}
	}, [_getStories, editStoryRes.called, editStoryRes.loading, editStoryRes.error]);

	const isLoading = getStoryStatusesRes.loading || getStoriesRes.loading || editStoryRes.loading;
	const storyStatuses = getStoryStatusesRes.data?.getStoryStatuses.rows || [];
	const stories = useMemo(() => {
		return getStoriesRes.data?.getStories.rows || [];
	}, [getStoriesRes]);

	const _editStoryIsFeatured = useCallback(
		(id: number, isFeatured: boolean) => {
			const story = stories.find((story) => story.id === id)!;
			void editStory({
				variables: {
					input: {
						filter: {
							id,
						},
						fields: {
							name: story.name,
							statusId: story.statusId,
							topic: story.topic,
							coverImageAssetFileId: story.coverImageAssetFileId,
							description: story.description,
							orderNum: story.orderNum,
							isFeatured,
						},
					},
				},
			});
		},
		[stories, editStory],
	);

	const _editStoryOrderNum = useCallback(
		(id: number, orderNum: number | null) => {
			const story = stories.find((story) => story.id === id)!;
			void editStory({
				variables: {
					input: {
						filter: {
							id,
						},
						fields: {
							name: story.name,
							statusId: story.statusId,
							topic: story.topic,
							coverImageAssetFileId: story.coverImageAssetFileId,
							description: story.description,
							orderNum,
							isFeatured: story.isFeatured,
						},
					},
				},
			});
		},
		[stories, editStory],
	);

	const _editStoryStatus = useCallback(
		(id: number, statusId: StoryStatusIds) => {
			const story = stories.find((story) => story.id === id)!;
			void editStory({
				variables: {
					input: {
						filter: {
							id,
						},
						fields: {
							name: story.name,
							statusId,
							topic: story.topic,
							coverImageAssetFileId: story.coverImageAssetFileId,
							description: story.description,
							orderNum: story.orderNum,
							isFeatured: story.isFeatured,
						},
					},
				},
			});
		},
		[stories, editStory],
	);

	return (
		<div className="px-4">
			<Table
				columns={[
					{
						name: "Story Title",
						formattedField: (row) => {
							return (
								<Link className="blue-clickable" to={PathName.BUILDER_STORIES(row.data.id + "")}>
									{row.data.name}
								</Link>
							);
						},
					},

					{
						name: "Topic",
						field: "topic",
					},
					{
						name: "Created by",
						formattedField: (row) => {
							return <div>{row.data.createdByUser?.username}</div>;
						},
					},
					{
						name: "Created On",
						formattedField: (row) => {
							return <div>{formatDateUTCMMddyyyy(row.data.createdAt)}</div>;
						},
					},
					{
						name: "Last Edited",
						formattedField: (row) => {
							return <div>{formatDateUTCMMddyyyy(row.data.updatedAt)}</div>;
						},
					},
					{
						name: "Order",
						columnWidth: "60px",
						formattedField: (row) => {
							return (
								<Input
									type="number"
									step={1}
									min={0}
									value={row.data.orderNum ?? ""}
									onChange={(e) => {
										_editStoryOrderNum(
											row.data.id,
											e.target.value === "" ? null : parseInt(e.target.value),
										);
									}}
								/>
							);
						},
					},
					{
						name: (
							<Tooltip message={"Featured stories are played on the community space monitors"}>
								<div className="flex items-center gap-1">
									Featured
									<RiInformationLine className="h-5 w-5 text-uwgc-slate" />
								</div>
							</Tooltip>
						),
						formattedField: (row) => {
							return (
								<div className="flex items-center justify-start text-center">
									<Toggle
										onColor="#22C55E"
										checked={row.data.isFeatured}
										onChange={(e) => {
											_editStoryIsFeatured(row.data.id, e.target.checked);
										}}
									/>
								</div>
							);
						},
					},

					{
						name: "Status",
						formattedField: (row) => {
							return (
								<>
									{row.data.statusId === StoryStatusIds.Published ? (
										<div>
											{storyStatuses.find((storyStatus) => storyStatus.id === row.data.statusId)
												?.name ?? "Unknown Status"}
										</div>
									) : (
										<Select
											value={row.data.statusId}
											onChange={(e) => {
												_editStoryStatus(row.data.id, e.target.value as StoryStatusIds);
											}}
										>
											{storyStatuses.map((storyStatus) => (
												<option key={storyStatus.id} value={storyStatus.id}>
													{storyStatus.name}
												</option>
											))}
										</Select>
									)}
								</>
							);
						},
					},
					{
						name: "Preview",
						formattedField: (row) => {
							return (
								<OpenStoryPreviewButton storyId={row.data.id}>
									<BsEye className="ml-8 h-6 w-6 text-uwgc-midblue" aria-hidden="true" />
								</OpenStoryPreviewButton>
							);
						},
					},
					{
						name: "Share",
						formattedField: (row) => {
							return (
								<ClipboardCopyIcon
									className="text-fsDarkGray ml-2 mr-2 h-5 w-5 cursor-pointer hover:opacity-50"
									onClick={() => {
										navigator.clipboard
											.writeText(createShareableStoryLink(row.data.name, row.data.id))
											.then(() => {
												toast.info("Copied to clipboard");
											})
											.catch(() => {
												console.error("Failed to copy to clipboard");
											});
									}}
								/>
							);
						},
					},
				]}
				rows={stories.map((data) => {
					return {
						data,
					};
				})}
				className="stories"
				// pagination={{
				// 	pages,
				// 	currentPage,
				// 	onPageClick: (page: number) => {
				// 		setState({
				// 			currentPage: page,
				// 		});
				// 	},
				// }}
				onSort={(field, isDesc) => {
					if (field) {
						// setState({
						// 	orderBys: pushToOrderBys(orderBys, field, isDesc),
						// });
					}
				}}
				ascIcon={<BsChevronUp className="h-5 w-5" />}
				descIcon={<BsChevronDown className="h-5 w-5" />}
				render={
					isLoading
						? () => {
								return <>{<Loading loadingType="lds-ring" />}</>;
						  }
						: undefined
				}
			/>
		</div>
	);
};

export default StoriesTable;
